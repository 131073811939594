<template>
  <div class="swiper-slide">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "SwiperSlide",
  updated() {

  },
};
</script>

<style scoped lang="scss">

</style>