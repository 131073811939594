import businessComputed from "./businessComputed";

export default {
  props: {
    type: String,
  },
  mixins: [businessComputed],
  computed: {
    TypeComputed() {
      return {
        isInput: this.type === "input",
        isInputOptions: this.type === "inputOptions",
        isMainForm: !!(this.form && (this.rules || this.$attrs.rules || this.$attrs["main-form"])),
        isForm: this.form,
        isTextarea: this.type === "textarea",
        isSelect: this.type === "select",
        isCascader: this.type === "cascader",
        isRadio: this.type === "radio",
        isCheckbox: this.type === "checkbox",
        isCurrcd: this.type === "currcd",
        isNumber: this.type === "number",
        isDate: this.type === "date",
        isTagInput: this.type === "tagInput",
        isSelectInputTel: this.type === "selectInputTel",
        isDateRange: this.type === "dateRange",
        isDateTime: this.type === "datetime",
        isDialog: this.type === "dialog",
        isPopover: this.type === "popover",
        isPhone: this.type === "phone",
        isSelectInput: this.type === "selectInput",

        isTruckIn: this.type === "国内车型",
        isTruckOut: this.type === "国外车型",
      };
    },
  }
};
