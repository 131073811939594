export default {
  computed: {
    BusinessType() {
      // 可能会用的是订舱页的业务类型 或 prop中的type
      return this.RoutePage.businessType || this.$route.params.type || this.type;
    },
    BusinessMode() {
      const options = this.getBusinessMode(this.BusinessType);
      return {
        IsRailWay: options.IsRailWay,
        IsTruck: options.IsTruck,
        IsAirport: options.IsAirport,
        IsSea: options.IsSea,
        IsMultiTrans: options.IsMultiTrans,
      };
    }
  },
  methods: {
    getBusinessMode(type) {
      return {
        IsRailWay: type?.toLowerCase() === "railway",
        IsTruck: type === "truck",
        IsAirport: type === "air",
        IsSea: type === "sea",
        IsMultiTrans: type === "multiTrain" || type === "multiTrans",
      };
    }
  }
};
