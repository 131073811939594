<template>
  <div
      @dragstart.prevent
      :class="[
      'el-input-number__extend',
      'el-input-number',
      inputNumberSize ? 'el-input-number--' + inputNumberSize : '',
      { 'is-disabled': inputNumberDisabled },
      { 'is-without-controls': !controls },
      { 'is-controls-right': controlsAtRight }
    ]">
    <span
        class="el-input-number__decrease"
        role="button"
        v-if="controls"
        v-repeat-click="decrease"
        :class="{'is-disabled': minDisabled}"
        @keydown.enter="decrease">
      <i :class="`el-icon-${controlsAtRight ? 'arrow-down' : 'minus'}`"></i>
    </span>
    <span
        class="el-input-number__increase"
        role="button"
        v-if="controls"
        v-repeat-click="increase"
        :class="{'is-disabled': maxDisabled}"
        @keydown.enter="increase">
      <i :class="`el-icon-${controlsAtRight ? 'arrow-up' : 'plus'}`"></i>
    </span>
    <el-input
        ref="input"
        :value="displayValue"
        :placeholder="placeholder"
        :disabled="inputNumberDisabled"
        :size="inputNumberSize"
        :max="max"
        :min="min"
        :name="name"
        :label="label"
        @keydown.up.native.prevent="increase"
        @keydown.down.native.prevent="decrease"
        @blur="handleBlur"
        @focus="handleFocus"
        @input="handleInput"
        @change="handleInputChange">
      <slot name="append" slot="append"/>
      <slot name="prepend" slot="prepend"/>
      <slot name="prefix" slot="prefix"/>
      <slot name="suffix" slot="suffix"/>
    </el-input>
  </div>
</template>
<script>
import ElementUI from "element-ui";

// 继承ElementUI的InputNumber
export default {
  extends: ElementUI.InputNumber,
};

</script>

<style scoped lang="scss">
.el-input-number__extend.el-input-number .el-input.el-input-group--append {
  display: flex;

  &::v-deep .el-input-group__append {
    height: 40px;
    width: auto;
    padding:0 15px;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-shrink: 0;
    overflow: hidden;
  }
}
</style>
