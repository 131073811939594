export default {
  state: {
    LclOptions: (BusinessKey = "Railway") => [
      {
        label: `OMSGlobalKey.${BusinessKey}.FCL`,
        value: "FCL"
      },
      {
        label: `OMSGlobalKey.${BusinessKey}.LCL2`,
        value: "LCL"
      }, ["Railway", "Sea", "Export",'MultiTrans'].includes(BusinessKey) ? {
        label: "OMSGlobalKey.Others",
        value: "Other",
        type: "select",
        list: [
          {
            label: "车皮",
            value: "WAG",
            content: "车皮是指棚车，敞车和平车的铁路运输。"
          }, {
            label: "散货",
            value: "CFS",
            content: "散货主要是指煤炭、散矿粉、长石沙，锂矿等无包装的散货。"
          }, {
            label: "散杂",
            value: "BULK",
            content: "散杂（杂货）一般是有包装的货物。如箱装或桶装的货物，可通过集装箱装载运输。"
          },
        ]
      } : null
    ].filter(Boolean),
    NM_LclOptions: (BusinessKey = "Railway") => [
      {
        label: `OMSGlobalKey.${BusinessKey}.FCL`,
        value: "FCL"
      },
      {
        label: `车皮`,
        value: "WAG",
        content: "车皮是指棚车，敞车和平车的铁路运输。"

      }
    ].filter(Boolean),
    DTHCOptions: [
      {label: "OMSGlobalKey.ProxyPayment", value: "T"},
      {label: "OMSGlobalKey.NonProxyPayment", value: "F"}
    ],
    TrafficOptions: [
      {
        label: "OMSGlobalKey.FullRoute",
        value: "Q"
      },
      {
        label: "OMSGlobalKey.FirstLeg",
        value: "T"
      },
      {
        label: "OMSGlobalKey.LastLeg",
        value: "H"
      }
    ],
    TruckLclOptions: [
      {
        label: "OMSGlobalKey.FTL",
        value: "FCL"
      },
      {
        label: "OMSGlobalKey.LTL",
        value: "LCL"
      }
    ],
    AirType: [
      {
        label: "OMSGlobalKey.MasterBillOfLading",
        label2: "OMSGlobalKey.MasterBillOfLading",
        value: "M"
      },
      {
        label: "OMSGlobalKey.HouseBillOfLading",
        label2: "OMSGlobalKey.HouseBillOfLading",
        value: "P"
      }
    ],
    truckType:[
      {
        label:'FTL',
        value:'FCL',
      },
      {
        label:'LTL',
        value:'LCL',
      },
    ],
    IntermodalOptions: [
      {
        label: "TestKey.SeaRailIntermodalTransport",
        value: "海铁联运",
      }, {
        label: "TestKey.other",
        value: "其他",
      }
    ],
    UseConType: (BusinessKey = "Railway") => [
      {
        label: `OMSGlobalKey.${BusinessKey}.COC`,
        label2: "COC",
        value: "C"
      },
      {
        label: `OMSGlobalKey.${BusinessKey}.SOC`,
        label2: "SOC",
        value: "S"
      }
    ],
    goodsMixedList:[
      {
        label: "否",
        value: "F"
      },
      {
        label: "是",
        value: "T"
      }
    ],
    CargoKindList: [
      {
        label: "OMSGlobalKey.CommonGoods",
        value: "普货"
      },
      {
        label: "OMSGlobalKey.DangerousGoods",
        value: "危险品"
      }
    ],
    ProductOptions: [
      {
        label: "OMSGlobalKey.RailwayBookingService",
        value: "Railway",
      },
      {
        label: "OMSGlobalKey.OceanShippingBookingService",
        value: "Sea",
      },
      {
        label: "OMSGlobalKey.RoadTransportService",
        value: "Truck",
      },
      {
        label: "OMSGlobalKey.AirTransportService",
        value: "Airway",
      },
      {
        label: "OMSGlobalKey.MultimodalTransportService",
        value: "MultiTrans",
      },
    ],

    ConMeasLocalData: {
      "20GP": {
        // 长
        long: 5.898,
        // 宽
        width: 2.352,
        // 高
        height: 2.38
      },
      "40GP": {
        // 长
        long: 12.032,
        // 宽
        width: 2.352,
        // 高
        height: 2.38
      },
      "40HQ": {
        // 长
        long: 12.032,
        // 宽
        width: 2.352,
        // 高
        height: 2.69
      },
      "40FR": {
        // 长
        long: 11.762,
        // 宽
        width: 2.24,
        // 高
        height: 2.03
      },
      "40OT": {
        // 长
        long: 12.034,
        // 宽
        width: 2.352,
        // 高
        height: 2.33
      },
      "40RF": {
        // 长
        long: 13.233,
        // 宽
        width: 2.437,
        // 高
        height: 2.57
      },
      "45HQ": {
        // 长
        long: 13.556,
        // 宽
        width: 2.352,
        // 高
        height: 2.69
      }
    }
  }
};
