import request from "@/utils/request";

// 查运价
export function fetchRailPriceFclList(query) {
  /** 参数
   * LegType: A
   * Placer:
   * PortlCd: QSM
   * Portl: 七苏木
   * firstLeg: F
   * lastLeg: F
   * headLeg: F
   * hashistory: F
   * EntryPortCd: ELHT
   * EntryPort: 二连浩特
   * DestCd: 700007
   * Dest: 阿拉木图-1
   * PickConPlace:
   * ReturnPlace:
   * BoxType: Z
   * showCol: ["Soc","Coc","Ag"]
   * currcd: USD
   * queryDate:
   * LrManager:
   * pageIndex: 1
   * pageSize: 20
   * sort: asc
   * sortName: Coc40Gp
   * ourBox: F
   * */
  /**
   * [
   *   {
   *     "Id": "337205310351427584",
   *     "segMemo": "全程",
   *     "Traffic": "",
   *     "Line": "中俄",
   *     "LineRemark": "大朗直发，每周二，8.31不开",
   *     "Placer": "广州",
   *     "Portl": "大朗",
   *     "TransPort": "",
   *     "EntryPort": "满洲里",
   *     "Dest": "沃尔西诺",
   *     "PickList": null,
   *     "DeliveryList": null,
   *     "ValidDateFr": "2021-07-15 00:00:00",
   *     "ValidDateTo": "2023-07-29 00:00:00",
   *     "TransDate": "周二",
   *     "TransDays": 18,
   *     "Currcd": "$",
   *     "EndConDt": null,
   *     "EndCastDt": null,
   *     "EndbillDt": null,
   *     "Remark": "发运前三天方可进站，需要提前装柜的请先放外堆场；放外堆场费用自费，",
   *     "Soc20Gp": null,
   *     "Soc40Gp": null,
   *     "Soc20Hd": null,
   *     "Soc40Hq": null,
   *     "Soc45Hq": null,
   *     "Coc20Gp": null,
   *     "Coc40Gp": null,
   *     "Coc20Hd": null,
   *     "Coc40Hq": 14000,
   *     "Coc45Hq": null,
   *     "Ag20Gp": null,
   *     "Ag40Gp": null,
   *     "Ag20Hd": null,
   *     "Ag40Hq": null,
   *     "Ag45Hq": null,
   *     "OC20Gp": null,
   *     "OC40Gp": null,
   *     "OC20Hd": null,
   *     "OC40Hq": null,
   *     "OC45Hq": null,
   *     "ExRateMemo": "\n",
   *     "SocID": "0",
   *     "CocID": "1",
   *     "AgentID": "0",
   *     "BoxType": "租箱",
   *     "BoxFeeMemo": "20GP:0\n20HD:0\n40GP:0\n40HQ:0\n45HQ:0",
   *     "BoxErrMemo": "无买箱价格",
   *     "Creator": "欧阳琴"
   *   },
   *   {
   *     "Id": "342143928882907136",
   *     "segMemo": "全程",
   *     "Traffic": "",
   *     "Line": "中俄",
   *     "LineRemark": "大朗直发，每周五，8.20不开",
   *     "Placer": "",
   *     "Portl": "大朗",
   *     "TransPort": "",
   *     "EntryPort": "满洲里",
   *     "Dest": "沃尔西诺",
   *     "PickList": null,
   *     "DeliveryList": null,
   *     "ValidDateFr": "2021-08-10 00:00:00",
   *     "ValidDateTo": "2023-08-20 00:00:00",
   *     "TransDate": "周五",
   *     "TransDays": 18,
   *     "Currcd": "$",
   *     "EndConDt": "",
   *     "EndCastDt": "",
   *     "EndbillDt": "",
   *     "Remark": "发运前三天方可进站，需要提前装柜的请先放外堆场；放外堆场费用自费，",
   *     "Soc20Gp": null,
   *     "Soc40Gp": null,
   *     "Soc20Hd": null,
   *     "Soc40Hq": null,
   *     "Soc45Hq": null,
   *     "Coc20Gp": null,
   *     "Coc40Gp": null,
   *     "Coc20Hd": null,
   *     "Coc40Hq": 15000,
   *     "Coc45Hq": null,
   *     "Ag20Gp": null,
   *     "Ag40Gp": null,
   *     "Ag20Hd": null,
   *     "Ag40Hq": null,
   *     "Ag45Hq": null,
   *     "OC20Gp": null,
   *     "OC40Gp": null,
   *     "OC20Hd": null,
   *     "OC40Hq": null,
   *     "OC45Hq": null,
   *     "ExRateMemo": "\n",
   *     "SocID": "0",
   *     "CocID": "1",
   *     "AgentID": "0",
   *     "BoxType": "租箱",
   *     "BoxFeeMemo": "20GP:0\n20HD:0\n40GP:0\n40HQ:0\n45HQ:0",
   *     "BoxErrMemo": "无买箱价格",
   *     "Creator": "欧阳琴"
   *   },
   *   {
   *     "Id": "348005188774086665",
   *     "segMemo": "全程",
   *     "Traffic": "",
   *     "Line": "中俄",
   *     "LineRemark": "大朗直发，每周五，8.20不开",
   *     "Placer": "",
   *     "Portl": "大朗",
   *     "TransPort": "",
   *     "EntryPort": "满洲里",
   *     "Dest": "沃尔西诺",
   *     "PickList": null,
   *     "DeliveryList": null,
   *     "ValidDateFr": "2021-08-10 00:00:00",
   *     "ValidDateTo": "2023-08-17 00:00:00",
   *     "TransDate": "周五",
   *     "TransDays": 18,
   *     "Currcd": "$",
   *     "EndConDt": "",
   *     "EndCastDt": "",
   *     "EndbillDt": "",
   *     "Remark": "发运前三天方可进站，需要提前装柜的请先放外堆场；放外堆场费用自费，",
   *     "Soc20Gp": null,
   *     "Soc40Gp": null,
   *     "Soc20Hd": null,
   *     "Soc40Hq": null,
   *     "Soc45Hq": null,
   *     "Coc20Gp": null,
   *     "Coc40Gp": null,
   *     "Coc20Hd": null,
   *     "Coc40Hq": 15000,
   *     "Coc45Hq": null,
   *     "Ag20Gp": null,
   *     "Ag40Gp": null,
   *     "Ag20Hd": null,
   *     "Ag40Hq": null,
   *     "Ag45Hq": null,
   *     "OC20Gp": null,
   *     "OC40Gp": null,
   *     "OC20Hd": null,
   *     "OC40Hq": null,
   *     "OC45Hq": null,
   *     "ExRateMemo": "\n",
   *     "SocID": "0",
   *     "CocID": "1",
   *     "AgentID": "0",
   *     "BoxType": "租箱",
   *     "BoxFeeMemo": "20GP:0\n20HD:0\n40GP:0\n40HQ:0\n45HQ:0",
   *     "BoxErrMemo": "无买箱价格",
   *     "Creator": "调价"
   *   },
   *   {
   *     "Id": "349766496104171520",
   *     "segMemo": "全程",
   *     "Traffic": "",
   *     "Line": "中俄",
   *     "LineRemark": "大朗直发，仅限8.27仓位",
   *     "Placer": "",
   *     "Portl": "大朗",
   *     "TransPort": "",
   *     "EntryPort": "满洲里",
   *     "Dest": "沃尔西诺",
   *     "PickList": null,
   *     "DeliveryList": null,
   *     "ValidDateFr": "2021-08-18 00:00:00",
   *     "ValidDateTo": "2023-08-28 00:00:00",
   *     "TransDate": "周二",
   *     "TransDays": 18,
   *     "Currcd": "$",
   *     "EndConDt": null,
   *     "EndCastDt": null,
   *     "EndbillDt": null,
   *     "Remark": "发运前三天方可进站，需要提前装柜的请先放外堆场；放外堆场费用自费，",
   *     "Soc20Gp": null,
   *     "Soc40Gp": null,
   *     "Soc20Hd": null,
   *     "Soc40Hq": null,
   *     "Soc45Hq": null,
   *     "Coc20Gp": null,
   *     "Coc40Gp": null,
   *     "Coc20Hd": null,
   *     "Coc40Hq": 14500,
   *     "Coc45Hq": null,
   *     "Ag20Gp": null,
   *     "Ag40Gp": null,
   *     "Ag20Hd": null,
   *     "Ag40Hq": null,
   *     "Ag45Hq": null,
   *     "OC20Gp": null,
   *     "OC40Gp": null,
   *     "OC20Hd": null,
   *     "OC40Hq": null,
   *     "OC45Hq": null,
   *     "ExRateMemo": "\n",
   *     "SocID": "0",
   *     "CocID": "1",
   *     "AgentID": "0",
   *     "BoxType": "租箱",
   *     "BoxFeeMemo": "20GP:0\n20HD:0\n40GP:0\n40HQ:0\n45HQ:0",
   *     "BoxErrMemo": "无买箱价格",
   *     "Creator": "欧阳琴"
   *   },
   *   {
   *     "Id": "396514792771134464",
   *     "segMemo": "全程",
   *     "Traffic": "",
   *     "Line": "中俄",
   *     "LineRemark": "大朗直发，仅限8.27仓位",
   *     "Placer": "",
   *     "Portl": "大朗",
   *     "TransPort": "",
   *     "EntryPort": "满洲里",
   *     "Dest": "沃尔西诺",
   *     "PickList": null,
   *     "DeliveryList": null,
   *     "ValidDateFr": "2021-08-18 00:00:00",
   *     "ValidDateTo": "2024-12-01 00:00:00",
   *     "TransDate": "周二",
   *     "TransDays": 18,
   *     "Currcd": "$",
   *     "EndConDt": "",
   *     "EndCastDt": "",
   *     "EndbillDt": "",
   *     "Remark": "发运前三天方可进站，需要提前装柜的请先放外堆场；放外堆场费用自费，",
   *     "Soc20Gp": null,
   *     "Soc40Gp": null,
   *     "Soc20Hd": null,
   *     "Soc40Hq": null,
   *     "Soc45Hq": null,
   *     "Coc20Gp": null,
   *     "Coc40Gp": null,
   *     "Coc20Hd": null,
   *     "Coc40Hq": 14500,
   *     "Coc45Hq": null,
   *     "Ag20Gp": null,
   *     "Ag40Gp": null,
   *     "Ag20Hd": null,
   *     "Ag40Hq": null,
   *     "Ag45Hq": null,
   *     "OC20Gp": null,
   *     "OC40Gp": null,
   *     "OC20Hd": null,
   *     "OC40Hq": null,
   *     "OC45Hq": null,
   *     "ExRateMemo": "\n",
   *     "SocID": "0",
   *     "CocID": "1",
   *     "AgentID": "0",
   *     "BoxType": "租箱",
   *     "BoxFeeMemo": "20GP:0\n20HD:0\n40GP:0\n40HQ:0\n45HQ:0",
   *     "BoxErrMemo": "无买箱价格",
   *     "Creator": "欧阳琴"
   *   },
   *   {
   *     "Id": "208890319874246656",
   *     "segMemo": "全程",
   *     "Traffic": "RAIL/铁运",
   *     "Line": "中俄",
   *     "LineRemark": "公司给销售的统一低价是USD2150. 指导价如下，请注意这是每个客户每列上的量，请大家根据指导价为基准报价：\n1-5柜 USD2450 ，5-15柜USD2350，15柜以上 USD2250.\n  俄罗斯其他车站单独确认                                                                                                                                                                                       Other station in Russia  additional inquiry.",
   *     "Placer": "",
   *     "Portl": "大朗",
   *     "TransPort": "",
   *     "EntryPort": "满洲里",
   *     "Dest": "沃尔西诺",
   *     "PickList": "",
   *     "DeliveryList": "",
   *     "ValidDateFr": "2020-08-01 00:00:00",
   *     "ValidDateTo": "2022-08-31 00:00:00",
   *     "TransDate": "周二",
   *     "TransDays": 16,
   *     "Currcd": "$",
   *     "EndConDt": "周五",
   *     "EndCastDt": "周五",
   *     "EndbillDt": "周五",
   *     "Remark": "1.不接锂电池货物，2.沃尔西诺免堆期为3天，超过3天会产生仓储费，由收货人支付给我司,第4-5天 =50$/天/柜，第6-9天=70$/天/柜，10天以上=100$/天/柜\n1.Don't receive lithium battery cargo,        2.  3 days for free storage fee in Vorsino.If more than 3 days, the storage fee will be charged,From day 4 to day 5 =50$/day/container,From day 6 to day 9 = 70$/day/container,From day 10 =100$/day/container  3. 提前10天取消舱位免收亏舱费，发运前8-10天取消舱位300美金亏舱费，发运前5-8天取消500美金亏舱费，发运前3-5天取消按照报价的80%收取亏舱费，发运前3天内取消收取全额亏仓费。 4.班列不强制要求预审文件，如果需要预审文件销售自行承担USD25/柜的预审文件费，同时预审文件必须要在班列发运前2个工作日提供。\n5.装箱方案审",
   *     "Soc20Gp": null,
   *     "Soc40Gp": null,
   *     "Soc20Hd": null,
   *     "Soc40Hq": null,
   *     "Soc45Hq": null,
   *     "Coc20Gp": null,
   *     "Coc40Gp": null,
   *     "Coc20Hd": null,
   *     "Coc40Hq": 5150,
   *     "Coc45Hq": null,
   *     "Ag20Gp": null,
   *     "Ag40Gp": null,
   *     "Ag20Hd": null,
   *     "Ag40Hq": null,
   *     "Ag45Hq": null,
   *     "OC20Gp": null,
   *     "OC40Gp": null,
   *     "OC20Hd": null,
   *     "OC40Hq": null,
   *     "OC45Hq": null,
   *     "ExRateMemo": "\n",
   *     "SocID": "0",
   *     "CocID": "1",
   *     "AgentID": "0",
   *     "BoxType": "租箱",
   *     "BoxFeeMemo": "20GP:0\n20HD:0\n40GP:0\n40HQ:0\n45HQ:0",
   *     "BoxErrMemo": "无买箱价格",
   *     "Creator": "欧阳琴"
   *   },
   *   {
   *     "Id": "220721373102688256",
   *     "segMemo": "全程",
   *     "Traffic": "RAIL/铁运",
   *     "Line": "中俄",
   *     "LineRemark": "公司给销售的统一低价是广州提箱USD2700/40HQ，深圳提箱USD2600. 对外报价不能低于USD2800，要求提箱时，特别注意地梁和箱体四周情况完好，不能有扭曲变形，腐锈甚至漏光等情况出现，大朗车站不接受此类箱，需要退场修箱，请修好箱后进站；否则将会产生额外修箱费用或耽误班列发运，提柜出了堆场修箱费自行承担，请销售将修箱费计入报价成本，务必引起重视。俄罗斯其他车站单独确认Other station in Russia  additional inquiry.",
   *     "Placer": "",
   *     "Portl": "大朗",
   *     "TransPort": "",
   *     "EntryPort": "满洲里",
   *     "Dest": "沃尔西诺",
   *     "PickList": null,
   *     "DeliveryList": null,
   *     "ValidDateFr": "2020-09-01 00:00:00",
   *     "ValidDateTo": "2022-09-30 00:00:00",
   *     "TransDate": "周二",
   *     "TransDays": 15,
   *     "Currcd": "$",
   *     "EndConDt": "",
   *     "EndCastDt": "",
   *     "EndbillDt": "",
   *     "Remark": "1.不接锂电池货物，2.沃尔西诺免堆期为3天，超过3天会产生仓储费，由收货人支付给我司,第4-5天 =50$/天/柜，第6-9天=70$/天/柜，10天以上=100$/天/柜3. 提前10天取消舱位免收亏舱费，发运前8-10天取消舱位300美金亏舱费，发运前5-8天取消500美金亏舱费，发运前3-5天取消按照报价的80%收取亏舱费，发运前3天内取消收取全额亏仓费。 4.班列不强制要求预审文件，如果需要预审文件销售自行承担USD25/柜的预审文件费，同时预审文件必须要在班列发运前2个工作日提供。\n5.装箱方案审核费用300RMB/柜 只要安排审核，不管集装箱最终发不发运，都会产生 6含一票报转",
   *     "Soc20Gp": null,
   *     "Soc40Gp": null,
   *     "Soc20Hd": null,
   *     "Soc40Hq": null,
   *     "Soc45Hq": null,
   *     "Coc20Gp": null,
   *     "Coc40Gp": null,
   *     "Coc20Hd": null,
   *     "Coc40Hq": 5700,
   *     "Coc45Hq": null,
   *     "Ag20Gp": null,
   *     "Ag40Gp": null,
   *     "Ag20Hd": null,
   *     "Ag40Hq": null,
   *     "Ag45Hq": null,
   *     "OC20Gp": null,
   *     "OC40Gp": null,
   *     "OC20Hd": null,
   *     "OC40Hq": null,
   *     "OC45Hq": null,
   *     "ExRateMemo": "\n",
   *     "SocID": "0",
   *     "CocID": "1",
   *     "AgentID": "0",
   *     "BoxType": "租箱",
   *     "BoxFeeMemo": "20GP:0\n20HD:0\n40GP:0\n40HQ:0\n45HQ:0",
   *     "BoxErrMemo": "无买箱价格",
   *     "Creator": "欧阳琴"
   *   },
   *   {
   *     "Id": "220721373111076864",
   *     "segMemo": "全程",
   *     "Traffic": "RAIL/铁运",
   *     "Line": "中俄",
   *     "LineRemark": "公司给销售的统一低价是广州提箱USD2700/40HQ，深圳提箱USD2600. 对外报价不能低于USD2800，要求提箱时，特别注意地梁和箱体四周情况完好，不能有扭曲变形，腐锈甚至漏光等情况出现，大朗车站不接受此类箱，需要退场修箱，请修好箱后进站；否则将会产生额外修箱费用或耽误班列发运，提柜出了堆场修箱费自行承担，请销售将修箱费计入报价成本，务必引起重视。俄罗斯其他车站单独确认Other station in Russia  additional inquiry.",
   *     "Placer": "",
   *     "Portl": "大朗",
   *     "TransPort": "",
   *     "EntryPort": "满洲里",
   *     "Dest": "沃尔西诺",
   *     "PickList": null,
   *     "DeliveryList": null,
   *     "ValidDateFr": "2020-09-01 00:00:00",
   *     "ValidDateTo": "2022-09-30 00:00:00",
   *     "TransDate": "周二",
   *     "TransDays": 15,
   *     "Currcd": "$",
   *     "EndConDt": "",
   *     "EndCastDt": "",
   *     "EndbillDt": "",
   *     "Remark": "1.不接锂电池货物，2.沃尔西诺免堆期为3天，超过3天会产生仓储费，由收货人支付给我司,第4-5天 =50$/天/柜，第6-9天=70$/天/柜，10天以上=100$/天/柜3. 提前10天取消舱位免收亏舱费，发运前8-10天取消舱位300美金亏舱费，发运前5-8天取消500美金亏舱费，发运前3-5天取消按照报价的80%收取亏舱费，发运前3天内取消收取全额亏仓费。 4.班列不强制要求预审文件，如果需要预审文件销售自行承担USD25/柜的预审文件费，同时预审文件必须要在班列发运前2个工作日提供。\n5.装箱方案审核费用300RMB/柜 只要安排审核，不管集装箱最终发不发运，都会产生 6含一票报转",
   *     "Soc20Gp": null,
   *     "Soc40Gp": null,
   *     "Soc20Hd": null,
   *     "Soc40Hq": null,
   *     "Soc45Hq": null,
   *     "Coc20Gp": null,
   *     "Coc40Gp": null,
   *     "Coc20Hd": null,
   *     "Coc40Hq": 5600,
   *     "Coc45Hq": null,
   *     "Ag20Gp": null,
   *     "Ag40Gp": null,
   *     "Ag20Hd": null,
   *     "Ag40Hq": null,
   *     "Ag45Hq": null,
   *     "OC20Gp": null,
   *     "OC40Gp": null,
   *     "OC20Hd": null,
   *     "OC40Hq": null,
   *     "OC45Hq": null,
   *     "ExRateMemo": "\n",
   *     "SocID": "0",
   *     "CocID": "1",
   *     "AgentID": "0",
   *     "BoxType": "租箱",
   *     "BoxFeeMemo": "20GP:0\n20HD:0\n40GP:0\n40HQ:0\n45HQ:0",
   *     "BoxErrMemo": "无买箱价格",
   *     "Creator": "欧阳琴"
   *   },
   *   {
   *     "Id": "256593536233719808",
   *     "segMemo": "全程",
   *     "Traffic": "RAIL/铁运",
   *     "Line": "中俄",
   *     "LineRemark": "Pls send your booking to Jenny's Email (jenny@nep-logistics.com，june@nep-logistics.com（12月1日班列底价       12月1日班列：俄铁箱USD4000/40HQ  客户自备箱USD3100/40HC    40GP  USD3100+箱管租箱费                  12月8日班列：俄铁箱：对外指导报价USD4950   1215/1222/1229班列：俄铁箱底价USD4800/对外指导报价SUD5600",
   *     "Placer": "",
   *     "Portl": "大朗",
   *     "TransPort": "",
   *     "EntryPort": "满洲里",
   *     "Dest": "沃尔西诺",
   *     "PickList": "",
   *     "DeliveryList": "",
   *     "ValidDateFr": "2020-12-09 00:00:00",
   *     "ValidDateTo": "2022-12-31 00:00:00",
   *     "TransDate": "",
   *     "TransDays": 15,
   *     "Currcd": "$",
   *     "EndConDt": "",
   *     "EndCastDt": "",
   *     "EndbillDt": "",
   *     "Remark": "1.不接锂电池货物，2.沃尔西诺免堆期为3天，超过3天会产生仓储费，由收货人支付给我司,第4-5天 =50$/天/柜，第6-9天=70$/天/柜，10天以上=100$/天/柜 3. 提前10天取消舱位免收亏舱费，发运前8-10天取消舱位300美金亏舱费，发运前5-8天取消500美金亏舱费，发运前3-5天取消按照报价的80%收取亏舱费，发运前3天内取消收取全额亏仓费。 4.班列不强制要求预审文件，如果需要预审文件销售自行承担USD25/柜的预审文件费，同时预审文件必须要在班列发运前2个工作日提供。\n5.装箱方案审核费用300RMB/柜 只要安排审核，不管集装箱最终发不发运，都会产生 6,运费含一票报转。7.要求提箱时，特别注意地梁和箱体四周情况完好，不能有扭曲变形，腐锈甚至漏光等情况出现，大朗车站不接受此类箱，需要退场修箱，请修好箱后进站；否则将会产生额外修箱费用或耽误班列发运，提柜出了堆场修箱费自行承担，请销售将修箱费计入报价成本，务必引起重视\n6.到站明斯克还箱莫斯科+100USD/柜异地还箱费，如果集装箱所装货物含有有色金属，则不参考以上运价",
   *     "Soc20Gp": 100,
   *     "Soc40Gp": 500,
   *     "Soc20Hd": 300,
   *     "Soc40Hq": 3700,
   *     "Soc45Hq": 800,
   *     "Coc20Gp": 200,
   *     "Coc40Gp": 600,
   *     "Coc20Hd": 400,
   *     "Coc40Hq": 7800,
   *     "Coc45Hq": 900,
   *     "Ag20Gp": null,
   *     "Ag40Gp": null,
   *     "Ag20Hd": null,
   *     "Ag40Hq": null,
   *     "Ag45Hq": null,
   *     "OC20Gp": 100,
   *     "OC40Gp": 500,
   *     "OC20Hd": 300,
   *     "OC40Hq": 3700,
   *     "OC45Hq": 800,
   *     "ExRateMemo": "\n",
   *     "SocID": "5",
   *     "CocID": "5",
   *     "AgentID": "0",
   *     "BoxType": "租箱",
   *     "BoxFeeMemo": "20GP:0\n20HD:0\n40GP:0\n40HQ:0\n45HQ:0",
   *     "BoxErrMemo": "无买箱价格",
   *     "Creator": "欧阳琴"
   *   }
   * ]
   * */
  // if (process.env.NODE_ENV === 'development') {
  //   return delay(200).then(() => ({
  //     CurrentPage: 1,
  //     Items: [
  //       {
  //         Id: '395514385257178112',
  //         segMemo: '全程',
  //         Traffic: '',
  //         Line: '中俄,中蒙',
  //         LineRemark: '123',
  //         Placer: '',
  //         Portl: '别基米扬卡（萨马拉）',
  //         TransPort: '',
  //         EntryPort: '霍尔果斯',
  //         Dest: '贝奇亚赫（专用线站）',
  //         PickList: '',
  //         DeliveryList: '',
  //         ValidDateFr: '2023-07-11 00:00:00',
  //         ValidDateTo: '2023-07-14 00:00:00',
  //         TransDate: '',
  //         TransDays: 0,
  //         Currcd: 'USD',
  //         CurrSign: '$',
  //         EndConDt: '',
  //         EndCastDt: '',
  //         EndbillDt: '',
  //         Remark: '321',
  //         Soc20Gp: 6880.44,
  //         Soc40Gp: 4652.29,
  //         Soc20Hd: 78.49,
  //         Soc40Hq: 3563.6,
  //         Soc45Hq: 543.97,
  //         Coc20Gp: 6880.3,
  //         Coc40Gp: 4733.64,
  //         Coc20Hd: 217.09,
  //         Coc40Hq: 3516.2,
  //         Coc45Hq: 547.71,
  //         Ag20Gp: 6880.3,
  //         Ag40Gp: 4707.59,
  //         Ag20Hd: 791.17,
  //         Ag40Hq: 3457.3,
  //         Ag45Hq: 553.81,
  //         OC20Gp: 6880.44,
  //         OC40Gp: 4652.29,
  //         OC20Hd: 78.49,
  //         OC40Hq: 3563.6,
  //         OC45Hq: 543.97,
  //         ExRateMemo: '[全程] CNY->USD:0.1386\n',
  //         SocID: '5',
  //         CocID: '5',
  //         AgentID: '5',
  //         BoxType: '租箱',
  //         BoxFeeMemo: '20GP:0\n20HD:0\n40GP:0\n40HQ:0\n45HQ:0',
  //         BoxErrMemo: '无买箱价格',
  //         Creator: '曾家淳',
  //         NormalRemark: '147'
  //       },
  //       {
  //         Id: '395514385257178112',
  //         segMemo: '全程',
  //         Traffic: '',
  //         Line: '中俄,中蒙',
  //         LineRemark: '123',
  //         Placer: '',
  //         Portl: '别基米扬卡（萨马拉）',
  //         TransPort: '',
  //         EntryPort: '霍尔果斯',
  //         Dest: '贝奇亚赫（专用线站）',
  //         PickList: '',
  //         DeliveryList: '',
  //         ValidDateFr: '2023-07-11 00:00:00',
  //         ValidDateTo: '2023-07-14 00:00:00',
  //         TransDate: '',
  //         TransDays: 0,
  //         Currcd: 'USD',
  //         CurrSign: '$',
  //         EndConDt: '',
  //         EndCastDt: '',
  //         EndbillDt: '',
  //         Remark: '321',
  //         Soc20Gp: 6880.44,
  //         Soc40Gp: 4652.29,
  //         Soc20Hd: 78.49,
  //         Soc40Hq: 3563.6,
  //         Soc45Hq: 543.97,
  //         Coc20Gp: 6880.3,
  //         Coc40Gp: 4733.64,
  //         Coc20Hd: 217.09,
  //         Coc40Hq: 3516.2,
  //         Coc45Hq: 547.71,
  //         Ag20Gp: 6880.3,
  //         Ag40Gp: 4707.59,
  //         Ag20Hd: 791.17,
  //         Ag40Hq: 3457.3,
  //         Ag45Hq: 553.81,
  //         OC20Gp: 6880.44,
  //         OC40Gp: 4652.29,
  //         OC20Hd: 78.49,
  //         OC40Hq: 3563.6,
  //         OC45Hq: 543.97,
  //         ExRateMemo: '[全程] CNY->USD:0.1386\n',
  //         SocID: '5',
  //         CocID: '5',
  //         AgentID: '5',
  //         BoxType: '租箱',
  //         BoxFeeMemo: '20GP:0\n20HD:0\n40GP:0\n40HQ:0\n45HQ:0',
  //         BoxErrMemo: '无买箱价格',
  //         Creator: '曾家淳',
  //         NormalRemark: '147'
  //       },
  //       {
  //         Id: '395514385257178112',
  //         segMemo: '全程',
  //         Traffic: '',
  //         Line: '中俄,中蒙',
  //         LineRemark: '123',
  //         Placer: '',
  //         Portl: '别基米扬卡（萨马拉）',
  //         TransPort: '',
  //         EntryPort: '霍尔果斯',
  //         Dest: '贝奇亚赫（专用线站）',
  //         PickList: '',
  //         DeliveryList: '',
  //         ValidDateFr: '2023-07-11 00:00:00',
  //         ValidDateTo: '2023-07-14 00:00:00',
  //         TransDate: '',
  //         TransDays: 0,
  //         Currcd: 'USD',
  //         CurrSign: '$',
  //         EndConDt: '',
  //         EndCastDt: '',
  //         EndbillDt: '',
  //         Remark: '321',
  //         Soc20Gp: 6880.44,
  //         Soc40Gp: 4652.29,
  //         Soc20Hd: 78.49,
  //         Soc40Hq: 3563.6,
  //         Soc45Hq: 543.97,
  //         Coc20Gp: 6880.3,
  //         Coc40Gp: 4733.64,
  //         Coc20Hd: 217.09,
  //         Coc40Hq: 3516.2,
  //         Coc45Hq: 547.71,
  //         Ag20Gp: 6880.3,
  //         Ag40Gp: 4707.59,
  //         Ag20Hd: 791.17,
  //         Ag40Hq: 3457.3,
  //         Ag45Hq: 553.81,
  //         OC20Gp: 6880.44,
  //         OC40Gp: 4652.29,
  //         OC20Hd: 78.49,
  //         OC40Hq: 3563.6,
  //         OC45Hq: 543.97,
  //         ExRateMemo: '[全程] CNY->USD:0.1386\n',
  //         SocID: '5',
  //         CocID: '5',
  //         AgentID: '5',
  //         BoxType: '租箱',
  //         BoxFeeMemo: '20GP:0\n20HD:0\n40GP:0\n40HQ:0\n45HQ:0',
  //         BoxErrMemo: '无买箱价格',
  //         Creator: '曾家淳',
  //         NormalRemark: '147'
  //       },
  //       {
  //         Id: '395514385257178112',
  //         segMemo: '全程',
  //         Traffic: '',
  //         Line: '中俄,中蒙',
  //         LineRemark: '123',
  //         Placer: '',
  //         Portl: '别基米扬卡（萨马拉）',
  //         TransPort: '',
  //         EntryPort: '霍尔果斯',
  //         Dest: '贝奇亚赫（专用线站）',
  //         PickList: '',
  //         DeliveryList: '',
  //         ValidDateFr: '2023-07-11 00:00:00',
  //         ValidDateTo: '2023-07-14 00:00:00',
  //         TransDate: '',
  //         TransDays: 0,
  //         Currcd: 'USD',
  //         CurrSign: '$',
  //         EndConDt: '',
  //         EndCastDt: '',
  //         EndbillDt: '',
  //         Remark: '321',
  //         Soc20Gp: 6880.44,
  //         Soc40Gp: 4652.29,
  //         Soc20Hd: 78.49,
  //         Soc40Hq: 3563.6,
  //         Soc45Hq: 543.97,
  //         Coc20Gp: 6880.3,
  //         Coc40Gp: 4733.64,
  //         Coc20Hd: 217.09,
  //         Coc40Hq: 3516.2,
  //         Coc45Hq: 547.71,
  //         Ag20Gp: 6880.3,
  //         Ag40Gp: 4707.59,
  //         Ag20Hd: 791.17,
  //         Ag40Hq: 3457.3,
  //         Ag45Hq: 553.81,
  //         OC20Gp: 6880.44,
  //         OC40Gp: 4652.29,
  //         OC20Hd: 78.49,
  //         OC40Hq: 3563.6,
  //         OC45Hq: 543.97,
  //         ExRateMemo: '[全程] CNY->USD:0.1386\n',
  //         SocID: '5',
  //         CocID: '5',
  //         AgentID: '5',
  //         BoxType: '租箱',
  //         BoxFeeMemo: '20GP:0\n20HD:0\n40GP:0\n40HQ:0\n45HQ:0',
  //         BoxErrMemo: '无买箱价格',
  //         Creator: '曾家淳',
  //         NormalRemark: '147'
  //       },
  //       {
  //         Id: '395514385257178112',
  //         segMemo: '全程',
  //         Traffic: '',
  //         Line: '中俄,中蒙',
  //         LineRemark: '123',
  //         Placer: '',
  //         Portl: '别基米扬卡（萨马拉）',
  //         TransPort: '',
  //         EntryPort: '霍尔果斯',
  //         Dest: '贝奇亚赫（专用线站）',
  //         PickList: '',
  //         DeliveryList: '',
  //         ValidDateFr: '2023-07-11 00:00:00',
  //         ValidDateTo: '2023-07-14 00:00:00',
  //         TransDate: '',
  //         TransDays: 0,
  //         Currcd: 'USD',
  //         CurrSign: '$',
  //         EndConDt: '',
  //         EndCastDt: '',
  //         EndbillDt: '',
  //         Remark: '321',
  //         Soc20Gp: 6880.44,
  //         Soc40Gp: 4652.29,
  //         Soc20Hd: 78.49,
  //         Soc40Hq: 3563.6,
  //         Soc45Hq: 543.97,
  //         Coc20Gp: 6880.3,
  //         Coc40Gp: 4733.64,
  //         Coc20Hd: 217.09,
  //         Coc40Hq: 3516.2,
  //         Coc45Hq: 547.71,
  //         Ag20Gp: 6880.3,
  //         Ag40Gp: 4707.59,
  //         Ag20Hd: 791.17,
  //         Ag40Hq: 3457.3,
  //         Ag45Hq: 553.81,
  //         OC20Gp: 6880.44,
  //         OC40Gp: 4652.29,
  //         OC20Hd: 78.49,
  //         OC40Hq: 3563.6,
  //         OC45Hq: 543.97,
  //         ExRateMemo: '[全程] CNY->USD:0.1386\n',
  //         SocID: '5',
  //         CocID: '5',
  //         AgentID: '5',
  //         BoxType: '租箱',
  //         BoxFeeMemo: '20GP:0\n20HD:0\n40GP:0\n40HQ:0\n45HQ:0',
  //         BoxErrMemo: '无买箱价格',
  //         Creator: '曾家淳',
  //         NormalRemark: '147'
  //       },
  //       {
  //         Id: '395514385257178112',
  //         segMemo: '全程',
  //         Traffic: '',
  //         Line: '中俄,中蒙',
  //         LineRemark: '123',
  //         Placer: '',
  //         Portl: '别基米扬卡（萨马拉）',
  //         TransPort: '',
  //         EntryPort: '霍尔果斯',
  //         Dest: '贝奇亚赫（专用线站）',
  //         PickList: '',
  //         DeliveryList: '',
  //         ValidDateFr: '2023-07-11 00:00:00',
  //         ValidDateTo: '2023-07-14 00:00:00',
  //         TransDate: '',
  //         TransDays: 0,
  //         Currcd: 'USD',
  //         CurrSign: '$',
  //         EndConDt: '',
  //         EndCastDt: '',
  //         EndbillDt: '',
  //         Remark: '321',
  //         Soc20Gp: 6880.44,
  //         Soc40Gp: 4652.29,
  //         Soc20Hd: 78.49,
  //         Soc40Hq: 3563.6,
  //         Soc45Hq: 543.97,
  //         Coc20Gp: 6880.3,
  //         Coc40Gp: 4733.64,
  //         Coc20Hd: 217.09,
  //         Coc40Hq: 3516.2,
  //         Coc45Hq: 547.71,
  //         Ag20Gp: 6880.3,
  //         Ag40Gp: 4707.59,
  //         Ag20Hd: 791.17,
  //         Ag40Hq: 3457.3,
  //         Ag45Hq: 553.81,
  //         OC20Gp: 6880.44,
  //         OC40Gp: 4652.29,
  //         OC20Hd: 78.49,
  //         OC40Hq: 3563.6,
  //         OC45Hq: 543.97,
  //         ExRateMemo: '[全程] CNY->USD:0.1386\n',
  //         SocID: '5',
  //         CocID: '5',
  //         AgentID: '5',
  //         BoxType: '租箱',
  //         BoxFeeMemo: '20GP:0\n20HD:0\n40GP:0\n40HQ:0\n45HQ:0',
  //         BoxErrMemo: '无买箱价格',
  //         Creator: '曾家淳',
  //         NormalRemark: '147'
  //       },
  //       {
  //         Id: '395514385257178112',
  //         segMemo: '全程',
  //         Traffic: '',
  //         Line: '中俄,中蒙',
  //         LineRemark: '123',
  //         Placer: '',
  //         Portl: '别基米扬卡（萨马拉）',
  //         TransPort: '',
  //         EntryPort: '霍尔果斯',
  //         Dest: '贝奇亚赫（专用线站）',
  //         PickList: '',
  //         DeliveryList: '',
  //         ValidDateFr: '2023-07-11 00:00:00',
  //         ValidDateTo: '2023-07-14 00:00:00',
  //         TransDate: '',
  //         TransDays: 0,
  //         Currcd: 'USD',
  //         CurrSign: '$',
  //         EndConDt: '',
  //         EndCastDt: '',
  //         EndbillDt: '',
  //         Remark: '321',
  //         Soc20Gp: 6880.44,
  //         Soc40Gp: 4652.29,
  //         Soc20Hd: 78.49,
  //         Soc40Hq: 3563.6,
  //         Soc45Hq: 543.97,
  //         Coc20Gp: 6880.3,
  //         Coc40Gp: 4733.64,
  //         Coc20Hd: 217.09,
  //         Coc40Hq: 3516.2,
  //         Coc45Hq: 547.71,
  //         Ag20Gp: 6880.3,
  //         Ag40Gp: 4707.59,
  //         Ag20Hd: 791.17,
  //         Ag40Hq: 3457.3,
  //         Ag45Hq: 553.81,
  //         OC20Gp: 6880.44,
  //         OC40Gp: 4652.29,
  //         OC20Hd: 78.49,
  //         OC40Hq: 3563.6,
  //         OC45Hq: 543.97,
  //         ExRateMemo: '[全程] CNY->USD:0.1386\n',
  //         SocID: '5',
  //         CocID: '5',
  //         AgentID: '5',
  //         BoxType: '租箱',
  //         BoxFeeMemo: '20GP:0\n20HD:0\n40GP:0\n40HQ:0\n45HQ:0',
  //         BoxErrMemo: '无买箱价格',
  //         Creator: '曾家淳',
  //         NormalRemark: '147'
  //       }
  //     ],
  //     ItemsPerPage: 20,
  //     TotalItems: 5,
  //     TotalPages: 1
  //   }))
  // }

  return request({
    url: "/api/v1/railpricefcl-list",
    method: "get",
    once: true,
    params: {
      LegType: "A",
      // Placer: "",
      // portlCd: "DLANG",
      firstLeg: "F",
      lastLeg: "F",
      headLeg: "F",
      hashistory: "F",
      EntryPortCd: "",
      EntryPort: "",
      PickConPlace: "",
      ReturnPlace: "",
      BoxType: "Z",
      showCol: "[\"Soc\",\"Coc\",\"Ag\",\"OC\"]",
      currcd: "USD",
      queryDate: "",
      LrManager: "",
      sort: "asc",
      sortName: "Coc45Hq",
      ourBox: "F",
      ...query
    }
  });
}

export function fetchRailTrainFrequencyList(query){

  return request({
    url: "/api/v1/trainrailprice-list",
    method: "get",
    once: true,
    params: {
      // LegType: "A",
      // // Placer: "",
      // // portlCd: "DLANG",
      // firstLeg: "F",
      // lastLeg: "F",
      // headLeg: "F",
      // hashistory: "F",
      // EntryPortCd: "",
      // EntryPort: "",
      // PickConPlace: "",
      // ReturnPlace: "",
      // BoxType: "Z",
      // showCol: "[\"Soc\",\"Coc\",\"Ag\",\"OC\"]",
      // currcd: "USD",
      // queryDate: "",
      // LrManager: "",
      // sort: "asc",
      // sortName: "Coc45Hq",
      // ourBox: "F",
      ...query
    }
  });
}

// 查运价-海运/多式联运
export function fetchMultitrainTrainFrequencyList(query){
  return request({
    url: '/api/v1/multitrainfrequency-list',
    method: 'post',
    once: true,
    data: query
  }).then((response) => {
    if (response.data?.Items) {
      response.data.Items = response.data.Items.map((x) =>
        Object.assign(x, {
          SugarCabinCount: [
            parseFloat(x.Total20Gp) >= 0 ? `20GP*${x.Surplus20Gp}` : '',
            parseFloat(x.Total40Fr) >= 0 ? `40FR*${x.Surplus40Fr}` : '',
            parseFloat(x.Total40Gp) >= 0 ? `40GP*${x.Surplus40Gp}` : '',
            parseFloat(x.Total40Hq) >= 0 ? `40HQ*${x.Surplus40Hq}` : '',
            parseFloat(x.Total40Rf) >= 0 ? `40RF*${x.Surplus40Rf}` : '',
            parseFloat(x.Total45Hq) >= 0 ? `45HQ*${x.Surplus45Hq}` : ''
          ]
            .filter(Boolean)
            .join('/')
        })
      )
    }

    return response
  })
}





// 获取起运站/目的站
export function getRailStations() {
  return request({
    url: "/api/v1/railport-query",
    method: "get",
    params: {
      key: "",
      type: ""
    },
    once: true
    // cache: {
    //   type: "local",
    //   ttl: 7 * 24 * 3600,
    // },
  });
}

// export function railPortOptions(type) {
//   return request({
//     url: "/api/v1/railport-query",
//     method: "get",
//     params: {
//       type,
//     },
//
//   });
// }

// 提交订单
export function AddOrder(data) {
  return request({
    url: "/api/v1/railorderonline",
    method: "post",
    data: data
  });
}

// 更新订单
export function UpdateOrder(data) {
  return request({
    url: "/api/v1/railorderonline",
    method: "put",
    data: data
  });
}

// 获取装货港/卸货港
export function SeaPortOptions(key) {
  return request({
    url: "/api/v1/seaport-query",
    method: "get",
    params: {
      key
    }
  });
}

// 获取空运始发站/空运目的站
export function AirPortOptions(key) {
  return request({
    url: "/api/v1/airport-query",
    method: "get",
    params: {
      key
    }
  });
}

// 获取船东列表
export function carrierOptions(type, filter = "") {
  return request({
    url: "/api/v1/carrier-query",
    method: "get",
    params: {
      key: filter,
      type
    }
  });
}

// export function UpdateOrder(data) {
//   var queryObj = formatQuery({})
//   return request({
//     url: '/api/v1/railorderonline',
//     method: 'put',
//     data: data,
//     params: queryObj
//   })
// }

export function getCargoTree(params) {
  return request({
    url: "api/v1/category-list",
    method: "get",
    params: {
      ...params,
      pageIndex: 1,
      pageSize: 9999
    },
    once: true,
    cache: {
      type: "local",
      ttl: 24 * 3600
    }
  });
}

// 获取铁运中转站
export function getTransPortList(key) {
  return request({
    url: "/api/v1/dialog-railport",
    method: "get",
    params:{
      key,
      pageIndex:1,
      pageSize:9999
    },
    once: true

  });
}
