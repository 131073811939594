<template>
  <div class="oms-extend__date-picker-range oms-input-style el-range-editor" :class="{'is-active':focusState}">
    <el-date-picker @focus="focusState=true;" @blur="focusState=false;" class="shr" v-model="start"
                    :picker-options="pickerOptionsStart"
                    @change="$emit('update:start-date',start)" :placeholder="$t('TestKey.StartDate')" value-format="yyyy-MM-dd"/>
    <span class="block plr-5">-</span>
    <el-date-picker @focus="focusState=true;" @blur="focusState=false;" class="shr last-child" v-model="end"
                    :picker-options="pickerOptionsEnd"
                    @change="$emit('update:end-date',end)" :placeholder="$t('TestKey.EndDate')" value-format="yyyy-MM-dd"/>
    <span class="close el-input__suffix" @click="$emit('update:start-date','');$emit('update:end-date','');">
      <span class="el-input__suffix-inner">
        <i class="el-input__icon el-icon-circle-close"></i>
      </span>
    </span>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "OmsDatePicker",
  props: {
    startDate: [String, Date],
    endDate: [String, Date],
  },
  watch: {
    startDate(val) {
      this.start = val;
    },
    endDate(val) {
      this.end = val;
    },
  },
  data() {
    return {
      start: "",
      end: "",
      focusState: false,
    };
  },
  computed: {
    pickerOptionsStart() {
      return {
        disabledDate: (time) => {
          return time.getTime() > moment(this.end).valueOf();
        },
      };
    },
    pickerOptionsEnd() {
      return {
        disabledDate: (time) => {
          return time.getTime() < moment(this.start).valueOf();
        },
      };
    },
  },
};
</script>

<style lang="scss">
.oms-extend__date-picker-range {
  display: flex;
  align-items: center;
  padding: 0;
  position: relative;
  transition: .2s linear;

  .el-input {
    display: flex;
    align-items: center;

    input {
      padding: 0;
      border: none;
      text-align: center;
      font-size: 14px;
      order: 2;
    }

    .el-input__prefix {
      position: relative;
      font-size: 14px;
      order: 1;
      left: 0;
      padding-left: 5px;
    }

    .el-input__suffix {
      display: none;
    }
  }

  .last-child {
    .el-input__prefix {
      display: none;
    }

    input {
      padding-right: 35px;
    }
  }

  .shr {
    flex-shrink: 0;
    flex-grow: 1;
    width: 0;
  }

  .close {
    position: absolute;
    right: 5px;
    display: none;
  }

  &:hover {
    .close {
      display: block;
    }
  }
}
</style>