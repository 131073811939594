import Vue        from "vue";
import "./numberFilter";
import moment     from "moment-timezone";
import * as utils from "@/utils";

let ignoreFields = ["encodeDataForQuery", "decodeQueryForData"];
Object.keys(utils).filter(x => !ignoreFields.includes(x)).forEach(name => Vue.filter(name, utils[name]));

export const DisplayDateFilter = (value) => {
  if (!value) return value;
  const mom = moment(value);
  if (mom.isValid()) {
    return moment(value).format("YYYY-MM-DD");
  }

  return value;
};
Vue.filter("displayDate", DisplayDateFilter);
export let ValueFilter = (val) => {
  if (val !== 0) {
    return val === "" ? "--" : val ?? "--";
  } else {
    return val;
  }
};
Vue.filter("valueFilter", ValueFilter);

