import {AirPortOptions, carrierOptions, getCargoTree, getRailStations, SeaPortOptions,getTransPortList} from "@/api/business";
import pinyin                                                                          from "pinyin";
import {getOrderDetail}                                                                from "@/api/user";
import {getCityQueryList}                                                              from "@/api/customer";
import {conInfoOptions, getCartType, getCodeLibQuery}                                  from "@/api/options";
import {i18n}                                                                          from "@/app";
import _                                                                               from "lodash";

// 默认能代处理站点，通过callbackcOptions来自定义处理
function onProgressListSources(list, callback, callbackOptions) {
  const {zhCN, zhTW, en, ru, value} = callbackOptions ?? {};
  const listLocale = list.reduce(((total, item) => {
    let localeKey = value ? value(item) : item.value;
    total["zh-CN"][localeKey] = zhCN ? zhCN(item) : (item.PortNameChs || item.PortName);
    total["zh-TW"][localeKey] = zhTW ? zhTW(item) : (item.PortNameCht || item.PortNameChs || item.PortName);
    total["en"][localeKey] = en ? en(item) : (item.PortNameEng || item.PortName);
    total["ru"][localeKey] = ru ? ru(item) : (item.PortNameRus || item.PortNameEng || item.PortName);

    if (item.children?.length > 0) {
      onProgressListSources(item.children, callback, callbackOptions);
    }
    return total;
  }), {
    "zh-CN": {},
    "zh-TW": {},
    "en": {},
    "ru": {},
  });

  ["zh-CN", "en", "zh-TW", "ru"].forEach(locale => {
    let localData = callback(listLocale[locale]);
    i18n.mergeLocaleMessage(locale, localData);
  });
}

export default {
  namespaced: true,
  state: {
    hashStory: new Map(),
    portLHotStation: [],
    destHotStation: [],
    stations: [],
    TruckTypeModel: {
      I: [],
      O: [],
    },

    orderResponse: {},

    seaStations: [],
    airStations: [],
    truckStations: [],
    transPortStations: [],

    carrierList: {
      "A": [],
      "C": [],
    },
    cnts: [],

    categoryList: [],

    isProgressHotCity: false,

    hotLeftCitySources: [
      {
        lineClamp: 7,
        label: "珠三角大湾区/华南地区",
        list: [
          {label: "深圳平湖南", value: "Shenzhen"},
          {label: "广州国际港", value: "Guangzhou"},
          {label: "东莞大朗", value: "Dongguan"},
          {label: "湖南长沙北", value: "Hunan"},
          {label: "厦门市", value: "Xiameng"},
          {label: "龙岩市", value: "Longyan"},
          {label: "武夷山", value: "Wuyishan"},
        ]
      },
      {
        label: "华东/华中/长三角地区",
        list: [
          {label: "上海市", value: "Shanghai"},
          {label: "武汉", value: "Wuhan"},
          {label: "苏州", value: "Suzhou"},
          {label: "金华", value: "Jinhua"},
          {label: "郑州", value: "Zhenzhou"}
        ]
      },
      {
        label: "西南/西北/华北地区",
        list: [
          {label: "成都市", value: "Chengdu"},
          {label: "西安市", value: "Xian"},
          {label: "北京市", value: "Beijing"},
          {label: "石家庄", value: "Shijiazhou"},
          {label: "天津市", value: "Tianjing"},
          // {label: "山东省", value: "Shandong"},
          // {label: "山西省", value: "Shanxi"}
        ]
      }
    ],

    hotRightCitySources: [
      {
        lineClamp: 5,
        label: "中俄专线",
        list: [
          {"label": "莫斯科", "value": "Mosco"},
          {"label": "圣彼得堡", "value": "St.petersburg"},
          {"label": "电煤", "value": "Elektrougli"},
          {"label": "明斯克", "value": "Minsk"},
          {"label": "沃尔西诺", "value": "Vorsino"},
          {"label": "别雷", "value": "Berelast"}
        ]
      },
      {
        lineClamp: 5,
        label: "中亚五国",
        list: [
          {"label": "阿拉山口", "value": "Alashankou"},
          {"label": "霍尔果斯", "value": "Horgos"},
          {"label": "塔什干", "value": "Toshkent"},
          // {"label": "阿里木图", "value": "Alanty"},
          {"label": "哈萨克斯坦", "value": "Kazakhstan"},
          // {"label": "吉尔吉斯斯坦", "value": "Kyrgyzstan"},
          {"label": "塔吉克斯坦", "value": "Tajikistan"},
          // {"label": "乌兹别克斯坦", "value": "Uzbekistan"}
        ]
      },
      {
        lineClamp: 5,
        label: "欧洲地区",
        list: [
          {"label": "马拉", "value": "Malaszewicze"},
          {"label": "杜伊斯堡", "value": "Duisburg"},
          {"label": "汉堡", "value": "Hamburg"}
        ]
      },
      {
        lineClamp: 5,
        label: "东南亚",
        list: [
          {"label": "万象", "value": "Vientiane"}
        ]
      }
    ],
  },
  mutations: {
    SET_TRUCK_TYPE(state, {key, data}) {
      state.TruckTypeModel[key] = data;
    },
    SET_BUSINESS(state, {codeClass, data}) {
      state.hashStory.set(codeClass, data);
    },
    SET_CNTS(state, list) {
      state.cnts = list || [];
      if (!(state.cnts instanceof Array)) {
        state.cnts = [];
      }
    },
    SET_STATION(state, origin) {
      origin = origin.map(x => Object.assign(x, {
        _type: "RAIL",
      }));
      state.portLHotStation = origin.filter(x => x.CommonPortl === "T");
      state.destHotStation = origin.filter(x => x.CommonDest === "T");
      state.stations = origin;
    },
    SET_SEA_STATION(state, list) {
      state.seaStations = list.map(x => Object.assign(x, {
        _type: "SEA",
      }));
    },
    SET_CARRIER_LIST(state, {type, list}) {
      state.carrierList[type] = list;
    },
    SET_AIR_STATION(state, list) {
      state.airStations = list.map(x => Object.assign(x, {
        _type: "AIR",
      }));
    },
    SET_TRUCK_STATION(state, list) {
      state.truckStations = list.map(x => Object.assign(x, {
        _type: "TRUCK",
      }));
    },
    SET_TRANS_STATION(state, list) {
      state.transPortStations = list.map(x => Object.assign(x, {
        _type: "RAIL",
      }));
    },
    SET_ORDER_RESPONSE(state, res) {
      state.orderResponse = res;
    },
    CLEAR_ORDER_RESPONSE(state) {
      state.orderResponse = null;
    },
    SET_CATEGORY_LIST(state, list) {
      state.categoryList = list;
    }
  },
  actions: {
    async GET_TRUCK_TYPE({commit, state}, inout) {
      if (state.TruckTypeModel[inout]?.length > 0) return;
      const resp = await getCartType({key: "", inout});

      commit("SET_TRUCK_TYPE", {
        key: inout,
        data: (Array.isArray(resp) ? resp : resp.data ?? resp.Items ?? []).map(x => ({
          ...x,
          label: i18n.$fKey(x.Type),
          value: x.Type,
        })),
      });
    },
    async GET_BUSINESS({commit, state}, codeClass) {
      if (state.hashStory.has(codeClass)) return;
      let data;

      // GlobalCodeClass.DOCTYPE： 文件分类

      if (codeClass === "IEID") {
        data = [{
          value: "E",
          label: i18n.t('TestKey.Export')
        }, {
          value: "I",
          label: i18n.t('TestKey.Import')
        }];
      } else if(codeClass === 'R_IEID'){
        data = [{
          value: "E",
          label: i18n.t('TestKey.Export')
        }, {
          value: "I",
          label: i18n.t('TestKey.Import')
        }, {
          value: "NM",
          label: i18n.t('内贸(国内运输)')
        }];
      } else {
        // await codeOptions
        let labelProp, valueProp;

        const response = await getCodeLibQuery(codeClass);
        data = Array.isArray(response) ? response : (response.Items ?? response.data);

        if (codeClass === "DOCTYPE") {
          // todo: 确认下拉文件分类所需要的label/value
        }

        if (labelProp && valueProp) {
          data = data.map(x => ({
            ...x,
            label: x[labelProp],
            value: x[valueProp],
          }));
        }

      }

      commit("SET_BUSINESS", {
        codeClass,
        data,
      });
    },
    async GET_CON_INFO_QUERY({commit, rootState}, key = "") {
      // if (rootState.business.cnts.length > 0) {
      //   return "";
      // }

      const list = await conInfoOptions(key);
      // console.log(list,'多式联运数据');
      if (!list || list?.success === false) {
        return list?.message || i18n.t("RequestMessage.GetConListError");
      }

      commit("SET_CNTS", list);
    },
    async GET_CARRIER_LIST({commit, rootState}, type = "C") {
      // if (rootState.business.carrierList[type]?.length > 0) {
      //   return "";
      // }

      let list = await carrierOptions(type);

      // console.log(list,'carrierOptions');
      if (!list || list?.success === false) {
        return list?.message || i18n.t("RequestMessage.GetCarrierListError");
      }

      const key = {
        "zh-CN": "CriNameChs",
        "zh-TW": "CriNameCht",
        "en": "CriNameEng",
        "ru": "CriNameRus",
      }[i18n.locale];
      const defaultKey = {
        "zh-CN": "CriNameChs",
        "zh-TW": "CriNameChs",
        "en": "CriNameEng",
        "ru": "CriNameEng",
      }[i18n.locale];

      list = list.map(x => ({
        ...x,
        CriName: x.CriNameChs?.trim() || x.CriNameCht?.trim() || x.CriNameEng?.trim() || x.CriName?.trim() || "",
        PortNameChs: x.CriNameChs?.trim() || "",
        PortNameCht: x.CriNameCht?.trim() || "",
        PortNameEng: x.CriNameEng?.trim() || "",
        PortNameRus: x.CriNameRus?.trim() || "",
        PortName: x.CityName?.trim(),
        name: (x?.[key] || x?.[defaultKey] || x?.CriName)?.trim() || "",
        label: x.CriNameChs?.trim() || x.CriNameCht?.trim() || x.CriNameEng?.trim() || x.CriName?.trim() || "",
        value: x.CriCd,
      }));

      onProgressListSources(list, data => ({
        ApiCarrier: {
          [type]: {
            Option: data,
          }
        }
      }), {
        zhCN: item => item.CriNameChs?.trim() || item.CriName?.trim(),
        zhTW: item => item.CriNameCht?.trim() || item.CriName?.trim(),
        en: item => item.CriNameEng?.trim() || item.CriName?.trim(),
        ru: item => item.CriNameRus?.trim() || item.CriName?.trim(),
      });

      commit("SET_CARRIER_LIST", {type, list});
    },
    // async GET_STATION_PORT() {
    //   const res = await railPortOptions('Portl');
    // },
    // async GET_STATION_DEST() {
    //   const res = await railPortOptions('Dest');
    // },
    async GET_ORDER_INFO({commit}, id) {
      const res = await getOrderDetail(id);
      commit("SET_ORDER_RESPONSE", res);
    },
    async GET_SEA_STATION({commit, rootState}, filter = "") {
      // if (rootState.business.seaStations.length > 0) {
      //   return "";
      // }

      let list = await SeaPortOptions(filter);

      if (!list || list?.message) {
        return list?.message || i18n.t("RequestMessage.GetListError");
      }

      const locale = i18n.locale;
      let key = locale === "zh-CN" ? "PortNameChs" : "PortNameEng";
      if (locale === "zh-CN") {
        key = "PortNameChn";
      } else if (locale === "en") {
        key = "PortNameEng";
      } else if (locale === "zh-TW") {
        key = "PortNameCht";
      } else {
        key = "PortNameRus";
      }

      const defaultKey = {
        "ru": "PortNameEng",
        "zh-CN": "PortNameChn",
        "en": "PortNameEng",
        "zh-TW": "PortNameChn",
      }[locale];

      list = list.map(x => ({
        ...x,
        PortName: x.PortNameChn || x?.PortName?.trim() || x?.[defaultKey]?.trim() || x.PortNameEng?.trim(),
        PortNameChs: x.PortNameChn?.trim() || x.PortNameEng?.trim(),
        name: x.PortNameChn || x[key]?.trim() || x.PortNameEng?.trim(),
        label: x.PortNameChn || x[key]?.trim() || x.PortNameEng?.trim(),
        value: x.PortCd,
        py: pinyin((x.PortNameChn)?.trim() || x.PortNameCht?.trim(), {
          style: pinyin.STYLE_NORMAL,
        }).join(""),
      }));

      onProgressListSources(list, data => ({
        ApiSeaPort: {
          Option: data
        }
      }));

      commit("SET_SEA_STATION", list);
    },
    async GET_TRUCK_STATION({commit, rootState}, filter = "") {
      // if (rootState.business.truckStations.length > 0) {
      //   return "";
      // }

      let list = await getCityQueryList(filter);
      // console.log(list,'汽运数据');
      if (!list || list?.message) {
        return list?.message || i18n.t("RequestMessage.GetTruckListError");
      }

      /**
       * CityCd: "LYI"
       * CityName: "临沂"
       * */
      const key = {
        "ru": "CityNameRus",
        "zh-CN": "CityNameChs",
        "zh-TW": "CityNameCht",
        "en": "CityNameEng",
      }[i18n.locale] || "CityName";

      list = list.map(x => ({
        ...x,
        PortNameRus: x.CityNameRus,
        PortNameCht: x.CityNameCht,
        PortNameEng: x.CityNameEng,
        PortName: x.CityName?.trim(),
        PortCd: x.CityCd,
        PortNameChs: (x[key] || x.CityName)?.trim(),
        name: (x[key] || x.CityName)?.trim(),
        label: x[key]?.trim() || x.CityName?.trim(),
        value: x.CityCd,
        py: pinyin(x.CityName?.trim(), {
          style: pinyin.STYLE_NORMAL,
        }).join(""),
      }));

      onProgressListSources(list, data => ({
        ApiCity: {
          Option: data,
        }
      }));

      commit("SET_TRUCK_STATION", list);
    },
    async GET_AIR_STATION({commit, rootState}, filter = "") {
      // if (rootState.business.airStations.length > 0) {
      //   return "";
      // }

      let list = await AirPortOptions(filter);
      // console.log(list,'空运数据');
      if (!list || list?.message) {
        return list?.message || i18n.t("RequestMessage.GetAirListError");
      }

      const key = {
        "ru": "PortNameRus",
        "zh-CN": "PortNameChs",
        "zh-TW": "PortNameCht",
        "en": "PortNameEng",
      }[i18n.locale] || "PortName";

      list = list.map(x => ({
        ...x,
        name: x?.[key]?.trim() || x.PortName?.trim(),
        label: x?.[key]?.trim() || x.PortName?.trim(),
        value: x.PortCd,
        py: pinyin((x.PortNameChs)?.trim() || x.PortName?.trim(), {
          style: pinyin.STYLE_NORMAL,
        }).join("") || x.PortName,
      }));

      onProgressListSources(list, data => ({
        ApiAirStation: {
          Option: data,
        }
      }));

      commit("SET_AIR_STATION", list);
    },
    async GET_HOT_CITY_SOURCES({commit, rootState, state, dispatch}) {
      if (state.isProgressHotCity) return;

      await Promise.all([
        dispatch("GET_STATION"),
        dispatch("GET_TRUCK_STATION"),
      ]);

      const keys = ["PortNameChn", "PortName", "PortNameEng", "PortNameCht", "PortNameRus",];
      // const getStr = x => `${x.PortNameChn || ""}${x.PortName || ""}${x.PortNameEng || ""}${x.PortNameCht || ""}${x.PortNameRus || ""}`;

      state.isProgressHotCity = true;

      [state.hotLeftCitySources.map(x => x.list), state.hotRightCitySources.map(x => x.list)].flat(Infinity).forEach(row => {
        const mainCondition = x => keys.some(prop => x[prop] === row.label);
        const backCondition = x => keys.some(prop => x[prop] && (x[prop].indexOf(row.label) >= 0 || row.label?.indexOf(x[prop]) >= 0));

        let result = state.stations.find(mainCondition);

        if (!result) {
          result = state.stations.find(backCondition);
        }

        if (!result) {
          result = state.truckStations.find(mainCondition);
        }


        if (!result) {
          result = state.truckStations.find(backCondition);
        }

        if (result) {
          const {label, value, ...rest} = result;
          Object.assign(row, rest, {
            _value: value,
          });
        } else {
          console.log("缺失数据源：", _.cloneDeep(row));
        }
      });

      console.log("处理完成");
    },
    async GET_STATION({commit, rootState, dispatch}) {
      // if (rootState.business.stations.length > 0) {
      //   return "";
      // }
      let list = await getRailStations();
      // console.log(list,'铁运数据');
      if (!list || list?.message) {
        return list?.message || i18n.t("RequestMessage.GetListError");
      }
      const locale = rootState.lang.i18n.locale;
      let key = locale === "zh-CN" ? "PortNameChs" : "PortNameEng";
      // console.log(locale,'locale');
      // const key = rootState.lang.locale
      if (locale === "zh-CN") {
        key = "PortNameChs";
      } else if (locale === "en") {
        key = "PortNameEng";
      } else if (locale === "zh-TW") {
        key = "PortNameCht";
      } else {
        key = "PortNameRus";
      }

      const defaultKey = {
        "ru": "PortNameEng",
        "en": "PortNameEng",
        "zh-CN": "PortNameChs",
        "zh-TW": "PortNameChs",
      }[i18n.locale];

      list = list.map(x => ({
        ...x,
        PortNameChs: x.PortNameChs?.trim() || x.PortName?.trim(),
        name: x[key]?.trim() || x[defaultKey]?.trim() || x.PortName?.trim(),
        label: x[key]?.trim() || x[defaultKey]?.trim() || x.PortName?.trim(),
        value: x.PortCd,
        py: pinyin((x.PortNameChs)?.trim() || x.PortName?.trim(), {
          style: pinyin.STYLE_NORMAL,
        }).join(""),
      }));

      onProgressListSources(list, data => ({
        ApiStation: {
          Option: data,
        }
      }));

      commit("SET_STATION", list);
    },
    async GET_CARGO_KIND_LIST({commit, state}) {
      if (state.categoryList?.length > 0) return;
      const resp = await getCargoTree({hasDisabled: "T"});
      if (resp?.Items?.length > 0) {
        let list = resp.Items;
        list = list.map(x => Object.assign({}, x, {
          CategoryNameKey: `ApiCategory.Option.${x.Id}`,
          children: (resp.SubItems ?? []).filter(y => y.Pid === x.Id).map(x => Object.assign({}, x, {
            CategoryNameKey: `ApiCategory.Option.${x.Id}`,
            value: x.Id,
          })),
          value: x.Id,
        }));

        onProgressListSources(list, data => ({
          ApiCategory: {
            Option: data,
          }
        }), {
          zhCN: item => item.CategoryName,
          zhTW: item => item.CategoryNameCht || item.CategoryName,
          en: item => item.CategoryNameEng || item.CategoryName,
          ru: item => item.CategoryNameRus || item.CategoryName,
        });

        commit("SET_CATEGORY_LIST", list);
      }
    },
    async GET_TRANS_STATION({commit, rootState}, filter = "") {

      let list = await getTransPortList(filter);

      if (!list || list?.message) {
        return list?.message || i18n.t("RequestMessage.GetListError");
      }

      const locale = i18n.locale;
      let key = locale === "zh-CN" ? "PortNameChs" : "PortNameEng";
      if (locale === "zh-CN") {
        key = "PortNameChs";
      } else if (locale === "en") {
        key = "PortNameEng";
      } else if (locale === "zh-TW") {
        key = "PortNameChs";
      } else {
        key = "PortNameRus";
      }

      const defaultKey = {
        "ru": "PortNameRus",
        "zh-CN": "PortNameChs",
        "en": "PortNameEng",
        "zh-TW": "PortNameChs",
      }[locale];

      list = list?.Items.map(x => ({
        ...x,
        PortNameChs: x.PortNameChs?.trim() || x.PortName?.trim(),
        name: x[key]?.trim() || x[defaultKey]?.trim() || x.PortName?.trim(),
        label: x[key]?.trim() || x[defaultKey]?.trim() || x.PortName?.trim(),
        value: x.PortCd,
        py: pinyin((x.PortNameChs)?.trim() || x.PortName?.trim(), {
          style: pinyin.STYLE_NORMAL,
        }).join(""),
      }));

      onProgressListSources(list, data => ({
        ApiTransPort: {
          Option: data
        }
      }));

      commit("SET_TRANS_STATION", list);
    },
  },
};
