// 判断非数字返回defaultValue
import Vue from "vue";

Vue.filter("numberFilter", (value, defaultValue = 0) => {
  if (isNaN(+value)) {
    return defaultValue;
  } else {
    return value;
  }
});
