<script>
import Element from "element-ui";

// 目前仅处理了 trigger: click 的场景
export default {
  name: "ElPopoverExtend",
  extends: Element.Popover,
  data() {
    return {
      actionWaitAsync: false,
    };
  },
  methods: {
    doToggle() {

      this.$super(Element.Popover).doToggle();
    },
    targetIsMessageBox(target) {
      if(!target) return false;
      if (target.className.indexOf("el-message-box__wrapper") >= 0) {
        return true;
      }

      if (target === document.body) {
        return false;
      }

      return this.targetIsMessageBox(target.parentNode);
    },
    async handleDocumentClick(e) {
      if (this.actionWaitAsync) return;

      // 检测点击元素来智能判定是否需要触发行为
      if (this.targetIsMessageBox(e.target)) return;

      let eventName = this.showPopper ? "before-close" : "before-show";
      const beforeResult = this.$listeners[eventName];

      if (beforeResult) {
        this.actionWaitAsync = true;
        const actionResult = await beforeResult();
        this.actionWaitAsync = false;
        if (actionResult === false) {
          return;
        }
      }

      this.$super(Element.Popover).handleDocumentClick(e);
    },
  }
};
</script>
