export default {
  name: "ElmControlMixins",
  destroyed() {
    if (this.ElmControl_ResizeObserver) {
      this.ElmControl_ResizeObserver.disconnect();
    }
    if (this.ElmControl_MutationObserver) {
      this.ElmControl_MutationObserver.disconnect();
    }
  },
  methods: {
    CreateElementResizeObserver(targetNode, eventCallback) {
      if(!window.ResizeObserver) return;
      const observer = new ResizeObserver((...args) => {
        eventCallback?.(...args);
      });

      observer.observe(targetNode);

      this.ElmControl_ResizeObserver = observer;

      return {
        // 销毁
        Destroy() {
          observer.disconnect();
        }
      };
    },
    CreateElementMutationObserver(targetNode, eventCallback) {
      if(!window.MutationObserver) return;
      const observer = new MutationObserver((...args) => {
        eventCallback?.(...args);
      });

      observer.observe(targetNode, {
        attributes: true,
        childList: true,
        subtree: true,
      });
      this.ElmControl_MutationObserver = observer;

      return {
        // 销毁
        Destroy() {
          observer.disconnect();
        }
      };
    },
  }
};
