<template>
  <el-input-number class="oms-input-number-extend" :class="[align]" v-bind="$attrs" v-on="$listeners"
                   v-model="dynamic"
                   :controls="false"/>
</template>

<script>
import {model} from "@/mixins";

export default {
  name: "OmsInputNumber",
  mixins: [model],
  props: {
    align: {type: String, default: "left"},
  }
};
</script>

<style scoped lang="scss">
.oms-input-number-extend {
  display: block;

  &::v-deep {
    &.el-input-number .el-input {
      display: flex;
    }

    &.left {
      input {
        text-align: left;
      }
    }
  }
}
</style>
