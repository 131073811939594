<script>
import Element      from "element-ui";

export default {
  name: "ElInputExtendEx",
  extends: Element.Input,
  computed: {
    inputDisabled() {
      return this.disabled;
    },
    validateState() {
      return false;
      // return this.elFormItem ? this.elFormItem.validateState : '';
    },
  },
  props: {
    disabled: Boolean,
  }
};
</script>
