<template>
  <div class="logo" :class="{'blue':blue}"/>
</template>

<script>
export default {
  name: "Logo",
  props: {
    blue: {type: Boolean, default: false,}
  }
};
</script>

<style scoped lang="scss">
.logo {
  //width: 181px;
  width: 117px;
  height: 35px;
  background-image: url("../assets/images/logo.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 5px;
  display: inline-block;

  &.blue {
    background-image: url("../assets/images/blue-logo.png");
  }
}
</style>
