import request from "@/utils/request";

// 提交/更新客商资料
export function createCustomer({customer, ...rest}) {
  return request({
    url: "/api/v1/customer",
    method: "post",
    data: {
      customer: JSON.stringify(customer),
      ...rest,
    },
  });
}

// 提交/更新客商资料
export function updateCustomer({customer, ...rest}) {
  return request({
    url: "/api/v1/customer",
    method: "put",
    data: {
      customer: JSON.stringify(customer),
      ...rest,
    },
  });
}


// 获取城市
export function getCityQueryList(query) {
  return request({
    url: "/api/v1/city-query",
    method: "get",
    once: true,
    params: query,
    // cache: {
    //   type: "local",
    //   ttl: 24 * 3600,
    // },
  });
}

// 获取国家列表
export function getCountryQueryList() {
  return request({
    url: "/api/v1/country-query",
    method: "get",
    cache: {
      type: "local",
      ttl: 24 * 3600,
    },
  });
}
