<template>
  <div
      class="el-select"
      :class="[selectSize ? 'el-select--' + selectSize : '']"
      @click.stop="toggleMenu"
      v-clickoutside="handleClose"
  >
    <div
      class="el-select__tags"
      v-if="multiple"
      ref="tags"
      :style="{ 'max-width': inputWidth - 32 + 'px', width: '100%' }">
      <span v-if="collapseTags && selected.length">
        <el-tag
          :closable="!selectDisabled"
          :size="collapseTagSize"
          :hit="selected[0].hitState"
          type="info"
          @close="deleteTag($event, selected[0])"
          disable-transitions>
          <span class="el-select__tags-text">{{ selected[0].currentLabel }}</span>
        </el-tag>
        <el-tag
          v-if="selected.length > 1"
          :closable="false"
          :size="collapseTagSize"
          type="info"
          disable-transitions>
          <span class="el-select__tags-text">+ {{ selected.length - 1 }}</span>
        </el-tag>
      </span>
      <transition-group @after-leave="resetInputHeight" v-if="!collapseTags">
        <el-tag
          v-for="item in selected"
          :key="getValueKey(item)"
          :closable="!selectDisabled"
          :size="collapseTagSize"
          :hit="item.hitState"
          type="info"
          @close="deleteTag($event, item)"
          disable-transitions>
          <span class="el-select__tags-text">{{ item.currentLabel }}</span>
        </el-tag>
      </transition-group>

      <input
        type="text"
        class="el-select__input"
        :class="[selectSize ? `is-${ selectSize }` : '']"
        :disabled="selectDisabled"
        :autocomplete="autoComplete || autocomplete"
        @focus="handleFocus"
        @blur="softFocus = false"
        @keyup="managePlaceholder"
        @keydown="resetInputState"
        @keydown.down.prevent="handleNavigate('next')"
        @keydown.up.prevent="handleNavigate('prev')"
        @keydown.enter.prevent="selectOption"
        @keydown.esc.stop.prevent="visible = false"
        @keydown.delete="deletePrevTag"
        @keydown.tab="visible = false"
        @compositionstart="handleComposition"
        @compositionupdate="handleComposition"
        @compositionend="handleComposition"
        v-model="query"
        @input="debouncedQueryChange"
        v-if="filterable"
        :style="{ 'flex-grow': '1', width: inputLength / (inputWidth - 32) + '%', 'max-width': inputWidth - 42 + 'px' }"
        ref="input">
    </div>
    <el-input
        ref="reference"
        v-model="selectedLabel"
        :id="id"
        type="text"
        :name="name"
        :placeholder="currentPlaceholder"
        :autocomplete="autoComplete || autocomplete"
        :size="selectSize"
        :disabled="selectDisabled"
        :readonly="readonly"
        :validate-event="false"
        :class="{ 'is-focus': visible }"
        :tabindex="(multiple && filterable) ? '-1' : null"
        @focus="handleFocus"
        @blur="handleBlur"
        @input="onUpdateSelector"
        @keyup.native="debouncedOnInputChange"
        @keydown.native.down.stop.prevent="navigateOptions('next')"
        @keydown.native.up.stop.prevent="navigateOptions('prev')"
        @keydown.native.enter.prevent="selectOption"
        @keydown.native.esc.stop.prevent="visible = false"
        @keydown.native.tab.stop.prevent="visible = false"
        @paste.native="debouncedOnInputChange"
        @mouseenter.native="inputHovering = true"
        @mouseleave.native="inputHovering = false">
      <template slot="prefix" v-if="$slots.prefix">
        <slot name="prefix"/>
      </template>
      <template slot="suffix">
        <i v-show="!showClose"
           :class="iconName ? `el-icon ${iconName}` : ['el-select__caret', 'el-input__icon', 'el-icon-' + iconClass]"
        />
        <i v-if="showClose"
           class="el-select__caret el-input__icon el-icon-circle-close"
           @click="handleClearClick"
        />
      </template>
    </el-input>
    <transition
        name="el-zoom-in-top"
        @before-enter="handleMenuEnter"
        @after-leave="doDestroy">
      <el-select-menu
          ref="popper"
          :append-to-body="popperAppendToBody"
          v-show="visible && emptyText !== false"

      >
        <div
            class="el-select-dropdown__wrap el-scrollbar__wrap el-select-dropdown__wrap-extend"
            style="overflow: hidden;position: relative;">
          <recycle-scroller
              ref="virtualScroller"
              :items="data"
              :buffer="buffer"
              :key-field="keyField"
              :item-size="itemSize"
              class="scroller"
              :style="scrollerStyle"
          >
            <template #before>
              <slot name="before"/>
            </template>
            <template #default="{ item, index, active }">
              <slot :item="item" :index="index" :active="active">

              </slot>
            </template>
            <template #after>
              <slot name="after"/>
            </template>
          </recycle-scroller>
          <template
              v-if="data.length <= 0 && emptyText && (!allowCreate || loading || (allowCreate && data.length === 0 ))">
            <slot name="empty" v-if="$slots.empty"></slot>
            <p class="el-select-dropdown__empty" v-else>
              {{ emptyText }}
            </p>
          </template>
        </div>
      </el-select-menu>
    </transition>
  </div>
</template>

<script>
import {Select}          from "element-ui";
import {RecycleScroller} from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

export default {
  name: "VirtualSelect",
  extends: Select,
  components: {
    RecycleScroller
  },
  props: {
    ...RecycleScroller.props,
    popperClass: String,
    iconName: String,
    itemCount: {type: Number, default: 10},
    disabled: Boolean,
  },
  data() {
    const selectDataFn = Select.data.bind(this);

    const defaultData = selectDataFn();
    return {
      ...defaultData,
      data: [...this.items]
    };
  },
  computed: {
    scrollerStyle() {
      const count = Math.min(this.data.length, this.itemCount);
      const height = this.itemSize * count;
      return `height: ${height}px`;
    },
    selectDisabled() {
      return this.disabled;
    },

    iconClass() {
      return [
        this.remote && this.filterable ? '' : (this.visible ? 'arrow-up is-reverse' : 'arrow-up'),
        ""
      ].join(' ');
    },
  },
  watch: {
    selected(option) {
      if (this.data?.length > 0) {
        const item = this.data.find(x => x.value === option.value);
        if (item) {
          this.selectedLabel = this.$t(item.label);
        }
      }
    },
    items(val) {
      this.data = [...val];
    },
    visible(val) {
      if (val) {
        this.data = [...this.items];
      }
    }
  },
  created() {

  },
  mounted() {

  },

  methods: {
    getLabel(value) {
      if(!value) return'';
      if (this.data?.length > 0) {
        const item = this.data.find(x => x.value === value);
        if (item) {
          return this.$t(item.label);
        }
      }
    },
    getOption(value) {
      let option;
      const isObject = Object.prototype.toString.call(value).toLowerCase() === '[object object]';
      const isNull = Object.prototype.toString.call(value).toLowerCase() === '[object null]';
      const isUndefined = Object.prototype.toString.call(value).toLowerCase() === '[object undefined]';

      for (let i = this.cachedOptions.length - 1; i >= 0; i--) {
        const cachedOption = this.cachedOptions[i];
        const isEqual = isObject
            ? getValueByPath(cachedOption.value, this.valueKey) === getValueByPath(value, this.valueKey)
            : cachedOption.value === value;
        if (isEqual) {
          option = cachedOption;
          break;
        }
      }
      if (option) return option;
      const label = (!isObject && !isNull && !isUndefined)
          ? (this.getLabel(value) || value) : '';
      let newOption = {
        value: value,
        currentLabel: label
      };
      if (this.multiple) {
        newOption.hitState = false;
      }
      return newOption;
    },
    async onUpdateSelector() {
      await this.$nextTick();
      setTimeout(async () => {
        this.$forceUpdate();
        await this.$refs.popper.$nextTick();
        this.$refs.popper.$forceUpdate();
      }, 200);
    },
    handleQueryChange(val) {
      if (this.previousQuery === val || this.isOnComposition) return;
      if (
          this.previousQuery === null
          && (typeof this.filterMethod === "function" || typeof this.remoteMethod === "function")
      ) {
        this.previousQuery = val;
        return;
      }
      this.previousQuery = val;
      this.$nextTick(() => {
        if (this.visible) this.broadcast("ElSelectDropdown", "updatePopper");
      });
      this.hoverIndex = -1;
      if (this.multiple && this.filterable) {
        this.$nextTick(() => {
          const length = this.$refs.input.value.length * 15 + 20;
          this.inputLength = this.collapseTags ? Math.min(50, length) : length;
          this.managePlaceholder();
          this.resetInputHeight();
        });
      }
      if (this.remote && typeof this.remoteMethod === "function") {
        this.hoverIndex = -1;
        this.remoteMethod(val);
      } else if (typeof this.filterMethod === "function") {
        this.filterMethod(val);
        this.broadcast("ElOptionGroup", "queryChange");
      } else {
        if (val) {
          let keys = [];
          if (this.items?.length > 0) keys = Object.keys(this.items[0]);

          this.data = this.items.filter((item) => keys.some(key => item[key]?.includes?.(val) || item[key] === val));
        } else {
          this.data = [...this.items];
        }
        this.filteredOptionsCount = this.data.length;
        this.broadcast("ElOption", "queryChange", val);
        this.broadcast("ElOptionGroup", "queryChange");
      }
      if (this.defaultFirstOption && (this.filterable || this.remote) && this.filteredOptionsCount) {
        this.checkDefaultFirstOption();
      }
    },
    scrollToOption(option) {
      const $option = Array.isArray(option) ? option[0] : option;
      if ($option && $option.$vnode) {
        const {key} = $option.$vnode;
        this.$refs.virtualScroller.scrollToItem(key);
      }
    }

  }

};
</script>
<style lang="scss">
.virtaul-select {
  width: 300px;
}

.scroller {
  width: 100%;
  height: 274px;
}

.el-select-dropdown__wrap-extend {
  .el-select-dropdown__item {
    list-style-type: none;

    &::marker {
      display: none;
    }
  }

  .scroller {
    &::-webkit-scrollbar {
      width: 8px;
      //background: #fff;

    }

    &::-webkit-scrollbar-thumb {
      border-radius: 99px;
      background: #ddd;
    }
  }
}
</style>
