<template>
  <header :class="[{ fixed: fixed }, ...pageHeaderClass]" id="header-box" :style="StylesComputed">
    <div class="header-space" ref="HeaderSpaceElm"></div>
    <div class="header" ref="HeaderElm">
      <div :class="['flex', 'center', 'between', 'container', pageType === 'workSpace' && 'workContainer']">
        <app-link name="Home">
          <logo class="ml-20 shrink-0" :blue="['workSpace','whiteMiddle'].includes(pageType) || isScroll"/>
        </app-link>
        <!-- todo:暂定在xs宽度下隐藏，待解决 -->
        <ul ref="MENU_UI" class="menu-ul flex center fc-fff shrink-0 hidden-xs-only">
          <!-- 首页 -->
          <li
              class="menu-li"
              @mouseover="hoverState.home = true"
              @mouseleave="hoverState.home = false"
              :class="{ on: hoverState.home || (NOT_ALLOW_HOVER && $route.name === 'Home') }"
          >
            <app-link fill name="Home">{{ $t("TestKey.Home") }}</app-link>
          </li>
          <!-- 产品服务 -->
          <li
              class="menu-li"
              :class="{ on: hoverState.productService || (NOT_ALLOW_HOVER && PRODUCT_SERVICE_LI_HOVER) }"
          >
            <el-popover
                v-model="hoverState.productService"
                transition="app-header-transition"
                popper-class="app-header_popper-style product min-190"
                placement="bottom"
                trigger="hover"
            >
              <div slot="reference" class="app-link">
                <app-link>{{ $t("TestKey.productService") }}</app-link>
              </div>
              <!-- 产品服务菜单 -->
              <ul class="display-menu-list">
                <li v-for="item in PRODUCT_SERVICE_LINK_LIST">
                  <app-link
                      class="product"
                      name="FreightQuery"
                      :params="{ type: item.type }"
                      fill
                      @click.native="hoverState.productService = false"
                      :class="{
                      active: $route.name === 'FreightQuery' && $route.params && $route.params.type === item.type
                    }"
                  >
                    {{ item.label }}
                  </app-link>
                </li>
              </ul>
            </el-popover>
          </li>
          <!-- 集团业务 -->
          <li class="menu-li" :class="{ on: hoverState.business || (NOT_ALLOW_HOVER && BUSINESS_LI_HOVER) }">
            <el-popover
                v-model="hoverState.business"
                transition="app-header-transition"
                popper-class="app-header_popper-style min-210 business"
                placement="bottom"
                trigger="hover"
            >
              <div slot="reference" class="app-link">
                <!--                <app-link>集团业务</app-link>-->
                <app-link v-if="$i18n.locale == 'ru'" style="margin-left: -17px"
                >{{ $t("TestKey.logisticsService") }}
                </app-link>
                <app-link v-else>{{ $t("TestKey.logisticsService") }}</app-link>
              </div>
              <!-- 集团业务菜单 -->
              <div class="display-menu-list flex">
                <div class="flex column">
                  <!--                  <p>物流运输服务</p>-->
                  <el-row>
                    <app-link
                        name="BusinessTrack"
                        class="product"
                        :params="{ type: '58' }"
                        :class="{
                        active: $route.name === 'BusinessTrack' && $route.params && $route.params.type === '58'
                      }"
                    >
                      {{ $t("TestKey.InternationalMultiTypeTransportation") }}
                    </app-link>
                    <app-link
                        name="BusinessTrack"
                        class="product"
                        :params="{ type: '59' }"
                        :class="{
                        active: $route.name === 'BusinessTrack' && $route.params && $route.params.type === '59'
                      }"
                    >
                      {{ $t("TestKey.InternationalContainerTrains") }}
                    </app-link>
                    <!--                    <el-col tag="a">工程项目物流</el-col>-->
                    <!--                    <el-col tag="a">国际公路运输</el-col>-->
                    <app-link
                        name="BusinessTrack"
                        class="product"
                        :params="{ type: '63' }"
                        :class="{
                        active: $route.name === 'BusinessTrack' && $route.params && $route.params.type === '63'
                      }"
                    >
                      {{ $t("TestKey.Cross-borderE-commerceLogistics") }}
                    </app-link>
                    <app-link
                        name="BusinessTrack"
                        class="product"
                        :params="{ type: '64' }"
                        :class="{
                        active: $route.name === 'BusinessTrack' && $route.params && $route.params.type === '64'
                      }"
                    >
                      {{ $t("TestKey.InternationalAirTransportation") }}
                    </app-link>
                  </el-row>
                </div>
                <div class="flex column" v-if="false">
                  <p>{{ $t("TestKey.WarehousingServices") }}</p>
                  <el-row>
                    <el-col tag="a">{{ $t("TestKey.DomesticBondedStorage") }}</el-col>
                    <el-col tag="a">{{ $t("TestKey.OverseasWarehouseService") }}</el-col>
                    <el-col tag="a">{{ $t("TestKey.Cross-borderE-commerceWarehousing") }}</el-col>
                    <el-col tag="a">{{ $t("TestKey.CustomizedDeliveryService") }}</el-col>
                  </el-row>
                </div>
                <div class="flex column" v-if="false">
                  <p>{{ $t("TestKey.ContainerService") }}</p>
                  <el-row>
                    <el-col tag="a">{{ $t("TestKey.MaintenanceService") }}</el-col>
                    <el-col tag="a">{{ $t("TestKey.ContainerLeasing") }}</el-col>
                    <el-col tag="a">{{ $t("TestKey.SecondHandContainerTrading") }}</el-col>
                    <el-col tag="a">{{ $t("TestKey.ContainerOperation") }}</el-col>
                  </el-row>
                </div>
                <div class="flex column" v-if="false">
                  <p>{{ $t("TestKey.supplyChainManagement") }}</p>
                  <el-row>
                    <el-col tag="a">{{ $t("TestKey.ImportAndExportCustomsDeclaration") }}</el-col>
                    <el-col tag="a">{{ $t("TestKey.OverseasSalesAgent") }}</el-col>
                    <el-col tag="a">{{ $t("TestKey.DomesticAndForeignProcurementAgency") }}</el-col>
                    <el-col tag="a">{{ $t("TestKey.SupplyChainFinance") }}</el-col>
                  </el-row>
                </div>
              </div>
            </el-popover>
          </li>
          <!-- 行业解决方案 -->
          <li
              v-if="false"
              class="menu-li"
              :class="{ on: hoverState.solution || (NOT_ALLOW_HOVER && $route.name === 'Test') }"
          >
            <el-popover
                v-model="hoverState.solution"
                popper-class="app-header_popper-style solution"
                placement="bottom"
                trigger="hover"
            >
              <div slot="reference" class="app-link">
                <app-link>{{ $t("TestKey.IndustrySolutions") }}</app-link>
              </div>
              <!-- 行业解决方案菜单 -->
              <div class="display-menu-list">
                <p>{{ $t("TestKey.IndustrySolutions") }}</p>
                <el-row>
                  <el-col :span="12" tag="a">{{ $t("TestKey.FurnitureTimber") }}</el-col>
                  <el-col :span="12" tag="a">{{ $t("TestKey.ElectronicCommunication") }}</el-col>
                  <el-col :span="12" tag="a">{{ $t("TestKey.ClothingAndDailySse") }}</el-col>
                  <el-col :span="12" tag="a">{{ $t("TestKey.Chemicals") }}</el-col>
                  <el-col :span="12" tag="a">{{ $t("TestKey.AutomobileManufacturing") }}</el-col>
                  <el-col :span="12" tag="a">{{ $t("TestKey.PetroleumEnergy") }}</el-col>
                  <el-col :span="12" tag="a">{{ $t("TestKey.EngineeringEquipment") }}</el-col>
                  <el-col :span="12" tag="a">{{ $t("TestKey.ArchitecturalDecoration") }}</el-col>
                  <el-col :span="12" tag="a">{{ $t("TestKey.MedicalHealth") }}</el-col>
                </el-row>
              </div>
            </el-popover>
          </li>
          <!-- 工作台 -->
          <li
              class="menu-li"
              ref="workSpace"
              @mouseover="hoverState.workSpace = true"
              @mouseleave="hoverState.workSpace = false"
              :class="{ on: hoverState.workSpace || (NOT_ALLOW_HOVER && WORK_SPACE_LI_HOVER) }"
          >
            <app-link :class="{ 'bottom-line': pageType === 'workSpace' }" name="WorkPlace" fill>{{
                $t("TestKey.workbench")
              }}
            </app-link>
          </li>
          <div id="workSpace" class="workSpace-mask"></div>
          <!-- <li class="menu-li" style="max-width:140px;" @mouseover="hoverState.controlOrder=true"
              @mouseleave="hoverState.controlOrder=false"
              :class="{'on':  hoverState.controlOrder}">
            <a href="/local/file/oms在线订舱用户使用手册-latest.pdf" target="_blank"
               class="h-p-100 flex center"
               v-if="$i18n.locale == 'zh-CN' || $i18n.locale == 'zh-TW'">{{ $t("TestKey.OperatingInstructions") }}</a>
            <a href="/local/file/oms在线订舱用户使用手册 英文1.1.pdf" target="_blank"
               class="h-p-100 flex center" v-if="$i18n.locale == 'en'">{{ $t("TestKey.OperatingInstructions") }}</a>
            <a href="/local/file/oms在线订舱用户使用手册 俄文1.2.pdf" target="_blank"
               class="h-p-100 flex center" v-if="$i18n.locale == 'ru'">{{ $t("TestKey.OperatingInstructions") }}</a>
          </li>  -->
        </ul>
        <div class="login-container flex center" :class="!isLogin ? 'mr-10 fc-fff shrink-0 nowrap' : ''">
          <div class="row-ul">
            <img :src="ICON_SOURCE" alt="language" class="icon"/>
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                {{ $t("TestKey.switchLanguage") }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="zh-CN">中文</el-dropdown-item>
                <el-dropdown-item command="en">English</el-dropdown-item>
                <el-dropdown-item command="zh-TW">繁體</el-dropdown-item>
                <el-dropdown-item command="ru">Русский</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="flex center middle" ref="headerUser">
            <div class="pointer" v-if="!isLogin" @click="onClickToAccountContainer('loginByPhone')">
              {{ $t("TestKey.Login") }}/{{ $t("TestKey.register") }}
            </div>
            <el-popover
                ref="USER_POPOVER"
                v-else
                trigger="manual"
                v-model="userPopoverVisible"
                placement="bottom-end"
                popper-class="app-header_popper-style avatar USER_POPOVER"
            >
              <ul class="control-list" id="controlList">
                <div
                    id="maskControlList"
                    ref="maskControlList"
                    style="width: 142px; height: 80px; opacity: 1; position: absolute; left: 10px; display: none"
                ></div>
                <li>
                  <app-link name="UserInformation" class="user-set oms-hover"
                  >{{ $t_reserve("账户信息") }}
                  </app-link>
                </li>
                <li>
                  <a @click="onLogout" class="log-out oms-hover">{{ $t("TestKey.LogOut") }}</a>
                </li>
              </ul>
              <div slot="reference" class="flex center user-info">
                <el-avatar
                    v-loading="logoutLoading"
                    element-loading-spinner="el-icon-loading"
                    :size="35"
                    :src="user.Avatar"
                />
                <p class="lh-22" style="margin-left: 10px">
                  <span v-if="+user.CustomerState !== 2" class="fc-fff">
                    {{ NICK_NAME ? NICK_NAME + "," : $t("TestKey.account") }}
                    <app-link v-if="+user.CustomerState === 1" name="UserInformation">{{
                        $t("TestKey.UnderCertification")
                      }}</app-link>
                    <app-link v-else name="UserInformation">{{ $t("TestKey.NotCertified") }}</app-link>
                  </span>
                  <span class="fc-fff max-200 name-text" v-else>{{
                      NICK_NAME || (user.CustomerState === 2 ? $t("TestKey.Certified") : $t("TestKey.NotCertified"))
                    }}</span>
                  <!--            <a class="fc-82a5e0">去认证</a>-->
                </p>
              </div>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
    <!-- style="max-width:140px;white-space:pre-wrap" -->
    <!-- 登录/注册窗口 -->
    <!-- <oms-dialog
      :clear-effect="accountContainerType === 'loginByPhone'"
      :width="590"
      :visible.sync="accountVisible"
      :close-on-click-modal="!loginDialogLock && accountContainerType === 'loginByPhone'"
      :close-on-press-escape="!loginDialogLock"
      @closed="$emit('login-close')"
      @close="$emit('login-before-close')"
      :title="ACCOUNT_TITLE"
    >
      <div class="account-by-phone-container" v-if="accountContainerType === 'loginByPhone'">
        <el-tabs v-model="loginTab" class="tab-style-none tab-center oms-extend__clear-tabs-effect">
          <el-tab-pane :stretch="true" name="phone" :label="$t('TestKey.LoginWithMobileNumber')">
            <oms-row
              ref="LOGIN_ROW"
              form
              :rules="INFO_RULES.MOBILE_LOGIN"
              class="mt-30"
              :model="HeaderInfo.MobileLogin"
              form-item-margin="0 0 20px 0"
            >
              <oms-form-item :prop="INFO_RULES.MOBILE_RULES_PROP.areaAndPhone">
                <div class="flex combine-select-input">
                  <select-area-number v-model="HeaderInfo.MobileLogin.area" class="fb-120px" />
                  <el-input
                    v-model="HeaderInfo.MobileLogin.phone"
                    class="fill"
                    :placeholder="$t('TestKey.PleaseEnterYourMobileNumber')"
                  />
                </div>
              </oms-form-item>
              <oms-form-item :prop="INFO_RULES.MOBILE_RULES_PROP.verifyImgCode">
                <el-input
                  class="w-p-100"
                  v-model.trim="HeaderInfo.MobileLogin.verifyImgCode"
                  :placeholder="$t('TestKey.PleaseEnterTheGraphicVerificationCode')"
                  @keypress.enter="() => $refs.VERIFY_SMS_CODE.onGetSmsCode()"
                >
                  <a slot="suffix" class="verify-button flex center middle">
                    <img
                      :src="OMS_API.verifyImgCode + '?v=' + UUID"
                      @click="onRefreshImgCode()"
                      alt=""
                      style="width: 83px; height: 32px"
                    />
                  </a>
                </el-input>
              </oms-form-item>
              <oms-form-item :prop="INFO_RULES.MOBILE_RULES_PROP.verifySmsCode">
                <el-input
                  class="w-p-100"
                  v-model.trim="HeaderInfo.MobileLogin.verifySmsCode"
                  :placeholder="$t('TestKey.PleaseEnterSMSVerificationCode')"
                  @keypress.enter.native="onLoginByPhone"
                >
                  <verify-sms-code :verify="'T'" ref="VERIFY_SMS_CODE" slot="suffix" :send="onGetSmsCode" />
                </el-input>
              </oms-form-item>
              <el-button
                class="button-fill mt-20 mb-10 oms-button-style"
                :loading="loading"
                :disabled="loading"
                type="primary"
                @click="onLoginByPhone"
              >
                {{ $t('TestKey.Login') }}/{{ $t('TestKey.register') }}
              </el-button>
              <p class="block fs-14 lh-24 mb-20">
                {{ $t('TestKey.click') }}{{ $t('TestKey.Login') }}/{{ $t('TestKey.register') }}”，{{
                  $t('TestKey.ThatMeansConsent')
                }}<a class="link" @click="serviceContentVisible = true">《{{ $t('TestKey.UserServiceAgreement') }}》</a>
              </p>
              <hr class="mtb-10" />
              <a class="link lh-24 fs-14" @click="accountContainerType = 'loginByAccount'">{{
                $t('TestKey.AccountPasswordLogin')
              }}</a>
            </oms-row>
          </el-tab-pane>
          <el-tab-pane name="wechat" :label="$t('TestKey.WeChatLogin')">
            <div class="flex center column middle mt-20" style="padding-top: 2px">
              <div class="w-p-100">
                <wwlogin
                  @init="wechatConfig = $event"
                  ref="WW_LOGIN"
                  v-if="loginTab === 'wechat'"
                  @login="onSetToken"
                  @scanned="onLoginByWechat"
                />
              </div>
              <span class="mt-20 lh-24 fs-14 mb-20 fc-777">{{
                $t('TestKey.PleaseUseWeChatScanningQRCodeToBind')
              }}</span>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

      <oms-row
        ref="ACCOUNT_ROW"
        class="pt-10"
        :model="HeaderInfo.MobileLogin"
        :rules="INFO_RULES.ACCOUNT_LOGIN"
        form
        v-else-if="accountContainerType === 'loginByAccount'"
        form-item-margin="0 0 20px 0"
      >
        <oms-form-item>
          <el-input
            v-model="HeaderInfo.MobileLogin.phone"
            class="w-p-100"
            :placeholder="$t('TestKey.PleaseEnterTheAccountNumber')"
          />
        </oms-form-item>
        <oms-form-item>
          <el-input
            v-model="HeaderInfo.MobileLogin.verifySmsCode"
            class="w-p-100"
            :placeholder="$t('TestKey.PleaseInputAPassword')"
            type="password"
          />
        </oms-form-item>
        <el-button
          :loading="loading"
          class="button-fill oms-button-style mt-40 mb-50"
          type="primary"
          @click="onLoginByAccount"
          >{{ $t('TestKey.Login') }}
        </el-button>
        <hr />
        <a class="link lh-24 fs-14" @click="accountContainerType = 'loginByPhone'"
          >{{ $t('TestKey.UseVerificationCode') }}/{{ $t('TestKey.WeChatLogin') }}</a
        >
      </oms-row>
    </oms-dialog>
    <oms-dialog :visible.sync="serviceContentVisible" :title="$t('TestKeyText.UserAgreementAndPrivacyStatement')">
      <div class="fs-18 fc-333">
        <span class="u-text text-1">{{ $t('TestKeyText.agreeContract') }}。</span>
        <span class="u-text text-1">{{ $t('TestKeyText.agreeContract1') }}</span>
        <span class="u-text text-1">{{ $t('TestKeyText.agreeContract2') }}</span>
        <span class="u-text text-2">{{ $t('TestKeyText.agreeContract3') }}</span>
        <span class="u-text text-2">{{ $t('TestKeyText.agreeContract4') }}</span>
        <span class="u-text text-1">3、{{ $t('TestKey.account') }}</span>
        <span class="u-text text-2">3.1{{ $t('TestKeyText.agreeContract5') }}。</span>
        <span class="u-text text-2">3.2{{ $t('TestKeyText.agreeContract6') }}。</span>
        <span class="u-text text-2">3.3{{ $t('TestKeyText.agreeContract7') }}。</span>
        <span class="u-text text-2">3.4{{ $t('TestKeyText.agreeContract8') }}。</span>
        <span class="u-text text-2">3.5{{ $t('TestKeyText.agreeContract9') }}。</span>
        <span class="u-text text-1">4、{{ $t('TestKey.ReasonableUseOfUserInformation') }}</span>
        <span class="u-text text-2">4.1{{ $t('TestKeyText.agreeContract10') }}。</span>
        <span class="u-text text-2">4.2{{ $t('TestKeyText.agreeContract11') }}。</span>
        <span class="u-text text-2">4.3{{ $t('TestKeyText.agreeContract12') }}。</span>
        <span class="u-text text-1">5、{{ $t('TestKey.Disclaimer') }}</span>
        <span class="u-text text-2">5.1 {{ $t('TestKeyText.agreeContract13') }}。</span>
      </div>
    </oms-dialog>
    <oms-dialog
      destroy-on-close
      :width="507"
      :visible.sync="bindPhoneVisible"
      :title="$t('TestKey.BindMobileNumber')"
      @close="onRefreshWechatQrCode"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <oms-row ref="BIND_FORM" form :model="bindUser" :rules="INFO_RULES.BIND_PHONE" form-item-margin="0">
        <oms-form-item :prop="INFO_RULES.BIND_PHONE_RULES_PROP.areaAndPhone">
          <div class="flex combine-select-input">
            <select-area-number v-model="bindUser.area" class="fb-120px" />
            <el-input v-model="bindUser.phone" class="fill" :placeholder="$t('TestKey.PleaseEnterYourMobileNumber')" />
          </div>
        </oms-form-item>
        <oms-form-item :prop="INFO_RULES.BIND_PHONE_RULES_PROP.verifySmsCode">
          <el-input
            v-model="bindUser.verifySmsCode"
            class="flex center mt-20"
            :placeholder="$t('TestKey.PleaseEnterTheVerificationCode')"
          >
            <verify-sms-code
              slot="suffix"
              class="verify-button link"
              :count-key="`Bind_Phone_VerifyCode`"
              :send="onGetSmsCode.bind(this, 1)"
            />
          </el-input>
        </oms-form-item>
        <div class="flex mt-40">
          <el-button :loading="bindLoading" type="primary" class="button-fill" @click="onBindPhone(1)">
            <span class="fs-18">{{ $t('TestKey.binding') }}</span>
          </el-button>
        </div>
      </oms-row>
    </oms-dialog> -->

    <!-- 登录/注册窗口 -->
    <oms-dialog
        :clear-effect="accountContainerType === 'loginByPhone'"
        :width="910"
        :visible.sync="accountVisible"
        :close-on-click-modal="true"
        :title="ACCOUNT_TITLE"
    >
      <div class="account-by-phone-container" v-if="accountContainerType === 'loginByPhone'">
        <div class="img-wrap">
          <img class="logo" src="@/assets/images/blue-logo.png" alt=""/>
          <img class="login-img" src="@/assets/images/header-login-img@2x.jpg" alt=""/>
        </div>
        <el-tabs v-model="loginTab" class="login-tabs" :class="[$i18n.locale == 'ru' ? 'tab-style-none-ru' : '']">
          <el-tab-pane name="account" :label="$t_reserve('账户登录')">
            <oms-row
                ref="ACCOUNT_ROW"
                class="mt-20"
                :model="HeaderInfo.MobileLogin"
                :rules="INFO_RULES.ACCOUNT_LOGIN"
                form
                form-item-margin="0 0 20px 0"
            >
              <oms-form-item :prop="INFO_RULES.ACCOUNT_PROP.phone">
                <el-input
                    v-model="HeaderInfo.MobileLogin.phone"
                    class="w-p-100 login-input"
                    :placeholder="$t('TestKey.PleaseEnterTheAccountNumber')"
                />
              </oms-form-item>
              <oms-form-item :prop="INFO_RULES.ACCOUNT_PROP.verifySmsCode">
                <el-input
                    v-model="HeaderInfo.MobileLogin.verifySmsCode"
                    class="w-p-100 login-input"
                    :placeholder="$t('TestKey.PleaseInputAPassword')"
                    type="password"
                    @keypress.enter.native="onLoginByAccount"
                />
              </oms-form-item>
              <el-button
                  :loading="loading"
                  class="login-button button-fill oms-button-style mt-40 mb-10"
                  type="primary"
                  @click="onLoginByAccount"
              >
                {{ $t("TestKey.Login") }}/{{ $t("TestKey.register") }}
              </el-button>
            </oms-row>
          </el-tab-pane>
          <el-tab-pane name="qrcode" :label="$t_reserve('微信登录')">
            <div class="mt-20">
              <div class="w-p-100">
                <wwlogin @init="wechatConfig = $event" ref="WW_LOGIN" @login="onSetToken" @scanned="onLoginByWechat"/>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane name="phone" :label="$t_reserve('验证码登录')">
            <oms-row
                ref="LOGIN_ROW"
                form
                :rules="INFO_RULES.MOBILE_LOGIN"
                class="mt-20"
                :model="HeaderInfo.MobileLogin"
                form-item-margin="0 0 20px 0"
            >
              <oms-form-item :prop="INFO_RULES.MOBILE_RULES_PROP.areaAndPhone">
                <div class="flex combine-select-input">
                  <select-area-number v-model="HeaderInfo.MobileLogin.area" v-flex-property.basis="'120px'"/>
                  <el-input
                      v-model="HeaderInfo.MobileLogin.phone"
                      class="fill login-input"
                      :placeholder="$t('TestKey.PleaseEnterYourMobileNumber')"
                  />
                </div>
              </oms-form-item>
              <oms-form-item :prop="INFO_RULES.MOBILE_RULES_PROP.verifyImgCode">
                <el-input
                    class="w-p-100 login-input"
                    v-model="HeaderInfo.MobileLogin.verifyImgCode"
                    :placeholder="$t('TestKey.PleaseEnterTheGraphicVerificationCode')"
                    @keypress.enter.native="$refs.VERIFY_SMS_CODE.onGetSmsCode()"
                >
                  <a slot="suffix" class="verify-button flex center middle">
                    <img
                        :src="OMS_API.verifyImgCode + '?v=' + UUID"
                        @click="onRefreshImgCode()"
                        alt=""
                        style="width: 83px; height: 32px"
                    />
                  </a>
                </el-input>
              </oms-form-item>
              <oms-form-item :prop="INFO_RULES.MOBILE_RULES_PROP.verifySmsCode">
                <el-input
                    class="w-p-100 login-input"
                    v-model="HeaderInfo.MobileLogin.verifySmsCode"
                    :placeholder="$t('TestKey.PleaseEnterSMSVerificationCode')"
                    @keypress.enter.native="onLoginByPhone"
                >
                  <verify-sms-code ref="VERIFY_SMS_CODE" slot="suffix" :send="onGetSmsCode"/>
                </el-input>
              </oms-form-item>
              <div class="flex column">
                <span class="login-tips mb-0" v-if="isSendMsg">{{
                  $t_reserve("若没有收到短信，记得检查短信垃圾箱哟~")
                }}</span>
                <span class="login-tips" :class="[$i18n.locale]">{{
                  $t_reserve("验证即登录，未注册将自动创建OMS账号")
                }}</span>
              </div>
              <el-button
                  class="login-button button-fill mt-20 mb-10 oms-button-style"
                  :loading="loading"
                  :disabled="loading"
                  type="primary"
                  @click="onLoginByPhone"
              >
                {{ $t("TestKey.Login") }}/{{ $t("TestKey.register") }}
              </el-button>
            </oms-row>
          </el-tab-pane>
          <div :class="['flex', 'center']" v-if="loginTab !== 'qrcode'" style="cursor: pointer">
            <p class="block lh-22" :class="[IS_OTHER ? 'fs-12' : 'fs-14']">
              {{ $t("TestKey.click") }}&nbsp;“{{ $t("TestKey.Login") }}/{{ $t("TestKey.register") }}”，{{
                $t("TestKey.ThatMeansConsent")
              }}<a class="link" style="color: #275df1" @click="serviceContentVisible = true"
            >《{{ $t("TestKey.UserServiceAgreement") }}》</a
            >
            </p>
          </div>
        </el-tabs>
      </div>

      <oms-row
          ref="ACCOUNT_ROW"
          class="pt-10"
          :model="HeaderInfo.MobileLogin"
          :rules="INFO_RULES.ACCOUNT_LOGIN"
          form
          v-else-if="accountContainerType === 'loginByAccount'"
          form-item-margin="0 0 20px 0"
      >
        <oms-form-item>
          <el-input
              v-model="HeaderInfo.MobileLogin.phone"
              class="w-p-100"
              :placeholder="$t('TestKey.PleaseEnterTheAccountNumber')"
          />
        </oms-form-item>
        <oms-form-item>
          <el-input
              v-model="HeaderInfo.MobileLogin.verifySmsCode"
              class="w-p-100"
              :placeholder="$t('TestKey.PleaseInputAPassword')"
              type="password"
              @keypress.enter.native="onLoginByAccount"
          />
        </oms-form-item>
        <el-button
            :loading="loading"
            class="button-fill oms-button-style mt-40 mb-50"
            type="primary"
            @click="onLoginByAccount"
        >{{ $t("TestKey.Login") }}
        </el-button>
        <hr/>
        <a class="link lh-24 fs-14" @click="accountContainerType = 'loginByPhone'"
        >{{ $t("TestKey.UseVerificationCode") }}/{{ $t("TestKey.WeChatLogin") }}</a
        >
      </oms-row>
    </oms-dialog>
    <oms-dialog :visible.sync="serviceContentVisible" :title="$t('TestKeyText.UserAgreementAndPrivacyStatement')">
      <div class="fs-18 fc-333">
        <span class="u-text text-1">{{ $t("TestKeyText.agreeContract") }}。</span>
        <span class="u-text text-1">{{ $t("TestKeyText.agreeContract1") }}</span>
        <span class="u-text text-1">{{ $t("TestKeyText.agreeContract2") }}</span>
        <span class="u-text text-2">{{ $t("TestKeyText.agreeContract3") }}</span>
        <span class="u-text text-2">{{ $t("TestKeyText.agreeContract4") }}</span>
        <span class="u-text text-1">3、{{ $t("TestKey.account") }}</span>
        <span class="u-text text-2">3.1{{ $t("TestKeyText.agreeContract5") }}。</span>
        <span class="u-text text-2">3.2{{ $t("TestKeyText.agreeContract6") }}。</span>
        <span class="u-text text-2">3.3{{ $t("TestKeyText.agreeContract7") }}。</span>
        <span class="u-text text-2">3.4{{ $t("TestKeyText.agreeContract8") }}。</span>
        <span class="u-text text-2">3.5{{ $t("TestKeyText.agreeContract9") }}。</span>
        <span class="u-text text-1">4、{{ $t("TestKey.ReasonableUseOfUserInformation") }}</span>
        <span class="u-text text-2">4.1{{ $t("TestKeyText.agreeContract10") }}。</span>
        <span class="u-text text-2">4.2{{ $t("TestKeyText.agreeContract11") }}。</span>
        <span class="u-text text-2">4.3{{ $t("TestKeyText.agreeContract12") }}。</span>
        <span class="u-text text-1">5、{{ $t("TestKey.Disclaimer") }}</span>
        <span class="u-text text-2">5.1 {{ $t("TestKeyText.agreeContract13") }}。</span>
      </div>
    </oms-dialog>
    <!-- 绑定手机号 -->
    <oms-dialog
        destroy-on-close
        :width="507"
        :visible.sync="bindPhoneVisible"
        :title="$t('TestKey.BindMobileNumber')"
        @close="onRefreshWechatQrCode"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
      <oms-row ref="BIND_FORM" form :model="bindUser" :rules="INFO_RULES.BIND_PHONE" form-item-margin="0">
        <oms-form-item :prop="INFO_RULES.BIND_PHONE_RULES_PROP.areaAndPhone">
          <div class="flex combine-select-input">
            <select-area-number v-model="bindUser.area" v-flex-property.basis="'120px'"/>
            <el-input v-model="bindUser.phone" class="fill" :placeholder="$t('TestKey.PleaseEnterYourMobileNumber')"/>
          </div>
        </oms-form-item>
        <oms-form-item :prop="INFO_RULES.BIND_PHONE_RULES_PROP.verifySmsCode">
          <el-input
              v-model="bindUser.verifySmsCode"
              class="flex center mt-20"
              :placeholder="$t('TestKey.PleaseEnterTheVerificationCode')"
          >
            <verify-sms-code
                slot="suffix"
                class="verify-button link"
                :count-key="`Bind_Phone_VerifyCode`"
                :send="onGetSmsCode.bind(this, 1)"
            />
          </el-input>
        </oms-form-item>
        <div class="flex mt-40">
          <el-button :loading="bindLoading" type="primary" class="button-fill" @click="onBindPhone(1)">
            <span class="fs-18">{{ $t("TestKey.binding") }}</span>
          </el-button>
        </div>
      </oms-row>
    </oms-dialog>
  </header>
</template>

<script>
import Logo                   from "@/components/Logo";
import {rules}                from "@/mixins";
import {mapGetters, mapState} from "vuex";
import VerifySmsCode          from "@/components/VerifySmsCode";
import notify                 from "@/mixins/notify";
import Wwlogin                from "@/components/omsExtend/wwlogin";
import {tryLoginByWechat}     from "@/api/user";
import SelectAreaNumber       from "@/components/omsExtend/SelectAreaNumber";
import elmControlMixins       from "@/mixins/elmControlMixins";
import app from '@/app'

export default {
  name: "AppHeader",
  components: {SelectAreaNumber, Wwlogin, VerifySmsCode, Logo},
  // watch: {
  //   bindPhoneVisible(val) {
  //     setTimeout(() => {
  //       alert("刷新");
  //       this.onRefreshWechatQrCode();
  //     }, 3000);
  //   },
  //   loginTab(val) {
  //     if (val === "wechat") {
  //       setTimeout(() => {
  //         alert('模拟扫描成功');
  //         this.onLoginByWechat("测试");
  //       },2000);
  //     }
  //   },
  // },
  props: {
    fixed: Boolean,
    loginDialogLock: Boolean,
    pageType: String
  },
  created() {
    const langType = window.localStorage.getItem("lang");
    // 监听登录弹窗
    app.$on('update-accountVisible',(data)=>{
      this.accountVisible = data 
    })
    if (langType) {
      this.handleCommand(langType);
    }
  },

  mixins: [rules, notify, elmControlMixins],
  computed: {
    ...mapGetters(["isLogin", "user"]),
    StylesComputed() {
      return {
        ["--headerHeight"]: String.raw`${this.headerModel.height}px`,
        ["--headerWidth"]: String.raw`${this.headerModel.width}px`,
      };
    },
    NICK_NAME() {
      return this.user.RealName; //|| this.user.NickName || this.user.Customer?.CusFullNameChs || this.user.WechatUser?.Nickname ||  this.user.Account;
    },
    // 转化成显示用的数字
    DISPLAY_SMS_COOL_DOWN() {
      if (this.smsCooldown <= 0) {
        return 0;
      }

      const time = parseInt((this.smsCooldown - this.currentDate) / 1000);

      if (time <= 0) {
        return 0;
      }

      return time;
    },
    ACCOUNT_TITLE() {
      if (this.accountContainerType === "loginByPhone") {
        return null;
      } else if (this.accountContainerType === "loginByAccount") {
        return this.$t("TestKey.AccountPasswordLogin");
      }

      return "";
    },
    PRODUCT_SERVICE_LI_HOVER() {
      return ["FreightQuery", "FreightOrder"].includes(this.$route.name);
    },
    BUSINESS_LI_HOVER() {
      return ["BusinessTrack"].includes(this.$route.name);
    },
    WORK_SPACE_LI_HOVER() {
      return ["UserInformation", "UserOrder"].includes(this.$route.name);
    },
    PRODUCT_SERVICE_LINK_LIST() {
      return [
        {
          label: this.$t("TestKey.railway"),
          type: "railway"
        },
        {
          label: this.$t("TestKey.automobileTransport"),
          type: "truck"
        },
        {
          label: this.$t("TestKey.seaTransportation"),
          type: "sea"
        },
        {
          label: this.$t("TestKey.airTransport"),
          type: "air"
        },
        {
          label: this.$t("TestKey.MultiTypeTransportation"),
          type: "multiTrain"
        }
      ];
    },
    NOT_ALLOW_HOVER() {
      return !this.hoverState.keyEntries().some((key) => !!this.hoverState[key]);
    },
    ICON_SOURCE() {
      return ["workSpace"].includes(this.pageType) || this.isScroll
          ? require("@/assets/images/lang-switch-black@2x.png")
          : require("@/assets/images/lang-switch-white@2x.png");
    },
    IS_OTHER() {
      return ["en", "ru"].includes(this.$i18n.locale);
    }
  },
  data() {
    return {
      ScrollScaleIndex: 0,
      ScrollTop: 0,
      headerModel: {
        width: 0,
        height: 0,
      },
      language: "",
      internationalizationBox: false,
      bindPhoneVisible: false,
      bindPhoneType: "",
      userPopoverVisible: false,
      wechatConfig: {},
      loading: false,
      bindLoading: false,
      logoutLoading: false,
      currentDate: null,
      smsCooldown: +sessionStorage.getItem("header_sms_cool_down") || 0,
      UUID: this.$guid().replace("-", ""),
      serviceContentVisible: false,
      accountVisible: false,
      accountContainerType: "loginByPhone",
      loginTab: "account",
      hoverState: {
        home: false,
        workSpace: false,
        controlOrder: false,
        productService: false,
        business: false,
        solution: false
      },
      classListMap: {
        home: "homePage",
        query: "queryPage",
        business: "businessPage",
        workSpace: "workSpacePage",
        bluePage: "bluePage",
        whiteMiddle: "whiteMiddle",
      },
      pageHeaderClass: [],
      headerEl: null,
      isScroll: false,
      isAgree: true,
      isSendMsg:false,
    };
  },
  mounted() {
    this.CreateElementResizeObserver(this.$refs.HeaderElm, this.onHeaderResizeChange);
    this.onHeaderResizeChange();
    this.pageHeaderClass.push(this.classListMap[this.pageType]);
    if (this.pageType === "home") {
      window.addEventListener("scroll", this.onScrollPage);
      this.headerEl = document.getElementById("header-box");
    } else if (this.pageType === "whiteMiddle") {
      window.addEventListener("scroll", this.onScrollPage);
    }
  },
  beforeDestroy() {
    document.documentElement.removeEventListener("mousemove", this.over);
    document.documentElement.removeEventListener("scroll", this.onScrollPage);
  },
  watch: {
    isLogin: {
      handler: function () {
        this.$nextTick(() => {
          // 监听鼠标进入事件，手动显示浮层
          this.$refs.headerUser.addEventListener("mouseenter", () => {
            this.showUserPopover();
          });

          if (document.getElementsByClassName("USER_POPOVER")[0]) {
            document.getElementsByClassName("USER_POPOVER")[0].addEventListener("mouseenter", () => {
              this.showUserPopover();
            });
          }

          // 监听鼠标离开事件，手动隐藏浮层
          this.$refs.headerUser.addEventListener("mouseleave", () => {
            this.closeUserPopover();
          });
          if (document.getElementsByClassName("USER_POPOVER")[0]) {
            document.getElementsByClassName("USER_POPOVER")[0].addEventListener("mouseleave", () => {
              this.closeUserPopover();
            });
          }
        });
      },
      immediate: true
    }
  },
  methods: {
    // 当header发生变化时
    onHeaderResizeChange() {
      const rect = this.$refs.HeaderElm.getBoundingClientRect();

      document.body.style.setProperty("--statusbarHeight", rect.height + "px");
      this.headerModel.width = rect.width;
      this.headerModel.height = rect.height;
    },
    showUserPopover() {
      this.userPopoverVisible = true;
    },
    closeUserPopover() {
      this.userPopoverVisible = false;
    },
    onScrollPage() {
      const scrollTop = window.scrollY || window.pageYOffset;
      this.ScrollTop = scrollTop;
      if (this.pageType === "whiteMiddle") {
        this.ScrollScaleIndex = this.ScrollScaleIndex || 0;
        this.isScroll = scrollTop > 100;
        if ((scrollTop - (this.__scrollPrev || 0)) >= 0) {
          if (this.ScrollScaleIndex >= 0) {
            this.ScrollScaleIndex++;
          } else {
            this.ScrollScaleIndex = 0;
          }
        } else {
          if (this.ScrollScaleIndex <= 0) {
            this.ScrollScaleIndex--;
          } else {
            this.ScrollScaleIndex = 0;
          }
        }
        this.__scrollPrev = scrollTop;
        return;
      }
      if (scrollTop > 100) {
        this.headerEl.classList.add("fixedClass");
        this.isScroll = true;
      } else {
        this.headerEl.classList.remove("fixedClass");
        this.isScroll = false;
      }
    },
    handleCommand(e) {
      window.localStorage.setItem("lang", e);

      if (e === "zh-CN") {
        this.langToggleChinese();
      } else if (e === "en") {
        this.langToggleEnglish();
      } else if (e === "zh-TW") {
        this.langToggleChineseTW();
      } else if (e === "ru") {
        this.langToggleChineseRussian();
      }
    },
    langToggleEnglish() {
      // console.log(this.$store.dispatch);
      this.$store.dispatch("lang/ASYNC_LOAD_LANG_JSON", "en");
    },
    langToggleChinese() {
      this.$store.dispatch("lang/ASYNC_LOAD_LANG_JSON", "zh-CN");
    },
    langToggleChineseTW() {
      this.$store.dispatch("lang/ASYNC_LOAD_LANG_JSON", "zh-TW");
    },
    langToggleChineseRussian() {
      this.$store.dispatch("lang/ASYNC_LOAD_LANG_JSON", "ru");
    },
    async onRefreshWechatQrCode() {
      this.loginTab = "";
      await this.$forceUpdate();
      this.loginTab = "wechat";
    },
    async onGetSmsCode(type) {
      if (type === 1) {
        // 绑定手机号
        let valid = await this.$refs.BIND_FORM.validateFields(this.INFO_RULES.BIND_PHONE_RULES_PROP.areaAndPhone);

        if (valid[0]) {
          const ok = await this.OMS_API.SendBindPhoneVerifyCode(this.bindUser.area, this.bindUser.phone);
          if (process.env.NODE_ENV === "development") {
            this.notifySuccess(`${this.$t("TestKey.Code")}：${ok}`);
            return true;
          }
          if (ok === true) {
            this.notifySuccess(this.$t("TestKey.SendingSucceeded"));
          } else {
            this.notifyError(ok?.message || this.$t("TestKey.FailedToSendPleaseTryAgainLater"));
            return false;
          }
        }
      } else {
        this.$refs.LOGIN_ROW.clearValidate();

        const loginInfo = this.HeaderInfo.MobileLogin;
        const results = await this.$refs.LOGIN_ROW.validateFields(
            this.INFO_RULES.MOBILE_RULES_PROP.areaAndPhone,
            this.INFO_RULES.MOBILE_RULES_PROP.verifyImgCode
        );
        if (results[0] && results[1]) {
          const result = await this.OMS_API.sendSmsCode({
            countryCode: loginInfo.area,
            phone: loginInfo.phone.replace(/\s*/g,""),
            code: loginInfo.verifyImgCode,
            isInternational: !!loginInfo.area && +loginInfo.area !== 86 ? "T" : "F",
            verifyImgCode: "T"
          });

          if (result.success === false) {
            this.notifyError(result.message || this.$t("TestKey.FailedToObtainTheVerificationCodePleaseTryAgainLater"));
            return false;
          }

          if (process.env.NODE_ENV === "development") {
            this.isSendMsg = true
            this.notifySuccess(`${this.$t("TestKey.Code")}:${result}`);
            return true;
          }

          if (result === true) {
            this.isSendMsg = true
            return true;
          }

          if (result === false) {
            this.notifyError(this.$t("TestKey.FailedToSend"));
            return false;
          }

          const errorMessage = result?.message || this.$t("TestKey.AnErrorOccurredInTheRequestPleaseTryAgainLater");

          this.notifyError(errorMessage);
          return false;
        }
        return false;
      }
      // this.smsLoading = false;
      // if (ok) {
      //   this.smsCooldown = Date.now() + 60 * 1000;
      //   sessionStorage.setItem("header_sms_cool_down", this.smsCooldown);
      //
      //   const loop = () => {
      //     this.currentDate = Date.now();
      //     if (this.DISPLAY_SMS_COOL_DOWN <= 0) return;
      //     delay(500).then(loop);
      //   };
      //
      //   loop();
      // } else {
      //   // this.$refs.LOGIN_ROW.row.validateField(this.INFO_RULES.MOBILE_RULES_PROP.verifySmsCode);
      // }
    },
    async onSetToken(res) {
      //todo: 扫码直接登录成功
      if (res?.success === false) {
        return this.notifyError(res?.message || this.$t("TestKey.WeChatLoginFailed"));
      }
      await this.$store.dispatch("user/LOGIN_SUCCESS", res);
      this.notifySuccess(this.$t("TestKey.LoginSucceeded"));

      if (this.$route.name === "SharedFreightOrder") {
        this.$router.go(0);
      }

      this.accountVisible = false;
      this.bindPhoneVisible = false;
      await this.$refs.WW_LOGIN.updateUrl();
    },
    async onBindPhone(type) {
      if (type === 1) {
        this.bindLoading = true;
        const res = await this.OMS_API.tryLoginByWechat(this.wechatConfig.wechatUserId, {
          phone: this.bindUser.phone,
          countryCode: this.bindUser.area,
          verifyCode: this.bindUser.verifySmsCode
        });
        this.bindLoading = false;

        if (!res || (res?.success === false && res?.message)) {
          return this.notifyError(res?.message || "c");
        }

        const message = await this.$store.dispatch("user/WECHAT_LOGIN", res);
        if (message) {
          return this.notifyError(res?.message || this.$t("TestKey.LoginFailed"));
        }

        this.notifySuccess(this.$t("TestKey.LoginSucceeded"));

        if (this.$route.name === "SharedFreightOrder") {
          this.$router.forward();
        }

        this.accountVisible = false;
        this.bindPhoneVisible = false;
        await this.$refs.WW_LOGIN.updateUrl();
      }
    },
    //刷新验证码
    onRefreshImgCode() {
      this.UUID = this.$guid().replace("-", "");
    },
    async onLoginByWechat(code) {
      this.bindUser.phone = "";
      this.bindUser.area = "86";
      this.bindUser.verifySmsCode = "";
      this.bindPhoneVisible = true;
      this.bindPhoneType = "wechat";
      // 初始的state不对，所以需要回调返回来的code
      this.wechatConfig.wechatUserId = code;
      //15811823978
      // await this.$refs.WW_LOGIN.updateUrl();
      // const wxConfig = this.wechatConfig;
      // const response = await tryLoginByWechat(wxConfig.state);
    },
    // 账号密码登录
    async onLoginByAccount() {
      // const type = 'login.account'
      // const loginInfo = this.HeaderInfo.MobileLogin
      // const ok = await this.$refs.ACCOUNT_ROW.validate()
      // if (ok) {
      //   this.loading = true
      //   const message = await this.$store.dispatch('user/USER_LOGIN', {
      //     type,
      //     user: loginInfo
      //   })
      //   this.loading = false
      //   if (message) {
      //     return this.notifyError(message)
      //   }

      //   this.notifySuccess(this.$t('TestKey.LoginSucceeded'))

      //   if (this.$route.name === 'SharedFreightOrder') {
      //     this.$router.go(0)
      //   }

      //   this.accountVisible = false
      // }
      this.loading = true
      const ok = await this.$refs.ACCOUNT_ROW.validate()
      this.loading = false
      if (ok) {
        this.notifySuccess(this.$t("TestKey.LoginSucceeded"));

        if (this.$route.name === "SharedFreightOrder") {
          this.$router.go(0);
        }
        this.accountVisible = false;
      }
    },
    // 手机号登录/注册
    async onLoginByPhone() {
      const type = "login.phone";
      const loginInfo = this.HeaderInfo.MobileLogin;

      const ok = await this.$refs.LOGIN_ROW.validate();
      if (ok) {
        this.loading = true;
        const message = await this.$store.dispatch("user/USER_LOGIN", {
          type,
          user: loginInfo
        });
        this.loading = false;
        this.onRefreshImgCode();

        if (message) {
          return this.notifyError(message);
        }

        this.notifySuccess(this.$t("TestKey.LoginSucceeded"));

        if (this.$route.name === "SharedFreightOrder") {
          this.$router.go(0);
        }

        this.accountVisible = false;
      }
    },
    onClickToAccountContainer(type = "loginByPhone") {
      this.accountVisible = true;
      this.accountContainerType = type;
    },
    async onLogout() {
      this.logoutLoading = true;
      const message = await this.$store.dispatch("user/LOG_OUT");
      this.logoutLoading = false;
      if (message) {
        this.notifyError(message);
      } else {
        this.notifySuccess(this.$t("TestKey.ExitSucceeded"));
        window.localStorage.setItem("billTip", "");
        window.localStorage.setItem("dialogHome", "");
        this.$router.go(0);
      }
    }
  }
};
</script>

<style lang="scss">

.verify-button {
  img {
    transform: translateX(5px);
  }
}

.row-ul {
  margin-right: 50px;
  display: flex;
  align-items: center;

  .el-dropdown-link {
    // cursor: pointer;
    color: #fff;
    font-size: 15px;
  }

  .icon {
    height: 18px;
    width: 18px;
    margin: 1px 6px 0 0;
  }

  // transform: translateY(20px);
  .internationalization-row {
    .internationalization {
      display: flex;
      align-items: center;
      margin-right: 15px;

      .text {
        font-size: 18px;
        color: #fff;
        margin: 0 4px;
      }

      .arrow {
        width: 10px;
        height: 6px;
        margin-top: 8px;
      }
    }
  }
}
</style>
<style lang="scss">
.app-header_popper-style[x-placement='bottom'],
.app-header_popper-style[x-placement='bottom-end'] {
  padding: 0;
  margin-top: 0;

  &.min-170 {
    min-width: 170px;
    box-sizing: border-box;
  }

  &.min-190 {
    min-width: 190px;
    box-sizing: border-box;
  }

  &.min-210 {
    min-width: 210px;
    box-sizing: border-box;
  }

  &.avatar {
    margin-top: 18px;
  }

  .popper__arrow {
    display: none;
  }

  &.solution {
    padding: 30px 40px;
  }

  &.business {
    // padding: 30px 40px;

    .flex {
      flex-grow: 1;
      flex-basis: 25%;
    }
  }

  &.app-header-transition-enter-active,
  &.app-header-transition-leave-active,
  &.app-header-transition-enter-to,
  &.app-header-transition-leave-to {
    transition: 0.2s linear;
  }

  &.app-header-transition-enter {
    opacity: 1;
    transform: translate(0, 0);
  }

  &.app-header-transition-enter,
  &.app-header-transition-leave-to {
    opacity: 0;
    transform: translate(0, -3px);
  }
}
</style>
<style lang="scss" scoped>
.name-text {
  display: block;
  text-align: center;
  margin-left: 8px;
  // width: 150px !important;
  min-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #fff;
}

.over-hidden {
  overflow: hidden;
}

header.homePage {
  z-index: 999 !important; // 防止driver时被隐藏
  position: absolute;
  background: none;
  position: fixed;
  top: 0;
  transition: all 0.2s;
  height: 72px;

  .name-text {
    color: rgba(255, 255, 255, 0.75);
  }

  .pointer {
    color: rgba(255, 255, 255, 0.75);
  }

  .menu-ul {
    position: relative;

    .workSpace-mask {
      position: absolute;
      top: 22px;
      left: 333px;
      opacity: 1;
      width: 50px;
      height: 30px;
      // background: #fff;
      display: none;
    }
  }

  .menu-li {
    ::v-deep a {
      color: rgba(255, 255, 255, 0.75);
    }

    &.on a {
      color: #fff;
    }
  }

  .row-ul .el-dropdown-link {
    color: rgba(255, 255, 255, 0.75);
  }

  // .menu-li {
  //     &.on{
  //       background-color: #2961b9;
  //       a {
  //       color: #fff;
  //       }
  //     }
  //     &:hover{
  //       background-color: #2961b9;
  //     }
  // }
}

header.fixedClass {
  transition: all 0.2s;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.03);

  .name-text {
    color: #333;
  }

  .pointer {
    color: #333;
  }

  .menu-li {
    ::v-deep a {
      color: #333 !important;
    }

    &.on a {
      color: #333 !important;
      font-weight: 500 !important;
    }
  }

  .row-ul .el-dropdown-link {
    color: #333;
  }
}

header.queryPage {
  position: absolute;
  background: none;

  .name-text {
    color: rgba(255, 255, 255, 0.75);
  }

  .pointer {
    color: rgba(255, 255, 255, 0.75);
  }

  .menu-li {
    ::v-deep a {
      color: rgba(255, 255, 255, 0.75);
    }

    &.on a {
      color: #fff;
    }
  }

  .row-ul .el-dropdown-link {
    color: rgba(255, 255, 255, 0.75);
  }
}

header.businessPage {
  position: absolute;
  background: none;

  .name-text {
    color: rgba(255, 255, 255, 0.75);
  }

  .pointer {
    color: rgba(255, 255, 255, 0.75);
  }

  .menu-li {
    ::v-deep a {
      color: rgba(255, 255, 255, 0.75);
    }

    &.on a {
      color: #fff;
    }
  }

  .row-ul .el-dropdown-link {
    color: rgba(255, 255, 255, 0.75);
  }
}

header.workSpacePage {
  $HEADER_HEIGHT: 72px;
  background: #fff;
  height: $HEADER_HEIGHT;

  .header {
    height: $HEADER_HEIGHT;
  }

  .workContainer {
    max-width: 100%;
    min-width: 1366px;

    .login-container {
      flex-basis: 500px;
    }

    .user-info .lh-22 {
      span {
        color: #333 !important;
      }
    }
  }

  .logo.blue {
    margin-left: 32px;
  }

  .name-text {
    color: #333;
  }

  .header .menu-ul {
    height: $HEADER_HEIGHT;
    left: 15%;
  }

  .menu-li {
    ::v-deep a {
      color: #333;
    }

    &.on a {
      color: #333;
    }

    .bottom-line {
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 33px;
        height: 3px;
        position: absolute;
        bottom: 15%;
        left: 33%;
        background: #237aff;
        border-radius: 2px;
      }
    }
  }

  .row-ul .el-dropdown-link {
    color: #333;
  }
}

header.bluePage {
  $HEADER_HEIGHT: 72px;
  background: $BASE_COLOR;
  height: $HEADER_HEIGHT;

  .header {
    height: $HEADER_HEIGHT;
  }

  .workContainer {
    max-width: 100%;
    min-width: 1366px;

    .login-container {
      flex-basis: 500px;
    }

    .user-info .lh-22 {
      span {
        color: #fff !important;
      }
    }
  }

  .logo.blue {
    margin-left: 32px;
  }

  .name-text {
    color: #fff;
  }

  .header .menu-ul {
    height: $HEADER_HEIGHT;
  }

  .menu-li {
    ::v-deep a {
      color: #fff;
    }

    &.on a {
      color: #fff;
    }

    .bottom-line {
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 33px;
        height: 3px;
        position: absolute;
        bottom: 15%;
        left: 33%;
        background: #237aff;
        border-radius: 2px;
      }
    }
  }

  .row-ul .el-dropdown-link {
    color: #fff;
  }
}

header.whiteMiddle {
  height: var(--statusbarHeight);
  //background: #fff;

  &.fixed {
    .header-space {
      display: block;
      width: var(--headerWidth);
      height: var(--headerHeight);
      opacity: 0;
    }

    .header {
      position: fixed;
    }
  }

  * {
    color: #333333;
    font-family: SourceHanSansSC-Regular, SourceHanSansSC, sans-serif;
  }


  > .header, > .header-space {
    background: #fff;
  }

  .header {
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 0;

    > div {
      max-width: 1369px;
    }
  }

  /*
  &.is-absolute {
    .header {
      position: absolute;
      transition: none;
      transform: none;
    }
  }*/
}

header {
  $HEADER_HEIGHT: 72px;
  font-size: 16px;
  position: relative;
  width: 100%;
  height: $HEADER_HEIGHT;
  // background: $BASE_COLOR;
  background: none;
  z-index: 999;

  .app-link {
    height: $HEADER_HEIGHT;
    //line-height: $HEADER_HEIGHT;
    align-items: center;
    display: flex;
  }

  .header-space {
    height: $HEADER_HEIGHT;
    display: none;
  }

  &.fixed {
    .header {
      position: fixed;
      width: 100vw;
      left: 0;
      top: 0;
      background: #fff;
    }
  }

  .header {
    height: $HEADER_HEIGHT;
    // background: $BASE_COLOR;
    background: none;

    > .container {
      margin: 0 auto;
      height: 100%;
      background: none;
      overflow: hidden;

      .flex {
        height: 100%;
      }
    }

    .menu-li {
      position: relative;
      height: 100%;
      max-width: 120px;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      text-align: center;
      transition: background-color 0.2s linear;
      background-color: transparent;

      ::v-deep a {
        padding: 0 25px;
        // color: #fff
      }

      &.on a {
        // background-color: #2961b9;
      }
    }

    .menu-ul {
      position: absolute;
      top: 0;
      left: 34%;
      // transform: translate(-50%, 0);
      padding-right: 96px;
    }
  }
}

.display-menu-list {
  display: flex;
  flex-direction: column;

  p {
    color: #333;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    white-space: nowrap;
  }

  ::v-deep a {
    color: #777;
    padding: 10px 0;
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    user-select: none;
    cursor: pointer;
    // white-space: nowrap;
    word-break: break-all;

    &:hover {
      color: #1c57af;
    }

    &.active {
      color: #1c56af;
    }

    &.product {
      padding: 0 20px;
      line-height: 48px;
      min-height: 48px;
      transition: 0.2s linear;

      &:hover {
        background: #ededed;
        color: #333;
        font-weight: 500;
      }

      &:active {
        background: darken(#ededed, 10%);
        color: #000;
        font-weight: 500;
      }
    }
  }
}

.control-list {
  display: flex;
  flex-direction: column;
  line-height: 20px;
  font-size: 16px;
  padding: 10px 0;

  li > a {
    padding: 10px 50px 10px 20px;
    box-sizing: border-box;
  }

  .user-set {
    display: flex;
    align-items: center;
    width: 100%;

    &:before {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 10px;
      background-image: url('../assets/images/user-set@2x.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .log-out {
    display: flex;
    align-items: center;

    &:before {
      content: '';
      display: block;
      margin-right: 11px;
      width: 16px;
      height: 15px;
      background-image: url('../assets/images/log-out@2x.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

.oms-extend__clear-tabs-effect.tab-center {
  ::v-deep .el-tabs__nav.is-top {
    float: none;
    margin: 0 auto;
    text-align: center;
    // :first-child {
    //  max-width:169px;
    // transform: translateY(20px) !important;
    // }
    .el-tabs__item {
      max-width: 169px;
      white-space: pre-wrap;
      line-height: 18px;
    }
  }
}

// .account-by-phone-container {
//   padding: 35px 50px 40px;
// }

.account-by-phone-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 25px 30px 30px 24px;
  max-height: 500px;
  box-sizing: border-box;

  .img-wrap {
    width: 380px;
    margin-right: 30px;
    border-right: 1px solid #dcdfe6;

    .logo {
      width: 123px;
      height: 36px;
      margin-bottom: 30px;
    }

    .login-img {
      width: 351px;
      height: 345px;
    }
  }

  ::v-deep .login-tabs {
    width: 448px;
    height: 372px;
    padding-top: 15px;

    .agree-btn {
      width: 16px;
      height: 16px;
      border: 1px solid #1c57afff;
      border-radius: 50%;
      transform: scale(0.5);

      &.is-agree {
        background: url('@/assets/images/header-login-confirm@2x.png') no-repeat;
        background-size: inherit;
        background-position: center;
      }
    }

    .login-tips {
      display: inline-block;
      font-size: 10px;
      font-weight: 400;
      color: #aaaaaa;
      line-height: 15px;
      margin-bottom: 12px;

      &.ru {
        margin: 0;
      }
      &.mb-0{
        margin-bottom:0;
      }
    }

    .el-form-item {
      margin-bottom: 20px !important;
    }

    .el-tabs__item {
      font-size: 20px;
      font-weight: 400;
      color: #777777;
      line-height: 27px;
      // transition: all 0.2s;
      &.is-active {
        // font-size: 20px;
        font-weight: 500;
        color: #333333;
        line-height: 29px;
      }
    }

    .el-tabs__active-bar {
      height: 2px;
      background: #1c57afff;
      border-radius: 1px;
    }

    .el-tabs__nav-wrap::after {
      display: none;
    }

    .login-input .el-input__inner {
      font-size: 14px;
      font-weight: 400;
      color: #aaaaaa;
      line-height: 20px;
    }

    .login-button {
      height: 44px;
      border-radius: 5px;
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      letter-spacing: 5px;
    }
  }
}

.u-text {
  display: block;
  line-height: 28px;

  &.text-1 {
    text-indent: 32px;
  }
}

.max-200 {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
