import Vue from "vue";

Vue.directive("autoTranslate", {
  inserted: function (el, binding,vNode) {
    console.log(el,vNode);
    el.childNodes.forEach(node => {
      if (node.nodeType === 3) {

      }
    })
  },
  update: function (el, binding) {

  },
  componentUpdated: function (el, binding) {

  },
  unbind: function (el, binding) {

  },
  bind() {

  },
});
