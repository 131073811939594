<template>
  <el-row v-if="row" v-bind="CurrentProps">
    <slot/>
  </el-row>
  <el-form ref="form" v-else-if="IsForm" v-bind="CurrentProps" :rules="FormRules"
           :status-icon="false"
           :validate-on-rule-change="false" class="oms-form-extend">
    <el-row>
      <slot/>
    </el-row>
  </el-form>
  <oms-base-col v-else v-bind="BaseColCurrentProps" ref="OMSBaseCol">
    <!-- 元素前插入 -->
    <slot name="item-append"/>

    <slot name="label" slot="label" v-if="$slots.label"/>
    <slot name="label-suffix" slot="label-suffix" v-if="$slots['label-suffix']"/>
    <!--   如果存在默认自定义slot的情况下 -->
    <slot v-if="$slots.default" :class="ItemClassList"/>
    <!-- 国内车型 -->
    <el-row class="flex" v-else-if="TypeComputed.isTruckIn">
      <el-input class="mr-10" v-model="DynamicModel" v-bind="CurrentProps" :data-dynamic-model="DynamicModel + ''"/>
      <el-select v-model="ToukDynamicModel" :class="ItemClassList" :disabled="ToukDisabledComputed">
        <el-option v-for="(item,i) in PropInitializeOptionsComputed" v-bind="item"
                   :label="$t(item.label)"
                   :key="item.label + i">
          <div class="flex between" v-if="!HasChinese(item.value) && (item._displayValue !== false)">
            <span>{{ $t(item.label) }}</span>
            <span class="fc-aaa">{{ item._displayValue }}</span>
          </div>
        </el-option>
      </el-select>
    </el-row>
    <!-- 手机号 -->
    <el-row ref="PhoneRow" class="flex phone-container-row" v-else-if="TypeComputed.isPhone">
      <select-area-number class="item-1" v-model="ToukDynamicModel" :element="() => $refs.PhoneRow.$el"/>
      <el-input class="item-2" v-model="DynamicModel" v-bind="CurrentProps"/>
      <!--      <el-select v-model="ToukDynamicModel">
              <el-option v-for="item in PropInitializeOptionsComputed" v-bind="item" :key="item.value"/>
            </el-select>-->
    </el-row>
    <!--  输入框/兼搜索栏  -->
    <el-select-input v-else-if="TypeComputed.isSelectInput" v-model="DynamicModel" v-on="CurrentListeners"
                     :popperClass="EffectClassComputed"
                     v-bind="CurrentProps"/>
    <!-- 国外车型 -->
    <el-row class="flex" v-else-if="TypeComputed.isTruckOut">
      <el-input class="mr-10" v-model="DynamicModel" v-bind="CurrentProps"/>
      <el-select v-model="ToukDynamicModel" :class="ItemClassList" :disabled="ToukDisabledComputed">
        <el-option v-for="(item,i) in PropInitializeOptionsComputed" v-bind="item"
                   :label="$t(item.label)"
                   :key="item.label + i">
          <div class="flex between" v-if="!HasChinese(item.value) && (item._displayValue !== false)">
            <span>{{ $t(item.label) }}</span>
            <span class="fc-aaa">{{ item._displayValue }}</span>
          </div>
        </el-option>
      </el-select>
    </el-row>
    <!-- 新版国外车型 -->
    <el-input-number-extend :placeholder="$t_reserve('请输入数量')" :controls="false" v-else-if="TypeComputed.isInputOptions" v-model="DynamicModel" :class="[ItemClassList, {
      'oms-item_effect_select__select-container': effect === 'OMS',
    }]" >
      <el-select v-model="ToukDynamicModel" slot="append" class="input-options_select" :class="{'car-type-select':isRu || isEn}"
                 :popperClass="EffectClassComputed">
        <el-option v-for="item in PropInitializeOptionsComputed" :key="item.value" :label="$t(item.label)"
                   :value="item.value"/>
      </el-select>
    </el-input-number-extend>
    <!-- 新版货币选择 -->
    <component :is="TypeComputed.isNumber ? 'el-input-number-extend' : 'el-input'"
               v-else-if="(TypeComputed.isInput || TypeComputed.isNumber) && OptionSourceType.isCurrency"
               v-model="DynamicModel" v-bind="CurrentProps" v-on="CurrentListeners">
      <currency v-model="ToukDynamicModel" popper-class="oms-item_popper_popper-style" class="w-90" placeholder=""
                slot="append"/>
    </component>

    <!-- 海关编号 -->
    <el-select-input v-else-if="OptionSourceType.isHsCode" v-model="DynamicModel" v-bind="CurrentProps"
                     :popperClass="EffectClassComputed"
                     v-on="CurrentListeners"/>
    <!-- 货币 -->
    <el-row class="flex" v-else-if="TypeComputed.isCurrcd">
      <el-input-number v-if="CurrentProps['input-type'] === 'number'" :controls="false" class="mr-10 w-p-100"
                       v-model="DynamicModel" v-bind="CurrentProps"/>
      <el-input v-else class="mr-10" v-model="DynamicModel" v-bind="CurrentProps"/>
      <currency v-model="ToukDynamicModel" :disabled="ToukDisabledComputed" :popperClass="EffectClassComputed"/>
    </el-row>
    <!-- 选择框 -->
    <el-checkbox v-else-if="TypeComputed.isCheckbox" v-model="DynamicModel" :class="ItemClassList"
                 v-bind="CurrentProps" :label="getExtendProp('label')"
                 true-label="T" false-label="F">
      <div v-if="$slots['item-label']">
        <slot name="item-label" v-if="$slots['item-label']"></slot>
        <el-icon class="yeah" name="checkbox__checked" v-if="DynamicModel === 'T' && effect === 'OMS'"/>
      </div>
    </el-checkbox>
    <!-- 单选 -->
    <div v-else-if="TypeComputed.isRadio" :class="ItemClassList">
      <template v-for="(item,index) in PropInitialize.options">
        <el-radio v-model="DynamicModel"
                  :ref="'RadioItemList-' + index"
                  v-if="!item.slot"
                  :disabled="item.disabled || false"
                  :label="item.type === 'select' ? item.list.tryFind(x => x.value === DynamicModel,{value:'NON_SELECT_ITEM__VALUE'}).value : item.value">
          <el-select :value="item.list.tryFind(x => x.value === DynamicModel,{value: item.label}).value"
                     :element="() => $refs['RadioItemList-' + index]"
                     :class="[effect === 'OMS' ? 'oms-item_effect_radio-select-container' : '']"
                     :popperClass="EffectClassComputed"
                     @change="DynamicModel = $event;consoleLog($event)" v-if="item.type === 'select'">
            <el-option v-for="item in item.list" :key="item.value"
                       :label="$t_reserve(item.label)" :value="item.value"/>
          </el-select>
          <slot :name="'prop.' + item.value" v-else-if="$scopedSlots['prop.' + item.value]"
                :row="Object.assign({},item,{label:$t(item.label)})"/>
          <template v-else>{{ $t(item.label) }}</template>
        </el-radio>
        <slot v-else :name="item.slot"/>
      </template>
    </div>
    <!-- 日期（不含时间） -->
    <el-date-picker-extend v-else-if="TypeComputed.isDate" :picker-options="PickerOptionsBegin.dynamic" type="date"
                           v-bind="CurrentProps"
                           :popper-class="EffectClassComputed"
                           :auto-width="effect === 'OMS'"
                           :value-format="GetFormatType.useElement" :format="GetFormatType.useElement"
                           v-model="DynamicModel"/>
    <!-- 标签分栏输入框 -->
    <el-tag-input v-model="DynamicModel" v-else-if="TypeComputed.isTagInput" v-bind="CurrentProps"
                  :api="api" :api-params="apiParams" :api-callback="apiCallback"
                  :popperClass="EffectClassComputed"
                  v-on="CurrentListeners"/>
    <!-- 输入框（多行）-->
    <el-input type="textarea" v-else-if="TypeComputed.isTextarea" v-bind="CurrentProps" v-on="CurrentListeners"
              v-model="DynamicModel"/>
    <!-- 输入框 -->
    <el-input v-else-if="TypeComputed.isInput" :class="ItemClassList" v-bind="CurrentProps" v-model="DynamicModel">
      <slot name="append" slot="append"/>
      <slot name="prepend" slot="prepend"/>
      <slot name="prefix" slot="prefix"/>
      <slot name="suffix" slot="suffix"/>
    </el-input>
    <!-- 数字框 -->
    <el-input-number-extend v-else-if="TypeComputed.isNumber" v-model="DynamicModel" :class="ItemClassList"
                            v-bind="CurrentProps">
      <slot name="append" slot="append"/>
      <slot name="prepend" slot="prepend"/>
      <slot name="prefix" slot="prefix"/>
      <slot name="suffix" slot="suffix"/>
    </el-input-number-extend>
    <!-- 二级选择 -->
    <el-cascader-extend v-else-if="TypeComputed.isCascader"
                        :value="[(PropInitializeOptionsComputed && PropInitializeOptionsComputed.find && PropInitializeOptionsComputed.find(x => x.children.find(n => n.Id === DynamicModel)) || {}).Id ,DynamicModel]"
                        :class="ItemClassList"
                        v-bind="CurrentProps" v-on="CurrentListeners"
                        :options="PropInitializeOptionsComputed">
      <span slot-scope="{node,data}">{{ $t(node.label) }}</span>
    </el-cascader-extend>
    <!-- 普通选择 -->
    <el-select v-else-if="!virtual && TypeComputed.isSelect" v-model="DynamicModel" :class="ItemClassList"
               :popperClass="EffectClassComputed"
               v-on="CurrentListeners"
               v-bind="CurrentProps">
      <el-option v-for="(item,i) in PropInitializeOptionsComputed" v-bind="item"
                 :label="$t_reserve(item.label)"
                 :key="item.label + i">
        <div class="flex between" v-if="!HasChinese(item.value) && (item._displayValue !== false)">
          <span>{{ $t_reserve(item.label) }}</span>
          <span class="fc-aaa">{{ item._displayValue }}</span>
        </div>
      </el-option>
    </el-select>
    <!-- 虚拟化选择 -->
    <virtual-select v-else-if="virtual && TypeComputed.isSelect"
                    v-bind="CurrentProps"
                    v-on="CurrentListeners"
                    :class="ItemClassList"
                    v-model="DynamicModel"
                    :buffer="32"
                    :popperClass="EffectClassComputed"
                    icon-name="el-icon-search"
                    :items="PropInitializeOptionsComputed"
                    :item-size="32"
                    filterable
                    keyField="value">
      <template slot-scope="{ item }">
        <el-option-virtual slot="default" :key="item.label" :label="$t(item.label)" :value="item.value">
          <div class="flex between">
            <span>{{ $t(item.label) }}</span>
            <span class="fc-aaa">{{ item._displayValue }}</span>
          </div>
        </el-option-virtual>
      </template>
    </virtual-select>
    <!-- 连体电话选择 -->
    <el-input v-model="DynamicModel" v-bind="CurrentProps" v-on="CurrentListeners"
              v-else-if="TypeComputed.isSelectInputTel">
      <select-area-number v-model="ToukDynamicModel" style="width:90px;" slot="prepend"
                          popper-class="oms-item_popper_popper-style"/>
    </el-input>
    <!-- 元素后插入 -->
    <slot name="item-suffix"></slot>
  </oms-base-col>
</template>

<script>
import Element             from "element-ui";
import VirtualSelect       from "@/components/elementExtend/ElSelectVirtual.vue";
import moment              from "moment-timezone";
import Currency            from "@/components/nbsExtend/modules/Currency";
import typeComputed        from "@/mixins/global/typeComputed";
import ElOptionVirtual     from "@/components/elementExtend/ElOptionVirtual";
import ElCascaderExtend    from "@/components/elementExtend/ElCascaderExtend";
import ElSelectExtendEx    from "@/components/elementExtend/ElSelectExtendEx";
import ElInputExtendEx     from "@/components/elementExtend/ElInputExtendEx";
import ElFormExtend        from "@/components/elementExtend/ElFormExtend";
import SelectAreaNumber    from "@/components/omsExtend/SelectAreaNumber";
import ElSelectInput       from "@/components/elementExtend/ElSelectInput";
import AsyncValidator      from "async-validator";
import ElInputNumberExtend from "@/components/elementExtend/ElInputNumberExtend.vue";
import ElDatePickerExtend  from "@/components/elementExtend/ElDatePickerExtend.vue";

export default {
  name: "OmsItem",
  components: {
    ElDatePickerExtend,
    ElInputNumberExtend,
    ElSelectInput,
    SelectAreaNumber,
    ElForm: ElFormExtend,
    ElSelect: ElSelectExtendEx,
    ElInput: ElInputExtendEx,
    ElCascaderExtend,
    ElOptionVirtual,
    Currency,
    VirtualSelect,
  },
  model: {
    event: "change",
    prop: "value",
  },
  data() {
    return {
      fields: [],
      visible: false,
      FormRules: {},
      // 当前MainForm父子表单集合
      MainFormComponents: [],
      symbolModel: "",
      viewModel: this.value,
      // 统合系Model - 用于需要扩展组件Model的情况下
      ToukModel: {},
      PropInitialize: {
        options: [],
      },
    };
  },
  watch: {
    "omsMainForm.FormRules"() {
      if (this.form && this !== this.omsMainForm) {
        this.FormRules = this.getFormRules();
      }
    },
    rules() {
      if (this.form) {
        this.FormRules = this.getFormRules();
      }
    },
    PropInitializeOptionsComputed(list) {
      this.$emit("options-change", list);
    },
    value(val) {
      if (this.log) {
        debugger;
      }
      this.viewModel = val;
    },
    viewModel: {
      deep: true,
      handler(val) {
        if (this.log) {
          debugger;
        }
        this.$emit("change", val);
      },
    },
    options: {
      deep: true,
      handler() {
        this.onInitialize();
      },
    },
    DynamicModel(val) {
      this.onUpdateToukModel();
    },
    "$i18n.locale"() {
      this.onUpdateToukModel();
    }
  },
  mixins: [typeComputed],
  methods: {

    /** <业务核心逻辑> */
    // 基于与DynamicModel绑定的个别字段关系可使用Update自动更新
    onUpdateToukModel() {
      if (this.TypeComputed.isSelect) {
        let item;
        if(this.multiple){
          item = this.PropInitialize.options.filter(x => this.DynamicModel.includes(x.value));
        }else{
          item = this.PropInitialize.options.find(x => x.value === this.DynamicModel);
          item = [item].filter(Boolean);
        }

        if (item.length > 0) {
          this.ToukDynamicModel = item.map(item => this.$t(item.label)).join(',');
          this.$emit("touk-change", this.ToukDynamicModel);
        }
      } else if (this.TypeComputed.isCascader) {
        const flat = this.PropInitialize.options.map(x => ([x, x.children])).flat(2);
        const item = flat.find(x => x.Id === this.DynamicModel);
        const pItem = this.PropInitialize.options.find(x => x.Id === item.Pid);

        if (item) {
          this.ToukDynamicModel = `${this.$t(`ApiCategory.Option.${item.Pid}`, 'zh-CN') ?? ""}/${this.$t(`ApiCategory.Option.${item.Id}`, 'zh-CN')}`;
          this.$emit("touk-change", [pItem, item]);
        }
      }
    },
    onProgressDependent(ev) {
      if (this.TypeComputed.isSelect) {
        // this.PropInitialize.options.find(x => )
        /*let templateStr = val => val;
        if (this.OptionSourceType.isAirStation) {
          templateStr = val => `ApiAirStation.Option.${val}`;
        } else if (this.OptionSourceType.isSeaPort) {
          templateStr = val => `ApiSeaPort.Option.${val}`;
        } else if (this.OptionSourceType.isStation) {
          templateStr = val => `ApiStation.Option.${val}`;
        } else if (this.OptionSourceType.isCity) {
          templateStr = val => `ApiCity.Option.${val}`;
        } else if (this.OptionSourceType.isCarrier) {
          templateStr = val => `ApiCarrier.A.Option.${val}`;
        }

        if (ev) {
          this.ToukDynamicModel = this.$t(templateStr(ev?.label || ev));
        }*/
      } else if (this.TypeComputed.isCascader) {
        if (this.OptionSourceType.isCargoKind) {
          [, ev] = ev;

          // 假设只有2级
          // const flat = this.PropInitialize.options.map(x => ([x, x.children])).flat(2);
          // const item = flat.find(x => x.Id === ev);
          // const root = flat.find(x => x.Id === item.Pid);

          this.DynamicModel = ev;
          // if (item) {
          // this.ToukDynamicModel = `${item?.PName ?? ""}/${item.CategoryName}`;
          // }
        }
      }
    },
    /** </业务核心逻辑> */

    /** <组件逻辑>*/
    validate() {
      let list = this.MainFormComponents.map((omsForm, index) => {
        if (!omsForm.$refs.form) {
          //  存在被纳入的无效Form表单时责跳过
          return null;
        }

        console.log(`omsForm.$refs.form-${index}`, omsForm.$refs.form);
        return new Promise(resolve => {
          omsForm.$refs.form.validate(valid => {
            console.log("valid", valid);
            resolve(valid);
          });
        });
      }).filter(Boolean);
      return Promise.all(list).then(resultList => resultList.every(Boolean));
    },
    clearValidate() {
      setTimeout(() => {
        this.MainFormComponents.forEach(omsForm => omsForm.$refs.form?.clearValidate());
      });
    },
    // 内部代码自检测整个Form表单或单个Item，该当函数不会将错误信息反馈到界面上
    innerCheckValidate() {
      if (this.form) {
        let fields = this.fields;
        if (this.omsMainForm) {
          fields = this.omsMainForm.fields;
        }

        let results = [];
        for (let field of fields) {
          results.push(field.innerCheckValidate());
        }

        return Promise.all(results);
      } else {
        const rulesContainer = this.omsMainForm.getFormRules();
        const rules = rulesContainer[this.prop];

        const descriptor = {};
        if (rules && rules.length > 0) {
          rules.forEach(rule => {
            delete rule.trigger;
          });
        }
        descriptor[this.prop] = rules;
        const model = {};

        model[this.prop] = this.DynamicModel;

        const validator = new AsyncValidator(descriptor);
        return new Promise(resolve => {
          validator.validate(model, {firstFields: true}, ([error] = [], fields) => {
            resolve(error);
          });
        });

      }
    },
    // 检车是否存在中文
    HasChinese(value = "") {
      if (value) {
        return /[\u4e00-\u9fa5]/ig.test(value);
      }

      return false;
    },
    async onInitialize() {
      if (this.TypeComputed.isSelect || this.TypeComputed.isRadio || this.TypeComputed.isInputOptions) {
        let api = this.api;
        let params = this.apiParams;
        let apiCallback = this.apiCallback;

        // 站点列表（起运站、目的站等）
        if (["isStation", "isCity", "isAirStation", "isCarrier", "isSeaComp", "isSeaPort",'isTransPort'].some(key => this.OptionSourceType[key])) {
          let list = [], templateStr = val => val;
          // 空运站点
          if (this.OptionSourceType.isAirStation) {
            await this.$store.dispatch("business/GET_AIR_STATION");
            list = (this.$store.getters.area.airStations ?? []);
            templateStr = val => `ApiAirStation.Option.${val}`;
          }
          // 陆运站点
          else if (this.OptionSourceType.isStation) {
            await this.$store.dispatch("business/GET_STATION");
            list = (this.$store.getters.area.stations ?? []);
            templateStr = val => `ApiStation.Option.${val}`;
          }
          // 城市
          else if (this.OptionSourceType.isCity) {
            await this.$store.dispatch("business/GET_TRUCK_STATION");
            list = (this.$store.getters.area.truckStations ?? []);
            templateStr = val => `ApiCity.Option.${val}`;
          }
          // 起运港/中转港/内贸港/目的港
          else if (this.OptionSourceType.isSeaPort) {
            await this.$store.dispatch("business/GET_SEA_STATION",);
            list = (this.$store.getters.area.seaStations ?? []);
            templateStr = val => `ApiSeaPort.Option.${val}`;
          }
          // 中转站
          else if (this.OptionSourceType.isTransPort) {
            await this.$store.dispatch("business/GET_TRANS_STATION",);
            list = (this.$store.getters.area.transPortStations ?? []);
            templateStr = val => `ApiTransPort.Option.${val}`;
          }
          // 航司/船东
          else if (this.OptionSourceType.isCarrier || this.OptionSourceType.isSeaComp) {
            let type;
            if (this.OptionSourceType.isCarrier) {
              type = "A";
            } else if (this.OptionSourceType.isSeaComp) {
              type = "C";
            }
            await this.$store.dispatch("business/GET_CARRIER_LIST", type);
            list = (this.$store.state.business.carrierList[type] ?? []);
            templateStr = val => `ApiCarrier.${type}.Option.${val}`;
          }
          this.PropInitialize.options = list.map(x => Object.assign({}, x, {
            label: templateStr(x.value),
          }));
        }
        // 专属销售
        else if (this.OptionSourceType.isSaler) {
          let list = await this.API.Options.getClerkList();
          if (list?.length === 1) {
            this.onChangeSaler(list[0].SalerId);
            // this.ToukModel = list[0]?.
          }

          this.PropInitialize.options = list.map(x => Object.assign({}, x, {
            label: x.RealName,
            value: x.SalerId,
            _displayValue: x.Account,
          }));
        }
        // 箱型箱量
        else if (this.OptionSourceType.isLoadCon) {
          await this.$store.dispatch("business/GET_CON_INFO_QUERY");
          this.PropInitialize.options = this.$store.state.business.cnts.map(con => {
            let sizeType = `${con.Size}${con.Type}`;
            return Object.assign({}, con, {
              label: sizeType,
              SizeType: sizeType,
              value: sizeType,
              _displayValue: false,
            });
          });
        }
        // 国内/外段车型
        else if (this.OptionSourceType.isTruckIn || this.OptionSourceType.isTruckOut) {
          let inout = ["I", "O"][+!this.OptionSourceType.isTruckIn];
          await this.$store.dispatch("business/GET_TRUCK_TYPE", inout);
          this.PropInitialize.options = this.$store.state.business.TruckTypeModel[inout];

        }
        // 尝试投入自动调用
        else if (typeof this.options === "string") {
          await this.$store.dispatch("business/GET_BUSINESS", this.options);
          this.PropInitialize.options = this.$store.state.business.hashStory.get(this.options);
        }
        // 自定义api
        else if (api) {
          let callback = apiCallback ?? (data => data);
          let response = await api(params);
          if (!Array.isArray(response)) {
            response = response?.data ?? [];
          }
          let resp = callback(response);
          if (Array.isArray(resp)) {
            this.PropInitialize.options = resp;
          }
        }
        // 默认处理
        else {
          let options = [];
          if (Array.isArray(this.options)) {
            options = this.options;
          }
          this.PropInitialize.options = options;
        }

        this.PropInitialize.options = this.PropInitialize.options.map(x => {
          if (typeof x === "string") {
            x = {label: x, value: x,};
          }

          //todo: 可以怡情扩展

          x._displayValue = x._displayValue ?? x.value;
          return x;
        });

        /*const monitorProxy = this.CurrentProps["monitor-options-proxy"];

        if (typeof monitorProxy === "function") {
          this.PropInitialize.options = monitorProxy(this.PropInitialize.options);
        }*/

        this.$emit("options-change", this.PropInitialize.options);
      }
      // 货物大类
      else if (this.TypeComputed.isCascader) {
        if (this.OptionSourceType.isCargoKind) {
          await this.$store.dispatch("business/GET_CARGO_KIND_LIST");
          this.PropInitialize.options = this.$store.state.business.categoryList;
        }
      }
      // 国内外车型
      else if (this.TypeComputed.isTruckIn || this.TypeComputed.isTruckOut) {
        let inout = ["I", "O"][+!this.TypeComputed.isTruckIn];
        await this.$store.dispatch("business/GET_TRUCK_TYPE", inout);
        this.PropInitialize.options = this.$store.state.business.TruckTypeModel[inout];
      }
      // 货币处理
      else if (this.TypeComputed.isCurrcd) {
        this.ToukModel = "";
      }
    },
    // 处理初始表单的逻辑
    onInitFormProgress() {
      // 在存在指定rules 或 指定attrs的 mainForm 时将其表单视为主表单
      if (this.TypeComputed.isMainForm) {
        this.MainFormComponents.push(this);
      }
      // 普通子表单
      else if (this.TypeComputed.isForm && this.omsMainForm) {
        this.omsMainForm.MainFormComponents.push(this);
      }
    },
    /** </组件逻辑>*/

    /** <杂项逻辑> */
    getExtendProp(prop) {
      if (prop === "required") {
        let val;
        if (this[prop] !== null && this[prop] !== undefined) {
          // 针对required性质，传入的空字符串也可算入true
          val = this[prop]?.trim?.() === "" || this[prop];
        } else {
          val = this.omsItem.getExtendProp?.(prop) ?? false;
        }

        return val;
      }
      return this[prop] ?? this.omsItem.getExtendProp?.(prop);
    },
    // 针对销售员暂时做特殊处理
    async onChangeSaler(ev) {
      await delay(100)
      const item = this.PropInitialize.options.find(item => item.value === ev);
      if (item) {
        let model = this.ExtendDependent;

        if (this.DynamicModel !== ev) this.DynamicModel = ev;
        this.ToukDynamicModel = item?.Account;

        model = this.PathProps.slice(0, -1).reduce((model, prop) => model[prop], model);
        model["Saler"] = item.RealName;
      }
    },
    onCheckRepeatProp() {
      const left = Array.from(new Set(this.PathProps));

      if (left.length !== this.PathProps.length) {
        console.warn("警告：存在可能会导致问题的键值，Prop 列表：", this.PathProps);
      }
    },
    getFormRules() {
      if (!this.form) {
        return {};
      }

      let rules = this.rules || this.$attrs.rules;
      // 避免死循环
      if (!rules && this.omsMainForm !== this) {
        rules = this.omsMainForm.FormRules;
      }

      if (typeof rules === "object") {
        return Object.keys(rules).reduce((total, key) => {
          let val = rules[key];
          // let translateKey = this.ruleTranslateKey || this.omsMainForm.ruleTranslateKey;
          if (typeof val === "string") {
            total[key] = [{required: true, message: val}];
          } else if (typeof val === "function") {
            total[key] = [{validator: val}];
          } else {
            if (!Array.isArray(val)) {
              val = [val];
            }
            total[key] = val;
          }
          if (!this.manualTrigger) {
            total[key].forEach(rule => rule.trigger = "save");
          }

          return total;
        }, {});
      }

      return {};
    },
    onFindLabelI18nKey(label) {
      const flatten = this.$i18n._messageFlatten?.["zh-CN"] || {};
      return Object.keys(flatten).find(key => flatten[key] === label);
    },
    /** </杂项逻辑> */
  },
  created() {
    this.onInitialize();
    this.onCheckRepeatProp();
    this.onInitFormProgress();

    if (this.form) {
      this.FormRules = this.getFormRules();
    } else {
      if (this.omsMainForm && !this.omsMainForm.fields.includes(this)) {
        // 将当前FormItem加入Fields列中
        this.omsMainForm.fields.push(this);
      }
    }
  },
  beforeDestroy() {
    if (!this.form) {
      if (this.omsMainForm && this.omsMainForm.fields.includes(this)) {
        this.omsMainForm.fields.splice(this.omsMainForm.fields.indexOf(this), 1);
      }
    }
  },
  computed: {
    EffectClassComputed() {
      return [this.effect === "OMS" ? "oms-item_popper_popper-style" : ""].join(",");
    },
    RequiredAlignmentsComputed() {
      return this.requiredAlignments || this.omsItem.RequiredAlignmentsComputed;
    },
    ParentLabelPosition() {
      return this.labelPosition || this.omsItem?.ParentLabelPosition || "";
    },
    IsForm() {
      return this.form || this.$vnode?.componentOptions?.tag === "oms-form" || this.$options.name === "OmsForm";
    },
    DisabledComputed() {
      if (typeof this.disabled === "boolean") {
        return this.disabled;
      }

      return this.omsItem?.disabled || false;
    },
    ToukDisabledComputed() {
      if (typeof this.toukDisabled === "boolean") {
        return this.toukDisabled;
      }

      return this.disabled || false;
    },
    PropInitializeOptionsComputed() {
      const monitorOptionsProxy = this.monitorOptionsProxy ?? (val => val);
      let list = monitorOptionsProxy(this.PropInitialize.options);
      return Array.isArray(list) ? list : [];
    },
    // 判别options的来源
    OptionSourceType() {
      return {
        isAirStation: this.options === "airStation",
        isCurrency: this.options === "currcd",
        isTruckIn: this.options === "truckIn",
        isTruckOut: this.options === "truckOut",
        isStation: this.options === "station",
        isSeaPort: this.options === "seaPort",
        isTransPort: this.options === "transPort",
        isCity: this.options === "city",
        isSaler: this.options === "saler",
        isCargoKind: this.options === "货物大类",
        isLoadCon: this.options === "箱型箱量",
        isHsCode: this.options === "海关编号",
        // 航司本质也是订舱代理
        isCarrier: ["航司"].includes(this.options),
        isSeaComp: ["船东"].includes(this.options),
        isNormalList: Array.isArray(this.options),
      };
    },
    CurrentListeners() {
      let otherListeners = {};
      let change = this.$listeners.change;

      // 处理选择类组件时
      if (this.TypeComputed.isSelect) {

        // 处理销售员数据
        if (this.OptionSourceType.isSaler) {
          otherListeners.change = (...args) => {
            this.onChangeSaler(...args);
            change?.(...args);
          };
        }
        // 处理半公共选择栏信息
        else if (
            this.OptionSourceType.isStation ||
            this.OptionSourceType.isCity ||
            this.OptionSourceType.isAirStation ||
            this.OptionSourceType.isSeaPort ||
            this.OptionSourceType.isTransPort ||
            this.OptionSourceType.isSeaComp ||
            this.OptionSourceType.isCarrier
        ) {
          otherListeners.change = (code) => {
            let multiple = this.CurrentProps.multiple ?? false;
            // this.onProgressDependent(code);
            if(multiple !== false){
              change?.(this.PropInitialize.options.filter(x => code.includes(x.value)));
            } else {
              change?.(this.PropInitialize.options.find(x => x.value === code));
            }
          };
        }
      }
      // 处理二级树时
      else if (this.TypeComputed.isCascader) {
        let change = this.$listeners.change;

        // 这些逻辑其实还要进行二次抽离的，不过运达人体量不大就无所谓全集中一起处理了。
        if (this.OptionSourceType.isCargoKind) {
          otherListeners.change = (...args) => {
            const [pid, id] = args[0];
            const item = this.PropInitializeOptionsComputed.find(x => x.children.find(n => n.Id === id));
            const pItem = this.PropInitializeOptionsComputed.find(x => x.Id === pid);

            this.onProgressDependent(args[0]);
            change?.([item, pItem]);
          };
        }
      }
      // 处理标签输入时
      else if (this.TypeComputed.isTagInput) {
        otherListeners.change = (code) => {
          const formItem = this.$refs.OMSBaseCol.getFormItem();

          if (formItem) {
            formItem.validate();
          }

          change?.(code);
        };
      }

      return Object.assign({}, this.$listeners, otherListeners);
    },
    DefaultToukProp() {
      if (this.TypeComputed.isCurrcd) {
        return "currcd";
      }

      return "defaultProp";
    },
    PathProps() {
      // 向上查询止步于Form
      if (this.omsMainForm === this) {
        return [];
      }

      if (this.ignorePropExtend) {
        return [this.prop];
      }

      if (!this.prop) return [this.omsItem?.PathProps].filter(Boolean);

      let props = [this.omsItem?.PathProps, this.prop.split(".")].flat(Infinity).filter(Boolean);

      if (this.ignoreProps?.length > 0) {
        props = props.filter(x => !this.ignoreProps.includes(x));
      }

      return props;
    },
    ToukPathProps() {
      let toukProp = (this.toukProp || this.prop) ?? "";

      // 向上查询止步于Form
      if (this.omsMainForm === this) {
        return [];
      }

      if (this.ignorePropExtend) {
        return [this.prop];
      }

      if (!this.prop && !this.toukProp) return [this.omsItem?.ToukPathProps].filter(Boolean);

      // 如果是容器下指定的prop，则可以确认是可能存在来自于子集ToukProp的联动组合，此刻仅只能指定当前节点的prop
      /*if (this.row) {
        toukProp = toukProp ?? this.prop;
      }*/

      let props = [this.omsItem?.ToukPathProps || this.omsItem.PathProps, toukProp?.split?.(".") ?? []].flat(Infinity).filter(Boolean);

      if (this.ignoreProps?.length > 0) {
        props = props.filter(x => !this.ignoreProps.includes(x));
      }

      return props;
    },
    ToukDynamicModel: {
      set(val) {
        let lastIndex = this.ToukPathProps.length - 1;
        let originValue = null;

        if (!this.closeToukProxy && this.ExtendDependent && this.toukProp) {
          let model = this.ExtendDependent;

          this.ToukPathProps.forEach((prop, index) => {
            if (index === lastIndex) {
              originValue = model[prop];
                // 基于个别Element组件（如InputNumber）下配合Computed使用会反复触发set，故需做额外的判定处理
              if (model[prop] === val) return;
              let numProp = parseInt(prop);
              if (typeof numProp === "number" && !isNaN(numProp)) {
                this.$set(model, prop, val);
              } else {
                const {get: getter,} = Object.getOwnPropertyDescriptor(model, prop) || {};
                if (!model.hasOwnProperty(prop) || !getter || (getter.name !== "reactiveGetter" && getter.name !== "get")) {
                  delete model[prop];
                  this.$set(model, prop, val);
                } else {
                  model[prop] = val;
                }
              }
            } else {
              model = model[prop];
            }
          });
        } else {
          this.ToukModel = val;
        }

      },
      get() {
        if (!this.closeToukProxy && this.ExtendDependent && this.toukProp) {

          let model = this.ExtendDependent ?? this.ToukModel;
          let props = this.ToukPathProps.length > 0 ? this.ToukPathProps : [this.DefaultToukProp];

          return props.reduce((model, prop) => {
            if (!model) {
              if (!isNaN(parseInt(prop))) {
                model = [];
              } else {
                model = {};
              }
            }

            return model[prop];
          }, model);
        }

        return this.ToukModel;
      }
    },
    DynamicModel: {
      set(val) {
        let model = this.ExtendDependent;
        let prop = this.prop;
        let setValue, originValue = null;

        if (!this.closeProxy && this.ExtendDependent && prop) {
          let lastIndex = this.PathProps.length - 1;

          this.PathProps.forEach((prop, index) => {
            if (index === lastIndex) {
              originValue = model[prop];
              setValue = () => {
                // 基于个别Element组件（如InputNumber）下配合Computed使用会反复触发set，故需做额外的判定处理
                if (model[prop] === val) return;

                let numProp = parseInt(prop);
                if (typeof numProp === "number" && !isNaN(numProp)) {
                  this.$set(model, prop, val);
                } else {
                  const {get: getter,} = Object.getOwnPropertyDescriptor(model, prop) || {};
                  if (!model.hasOwnProperty(prop) || !getter || (getter.name !== "reactiveGetter" && getter.name !== "get")) {
                    delete model[prop];
                    this.$set(model, prop, val);
                  } else {
                    model[prop] = val;
                  }
                }
              };
            } else {
              model = model[prop];
            }
          });
        } else {
          setValue = () => this.viewModel = val;
        }

        if (this.TypeComputed.isDateRange) {
          if (typeof originValue === "string") {
            val = val.join(",");
          }
        } else if (this.TypeComputed.isTagInput) {
          if (Array.isArray(val)) {
            val = val.join(",");
          }
        } else if (this.TypeComputed.isSelect && this.multiple) {
          if (Array.isArray(val)) {
            val = val.join(",");
          }
        }

        setValue();
        this.$emit("change", val);
      },
      get() {
        let model = this.ExtendDependent;
        let prop = this.prop;

        let value;

        // 自带prop和dependent时，直接取下标。视情况看追加prop进行动态处理
        // 针对复杂层级的问题可以考虑用平铺字段的形式来处理
        if (!this.closeProxy && this.ExtendDependent && prop) {
          // const lastProp = this.PathProps.slice(-1).pop();
          try {
            value = this.PathProps.reduce((model, prop) => {
              // todo: 原先想试试自动扩展对象，但发现逻辑会很复杂
              /*if (model[prop] === null || model[prop] === undefined && lastProp !== prop) {
                if (Array.isArray(model)) {
                  model.splice(+prop, 0, {});
                } else {
                  this.$set(model, prop, {});
                }
              }*/
              return model[prop];
            }, model);
          } catch (e) {
            console.log("取值异常-Props列:", this.PathProps);
          }
        } else {
          value = this.viewModel;
        }

        if (this.TypeComputed.isDateRange) {
          if (Array.isArray(value)) {
            return value;
          } else if (typeof value === "string") {
            try {
              return JSON.stringify(value) ?? [];
            } catch (e) {
              return value.split(",").filter(Boolean);
            }
          }
        } else if (this.TypeComputed.isTagInput) {
          if (typeof value === "string") {
            value = value.split(",").filter(Boolean);
          }
        } else if (this.TypeComputed.isSelect && this.multiple) {
          if (typeof value === "string") {
            value = value.split(",").filter(Boolean);
          }
        }

        if(this.multiple && typeof value === 'string'){
          return value.split(',');
        }
        return value;
      }
    },
    LabelWidthExtend() {
      if (this.labelWidth === false || this.labelWidth === "false") {
        return false;
      }

      return this.labelWidth || this.omsItem.LabelWidthExtend;
    },
    GetCurrentComponentKeys() {
      let model = [];
      const createProps = (prop) => ({props: prop});

      if (this.TypeComputed.isDate) {
        model.push(Element.DatePicker);
      } else if (this.TypeComputed.isInput) {
        model.push(Element.Input);
      } else if (this.TypeComputed.isNumber) {
        model.push(Element.InputNumber);
      }
      // 选择框
      else if (this.TypeComputed.isSelect) {
        model.push(Element.Select);
        model.push(createProps({
          blurInput: true,
        }));
      } else if (this.TypeComputed.isTextarea) {
        model.push(Element.Input);
      } else if (this.TypeComputed.isTextarea) {
        model.push(Element.Input);
      } else if (this.TypeComputed.isCheckbox) {
        model.push(Element.Checkbox);
      } else if (this.TypeComputed.isTagInput) {
        model.push({
          props: ["placeholder"]
        });
      } else if (this.form) {
        model.push(Element.Form);
        model.push(Element.Row);
      } else if (this.row) {
        model.push(Element.Row);
        model.push(Element.Col);
      }

      return Array.from(new Set(model.map(x => Object.keys(x.props)).flat(Infinity)));
    },
    GetFormatType() {
      let formatType = this.$attrs["valueFormat"] || this.$attrs["value-format"] || "yyyy-MM-dd";
      let [date, time] = formatType.split(" ");

      if (time) {
        let [hour, minute, second] = time.split(":");

        time = [hour?.toUpperCase(), minute?.toLowerCase(), second?.toLowerCase()].filter(Boolean).join(":");
      }

      return {
        useMoment: [date.toUpperCase(), time].filter(Boolean).join(" "),
        useElement: formatType,
      };
    },
    PickerOptionsBegin() {
      let options = this.options;
      if(typeof options !== 'object'){
        options = {};
      }

      return {
        dynamic: {
          ...options,
          disabledDate: time => {
            let result = false;
            if (this.start) {
              let format = moment(this.CurrentProps.start).format(this.GetFormatType.useMoment);
              result = result || time.getTime() < moment(format).toDate().getTime();
            }

            if (this.end) {
              let formatType = this.GetFormatType.useMoment;
              let format = moment(this.CurrentProps.start).format(formatType);
              result = result || time.getTime() > moment(format).toDate().getTime();
            }

            return result;
          },
        },
        start: {
          disabledDate: time => {
            let [start, end] = this.DynamicModel;

            if (time && end) {
              return time.getTime() > new Date(end).getTime();
            }
          },
        },
        end: {
          disabledDate: time => {
            let [start, end] = this.DynamicModel;

            if (time && start) {
              return time.getTime() < new Date(start).getTime();
            }
          },
        },
      };
    },
    ItemSpanComputed() {
      return this.getExtendProp("itemSpan");
    },
    SpanComputed() {
      return this.getExtendProp("span");
    },
    ExtendDependent() {
      return this.getExtendProp("dependent");
    },
    ItemClassList() {
      return [this.itemClass];
    },
    BaseColCurrentProps() {
      let other = {};

      if (this.RequiredAlignmentsComputed) {
        other.requiredSpace = true;
      }

      return Object.assign({
        className: `oms-item-base-col__${this.effect}`,
      }, this.CurrentProps, other);
    },
    CurrentProps() {
      let allProps = this.$attrs;
      let currentProps = {};
      this.GetCurrentComponentKeys.forEach(key => currentProps[key] = allProps[key]);
      currentProps = {
        ...allProps,
        ...this.$props,
      };

      currentProps.class = [this.$props["class"], this.UIComponentCSS];
      currentProps.required = this.getExtendProp("required");
      if (currentProps.hasOwnProperty("clearable")) {
        currentProps.clearable = currentProps.clearable !== undefined && currentProps.clearable !== null && currentProps.clearable !== false;
      }

      // 处理来自TypeComputed的属性判定
      if (this.TypeComputed.isCheckbox) {
        currentProps.label = "";
      } else if (this.TypeComputed.isCascader) {
        if (this.OptionSourceType.isCargoKind) {
          currentProps.props = {
            value: "Id",
            label: "CategoryNameKey"
          };
        }
      } else if (this.OptionSourceType.isHsCode) {
        currentProps.options = undefined;
        currentProps.api = this.API.Options.GetHsCode;
        currentProps.apiCallback = resp => {
          // 判断resp.Items是否为数组
          if (!Array.isArray(resp.Items)) {
            resp.Items = [];
          }

          resp.Items = resp.Items.map(x => Object.assign(x, {
            label: x.Name,
            value: x.Code,
          }));
          return resp;
        };
        currentProps.remote = true;
      }

      // 处理非Form表单和Container容器时
      if (!this.form && !this.row) {
        /** 留存处理required样式自带的※占位，该父级元素存在space时且当下组件的required为false时，
         * 或指定当组件required为space时， 则为必填项※留存占位 */

        let parentPropRequired = this.omsItem.getExtendProp?.("required");
        if (currentProps.prop) {
          currentProps.prop = this.PathProps.join(".");
        }
        currentProps.requiredSpace = this.required === "space" || (this.required === false && parentPropRequired === "space");
      } else if (this.form) {
        // 仅指定当前节点的dependent
        delete currentProps.rules;
        currentProps.validateOnRuleChange = false;
        // todo: 可以考虑将所有子Form/Container/Item下的dependent进行merge （但还需要考虑可能存在字段冲突）
        // 暂定做法： 采取多表联动校验的方式，每个表单共享主表单的Dependent
        currentProps.model = currentProps.model ?? this.dependent ?? this.omsMainForm.dependent;
        if (Array.isArray(currentProps.model)) {
          let local = {};

          // 转化Array为Object，潜在bug未知
          currentProps.model.forEach((val, index) => {
            local[index] = val;
          });

          currentProps.model = local;
        }
      }

      if (!this.form) {
        // 消除vue-warn
        currentProps.form = undefined;
      }

      /*if (this.ruleProp && this.FormRules[this.ruleProp]) {
        currentProps.rules = this.FormRules[this.ruleProp];
      }*/

      if (this.omsMainForm.autoTranslate || this.autoTranslate) {
        currentProps._LabelKey = currentProps._LabelKey || this.onFindLabelI18nKey(currentProps.label);
        currentProps._PlaceholderKey = currentProps._PlaceholderKey || this.onFindLabelI18nKey(currentProps.placeholder);
        if (currentProps._LabelKey) {
          currentProps.label = this.$t(currentProps._LabelKey);
        }
        if (currentProps._PlaceholderKey) {
          currentProps.placeholder = this.$t(currentProps._PlaceholderKey);
        }

        currentProps.ruleTranslateKey = this.omsMainForm.ruleTranslateKey || this.ruleTranslateKey;
        currentProps.autoTranslate = this.omsMainForm.autoTranslate || this.autoTranslate;
      }

      currentProps.disabled = this.DisabledComputed;
      currentProps.labelWidth = this.labelWidth;
      return currentProps;
    },
    UIComponentCSS() {
      // 新版组件整体风格样式
      if (this.uiVersion === "2.0") {
        return "ui-component__second-version";
      }
    },
    isRu(){
        return this.$i18n.locale === 'ru'
    },
    isEn(){
        return this.$i18n.locale === 'en'
    },
  },
  props: {
    ignoreProps: Array,
    requiredAlignments: Boolean,
    multiple: Boolean,
    // 风格
    effect: {type: String, default: "OMS"},
    // 自动翻译（仅适用于OmsButton）（下次再补功能）
    autoTranslate: Boolean,
    // 避免自动翻译在反查时查到同一字词，以此添加前缀进行过滤
    ruleTranslateKey: String,
    disabled: {
      type: [Boolean, String],
      default() {
        if (this.$vnode?.componentOptions?.tag === "oms-form") {
          return false;
        }

        return "";
      },
    },
    rules: Object,
    // 是否开启自动触发
    manualTrigger: {type: Boolean, default: true},
    toukDisabled: {
      type: [String, Boolean],
      default() {
        return "";
      },
    },
    model: Object,
    // 是否针对个别性能开启虚拟化（目前仅针对select有效）
    virtual: Boolean,
    required: {type: [Object, String, Boolean], default: undefined},
    log: Boolean,
    closeProxy: Boolean,
    closeToukProxy: Boolean,
    itemClass: String,
    // ruleProp: String,
    toukProp: String,
    dependent: [Object, Array],
    options: [String, Object, Array],
    monitorOptionsProxy: [Function],
    // 不继承父级Prop
    ignorePropExtend: Boolean,
    prop: String,
    position: String,
    // 默认input类型
    inline: Boolean,
    label: String,
    labelWidth: {
      type: [String, Boolean], default: () => "",
    },
    // 分行Label
    labelPosition: String,
    type: {type: String, default: "input"},
    itemSpan: [String, Number],
    span: [Number, String],
    // 类ElForm
    form: {
      type: Boolean,
      default() {
        return this.$vnode?.componentOptions?.tag === "oms-form";
      },
    },
    // 类ElRow
    row: {
      type: Boolean,
      default() {
        return this.$vnode?.componentOptions?.tag === "oms-container";
      },
    },
    // ui版本 - 2.0
    uiVersion: String,
    value: {
      type: [Object, String, Boolean, Number, Array],
      default() {
        return undefined;
      }
    },
  },
  provide() {
    return {
      omsItem: this,
      omsForm: this.form ? this : this.omsForm,
      omsMainForm: this.TypeComputed.isMainForm ? this : this.omsMainForm,
    };
  },
  inject: {
    omsItem: {
      default: "",
    },
    omsForm: {
      default: "",
    },
    omsMainForm: {
      default: "",
    }
  },
};
</script>

<style lang="scss">
.oms-form-extend {
  /** 重写Element-UI的样式覆盖，存在嵌入使用引发的样式bug，通过覆盖解决 */
  /** 禁止在此处追加其他无关样式 */
  &.el-form--label-top .el-form-item__label {
    float: none;
    text-align: left;
    display: inline-block;

    &.flex {
      display: flex;
    }

    &:not([class*="pb-"]):not([class*="p-"]) {
      padding-bottom: 10px;
    }
  }

  /*
  .el-form-item__label {
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 14px;
      color: #606266;
      line-height: 40px;
      padding: 0 12px 0 0;
      box-sizing: border-box;
  }
  */
  &:not(.el-form--label-top) {
    //:not(.el-form--label-right)
    &, &.oms-form-extend {
      .el-form-item__label {
        text-align: right;
        vertical-align: middle;
        float: left;
        font-size: 14px;
        color: #606266;
        line-height: 30px;
        height: 30px;

        &:not([class*="p-"]):not([class*="pr-"]) {
          padding-right: 12px;
        }

        &:not([class*="pb-"]):not([class*="p-"]) {
          padding-bottom: 0;
        }

        box-sizing: border-box;
      }

      .el-form-item__content {
        line-height: 32px;
        position: relative;
        font-size: 14px;
      }
    }
  }

  &:not(.el-form--label-top).el-form--label-left {
    //:not(.el-form--label-right)
    &, &.oms-form-extend {
      .el-form-item__label {
        text-align: left;
      }
    }
  }

  .phone-container-row {
    display: flex;

    .item-1 {
      flex-grow: 1;
      flex-basis: 120px;
    }

    .item-2 {
      flex-grow: 4;
    }
  }

  // 解决不同组件之间高度不一的bug(比如个别组件由于默认vertical-align是baseline,导致高度不一)

  .el-form-item__content {
    > .el-input:not(.el-input-group), > .el-select, > .el-input-number {
      display: block;
      vertical-align: top;
    }
  }

  .oms-item_effect_radio-select-container {
    margin-top: -1px;
    box-sizing: border-box;
  }
}

.oms-item-base-col__OMS {
  .el-form-item__content {
    // 将所有line-height默认为合理的均高
    line-height: 1;

    // 将所有line-height默认为合理的均高
    .el-cascader {
      line-height: 1;
    }

    .el-input__suffix {
      line-height: 1;
      display: flex;
      align-items: center;
      min-width: 25px;
      justify-content: center;
    }

    .el-checkbox {
      border-radius: 3px;
      border: 1px solid #DCDFE6;
      background: #fff;
      box-sizing: border-box;
      min-width: 120px;
      width: fit-content;
      display: flex;
      align-items: center;
      height: 40px;
      justify-content: center;
      transition: .2s linear;

      .el-checkbox__input {
        display: none;
      }

      .el-checkbox__label {
        padding: 0;
      }

      &.is-disabled {
        cursor: not-allowed;
      }

      &.is-checked {
        span {
          color: #1C57AF;
        }

        .el-checkbox__input.is-disabled + span.el-checkbox__label {
          color: #1C57AF;
        }

        background: #F4F9FF;
        border: 1px solid #1C57AF;
      }

      .yeah {
        position: absolute;
        right: 3px;
        bottom: 3px;
      }

      &:hover{
        border-color: #1C57AF;
      }
    }

    .el-radio {
      $COMMON_RADIO_WIDTH: 126px;
      border-radius: 3px;
      height: 40px;
      box-sizing: border-box;
      border: 1px solid #DCDFE6;
      min-width: $COMMON_RADIO_WIDTH;
      text-align: center;
      transition: .2s linear;

      .el-radio__input {
        display: none;
      }

      .el-radio__label {
        line-height: 40px;
        padding: 0;
      }

      .el-select {
        width: $COMMON_RADIO_WIDTH - 2px;

        input {
          border: none;
          line-height: 38px;
          height: 38px;
          background: transparent;
          padding: 0 0 0 20px;
        }
      }

      // 字体控制
      .el-select input, .el-radio__label {
        color: #aaa;
        font-size: 14px;
      }

      &.is-checked {
        background: #EDF4FF;

        .el-radio__label, .el-select input {
          color: #333;
        }
      }

      .el-radio__input.is-checked + .el-radio__label {
      }

      &:hover{
        background-color: #EDF4FF;
      }
    }

    .el-textarea {
      font-size: 16px;

      .el-textarea__inner {
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
  }

  .oms-item_effect_select__select-container {
    .el-select {
      max-width: 150px;
    }
    .car-type-select{
      width: 160px;
    }
  }
}
</style>
