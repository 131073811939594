<template>
  <div :class="{ 'aside-menu-wrap': true, 'top-0': isScroll }">
    <el-menu :default-active="defaultActive"
      :class="{ 'aside-menu-container': true, 'show-shadow': showShadow, 'menu-long': isRu || isEn }"
      @select="handleSelect" :collapse="isCollapse && collapseAble" @mouseenter.native="expandMenu"
      @mouseleave.native="collapseMenu" ref="ASIDEMENU">
      <component v-for="(menuItem, i) in menuConfig" :key="i"
        :is="menuItem.subMenu && menuItem.subMenu.length > 0 ? 'el-submenu' : 'el-menu-item'" :index="menuItem.index">
        <template slot="title">
          <img class="menu-logo" v-if="menuItem.icon" :src="IMG_SRC(menuItem)" />
          <span> {{ $t_reserve(menuItem.name) }} </span>
        </template>
        <component v-for="(subMenuItem, x) in menuItem.subMenu"
          :is="subMenuItem.subMenu && subMenuItem.subMenu.length > 0 ? 'el-submenu' :'el-menu-item'"
          @click="subMenuItem.subMenu ? null : onSelectMenu(subMenuItem)" :key="x" :index="subMenuItem.index">
          <img class="menu-logo" v-if="subMenuItem.icon && !subMenuItem.subMenu" :src="subMenuItem.icon" />
          <template slot="title">
            <img class="menu-logo" v-if="subMenuItem.icon && subMenuItem.subMenu" :src="subMenuItem.icon" />
            <span>{{ $t_reserve(subMenuItem.name) }} </span>
          </template>
          <el-menu-item v-for="(subMenuItem2, x2) in subMenuItem.subMenu" @click="onSelectMenu(subMenuItem2)" :key="x2"
            :index="subMenuItem2.index">
            <img class="menu-logo" v-if="subMenuItem2.icon" :src="subMenuItem2.icon" />
            <template slot="title">
              <span>{{ $t_reserve(subMenuItem2.name) }} </span>
            </template>
          </el-menu-item>
        </component>
      </component>
    </el-menu>
  </div>
</template>

<script>
import _ from 'lodash'
import { i18n } from '@/app'

export default {
  name: 'AsideMenu',
  props: {
    menuConfig: {
      type: Array,
      default: () => [
        {
          name: '我的订单',
          index: '/allList',
          icon: 'menu-myOrder-icon',
          subMenu: [
            {
              name: '订单列表',
              index: 'myOrder/allList',
              route: 'AllBookingList',
            },
          ]
        },
        {
          name: 'TestKey.RailwayService',
          index: '/railway',
          icon: 'menu-railway-icon',
          subMenu: [
            {
              name: 'TestKey.BookingRequest',
              index: 'booking/railway',
              route: 'UserOrderBookingQuery',
              type: 'railway'
            },
            { name: 'TestKey.MyJobOrder', index: 'workOrder/railway', route: 'WorkOrderQuery', type: 'railway' }
          ]
        },
        {
          name: 'TestKey.TruckService',
          index: '/truck',
          icon: 'menu-truck-icon',
          subMenu: [
            {
              name: 'TestKey.BookingRequest',
              index: 'booking/truck',
              route: 'UserOrderBookingQuery',
              type: 'truck'
            },
            { name: 'TestKey.MyJobOrder', index: 'workOrder/truck', route: 'WorkOrderQuery', type: 'truck' }
          ]
        },
        {
          name: 'TestKey.AirService',
          index: '/airport',
          icon: 'menu-airport-icon',
          subMenu: [
            {
              name: 'TestKey.BookingRequest',
              index: 'booking/airport',
              route: 'UserOrderBookingQuery',
              type: 'airport'
            },
            { name: 'TestKey.MyJobOrder', index: 'workOrder/airport', route: 'WorkOrderQuery', type: 'airport' }
          ]
        },
        {
          name: 'TestKey.SeaService',
          index: '/ship',
          icon: 'menu-ship-icon',
          subMenu: [
            {
              name: 'TestKey.BookingRequest',
              index: 'booking/ship',
              route: 'UserOrderBookingQuery',
              type: 'ship'
            },
            { name: 'TestKey.MyJobOrder', index: 'workOrder/ship', route: 'WorkOrderQuery', type: 'ship' }
          ]
        },
        {
          name: 'TestKey.MultimodalTransport',
          index: '/multiTrain',
          icon: 'menu-multi-icon',
          subMenu: [
            {
              name: 'TestKey.BookingRequest',
              index: 'booking/multiTrain',
              route: 'UserOrderBookingQuery',
              type: 'multiTrain'
            },
            {
              name: 'TestKey.MyJobOrder',
              index: 'workOrder/multiTrain',
              route: 'WorkOrderQuery',
              type: 'multiTrain'
            }
          ]
        },
        {
          name: 'TestKey.CommonServices',
          index: '/common',
          icon: 'menu-common-icon',
          subMenu: [
            { name: 'TestKey.MyBill', index: '/bill', route: 'MyBill', type: 'bill' },
            {
              name: 'TestKey.InvoiceManagement',
              index: '/invoice', // unopen/common/invoice
              route: 'InvoiceManage',//InvoiceManage
              type: 'invoice'
            },
            { name: 'TestKey.MyToDoList', index: '/todo', route: 'WorkOrderTodo' },
            {
              name: 'TestKey.SystemAnnouncement',
              index: '/notice', //unopen/common/notice
              route: 'WorkOrderNotice',
              type: 'notice',
            },
            {
              name: 'TestKey.MyFavorites',
              index: '/collect',
              route: 'MyCollect',
            },
            
            { name: 'TestKey.ShipmentTracking', index: 'freight/common/query', route: 'UserOrderFreightQuery' },
            {
              name: '账户信息',
              index: '/account',
              route: 'UserInformation',
              type: 'account',

            },
            {
              name: '企业认证',
              index: '/business',
              route: 'BusinessCheck',
              type: 'business',
            },
            {
              name: '安全设置',
              index: '/accountSafe',
              route: 'AccountSafe',
              type: 'accountSafe',
            },
            // { name: `我的地址`, index: '6-8', route: 'UnOpenPage' }
          ]
        }
      ]
    },
    collapseAble: {
      type: Boolean,
      default: true
    },
    scroll: {
      type: Boolean,
      default: true
    },
    defaultActive: {
      type: String,
      default: ''
    },
    showShadow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isCollapse: true,
      nowPath: [],
      isScroll: false,
      // defaultActive: '',
      activeIndex: this.defaultActive,
      i18n
    }
  },
  mounted() {
    if (this.scroll) {
      window.addEventListener('scroll', _.throttle(this.handleScroll, 50)) // 监听页面滚动
    }
  },
  methods: {
    handleOpen() {},
    handleClose() {},
    handleSelect(key, keyPath) {
      this.nowPath = keyPath
    },
    onSelectMenu(item) {
      // 子页面点击
      // if (
      //   this.$route.params?.type === item?.type &&
      //   !this.$route.params?.trafficCd &&
      //   item?.route === this.$route?.name
      // ) {
      //   return
      // }
      // 同个页面点击
      if ((this.$route.name === item.route || !item.route) && this.$route.path.includes(item.index)) {
        return (this.$refs.ASIDEMENU.activeIndex = this.activeIndex = item.index)
      }
      this.$router.push({
        name: item?.route,
        params: {
          type: item?.type,
          trafficCd:item?.trafficCd
        }
      })
    },
    expandMenu() {
      this.isCollapse = false
    },
    collapseMenu() {
      this.isCollapse = true
    },
    handleScroll(e) {
      if (document.documentElement.scrollTop > 20) {
        this.isScroll = true
      } else {
        this.isScroll = false
      }
    }
  },
  computed: {
    IMG_SRC() {
      return (menuItem) => {
        if (!menuItem?.icon) return ''
        return menuItem?.index === this.nowPath?.[0]
          ? require(`@/assets/images/${menuItem?.icon}-checked@2x.png`)
          : require(`@/assets/images/${menuItem?.icon}@2x.png`)
      }
    },
    isRu() {
      return this.$i18n.locale === 'ru'
    },
    isEn() {
      return this.$i18n.locale === 'en'
    }
  },
  watch: {
    $route: {
      handler: function (to, from) {
        const routeArr = to.path.split('/')
        const routeInfo = routeArr.slice(3, routeArr?.length)
        if(['UserInformation','BusinessCheck','AccountSafe'].includes(to.name)){
          return this.nowPath = ['/common']
        }
        this.nowPath = [`/${routeInfo[1]}`]

      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
.aside-menu-wrap {
  &.top-0 {
    top: 0;
  }

  position: fixed;
  top: 72px;
  left: 0;
  z-index: 999;

  .aside-menu-container {
    background: #fff;
    width: 80px;
    height: 100vh;
  }

  .aside-menu-container:not(.el-menu--collapse) {
    width: 232px;
    min-height: 400px;
    // box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
  }

  .show-shadow:not(.el-menu--collapse) {
    box-shadow: 0px 15px 14px 3px rgba(0, 0, 0, 0.06);
  }

  ::v-deep .el-menu {
    border-right: none;
    max-height: max-content;
    // max-height: 92vh;
    overflow-y: auto;
  }
}

.menu-logo {
  position: relative;
  top: 1px;
  height: 18px;
  width: 18px;
  margin-right: 8px;
}

::v-deep {
  .menu-long {
    .el-submenu__title {
      display: flex;
      align-items: center;
    }

    .el-submenu__title span {
      white-space: normal;
      word-break: break-all;
      line-height: 20px;
      flex: 1;
      padding-right: 20px;
      font-size: 14px;
    }

    .el-menu-item {
      display: flex;
      align-items: center;
      padding-right: 20px !important;
    }

    .el-menu-item span {
      white-space: normal;
      word-break: break-all;
      line-height: 20px;
      flex: 1;
      font-size: 14px;
    }
  }

  .el-submenu.is-active {
    .el-submenu__title i {
      color: #fff;
    }
  }

  .el-submenu__title {
    padding-left: 30px !important;
    display: flex;
    align-items: center;
    height: 54px;
    font-size: 16px;
    line-height: 54px;
    vertical-align: baseline;
  }

  .el-menu-item {
    height: 54px;
    font-size: 16px;
    line-height: 51px;

    // margin-left: 10px;
    span {
      margin-left: 10px;
    }
  }

  .el-submenu.is-active {
    transition: all 0.3s;
    background: #3870c4;

    .el-submenu__title:hover {
      background: #3870c4;
      color: #fff;
    }

    .el-submenu__title span {
      color: #fff;
      vertical-align: baseline;
    }
  }
}
</style>
