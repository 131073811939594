<template>
  <oms-select v-model="selectData" @change="onChange" v-bind="$attrs" :popper-class="popperClass">
    <el-option v-for="(item,index) in dataOptions" :key="index"
               class="option-currency"
               :value="item[optionValue]">
      <span :style="{
        float: 'left',
        maxWidth: '80px',
        wordBreak: 'break-word',
        paddingRight:'10px',
      }" v-if="$i18n.locale != 'en'">{{
          item[labelValue]
        }}</span>
      <span v-if="labelValue !== optionValue"
            style="max-width:80px;word-break: break-word;float: right; color: #8492a6; font-size: 13px">
        {{ item[optionValue] }}
      </span>
    </el-option>
  </oms-select>
</template>

<script>
import {getCurrencyOptions} from "@/api/options";

export default {
  name: "Currency",
  model: {
    prop: "value",
    event: "change-value",
  },
  props: {
    value: String,
    popperClass: String,
  },
  data() {
    return {
      selectData: this.value,
      dataOptions: [],
    };
  },
  computed: {
    labelValue() {
      if (this.$i18n.locale == "zh-CN") {
        return "CurrName";
      } else if (this.$i18n.locale == "zh-TW") {
        return "CurrNameCht";
      } else if (this.$i18n.locale == "en") {
        return "CurrNameEng";
      } else if (this.$i18n.locale == "ru") {
        return "CurrNameRus";
      }
    },
    optionValue() {
      return "CurrCd";
    }
  },
  async created() {
    this.dataOptions = await getCurrencyOptions();
    if (!Array.isArray(this.dataOptions)) {
      this.dataOptions = [];
    }
  },
  watch: {
    value(val) {
      this.selectData = val;
    },
    selectData(val) {
      this.$emit("change-value", val);
      // this.onUpdate();
    },
    "$i18n.locale"(val) {
    },
  },
  methods: {
    onChange(e) {
      this.selectData = e;
      this.$emit("change", e);
      this.$emit("change-value", e);
    },
    /*   onUpdate() {
         const ev = this.selectData;
         const model = this.dataOptions.find(x => x[this.optionValue] === ev);

         if (model) {
           this.$emit("change-value", model[this.optionValue]);
         }

         return model;
       },*/
  },
};
</script>
<style lang="scss" scoped>
.option-currency {
  height: auto;
  min-height: 34px;
  line-height: 18px;
  padding-top: 8px;
  padding-bottom: 8px;

  white-space: pre-wrap;
  word-break: break-word;

}
</style>
