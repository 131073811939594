<script>
import Element               from "element-ui";
import {isEqual, isFunction} from "lodash";

export default {
  name: "ElCascaderExtend",
  extends: Element.Cascader,
  watch: {
    "$i18n.locale"() {
      this.computePresentText();
    }
  },
  props: {
    filterable: {type: Boolean, default: true},
  },
  methods: {
    getSuggestions() {
      let {filterMethod} = this;

      if (!isFunction(filterMethod)) {
        filterMethod = (node, keyword) => node.text.includes(keyword);
      }

      const suggestions = this.panel.getFlattedNodes(this.leafOnly)
          .filter(node => {
            if (node.isDisabled) return false;
            node.text = (node.getText(this.showAllLevels, '[/]') || "").split('[/]').map(x => this.$t(x)).join(this.separator);
            return filterMethod(node, this.inputValue);
          });

      if (this.multiple) {
        this.presentTags.forEach(tag => {
          tag.hitState = false;
        });
      } else {
        suggestions.forEach(node => {
          node.checked = isEqual(this.checkedValue, node.getValueByOption());
        });
      }

      this.filtering = true;
      this.suggestions = suggestions;
      this.$nextTick(this.updatePopper);
    },
    computePresentText() {
      const {checkedValue, config} = this;
      if (!this.isEmptyValue(checkedValue)) {
        const node = this.panel.getNodeByValue(checkedValue);
        if (node && (config.checkStrictly || node.isLeaf)) {
          this.presentText = node.getText(this.showAllLevels, this.separator).split(this.separator).map(x => this.$t(x)).join(this.separator);
          return;
        }
      }
      this.presentText = null;
    },
  }
};
</script>

<style lang="scss">
.el-popper.el-cascader__dropdown[x-placement="top-start"] {
  transform-origin: center bottom !important;
}
</style>
