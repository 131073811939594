<template>
  <div>
    <div class="flex around center">
      <div :class="`wxwork-login ${this.className||''}`" ref="login"></div>
      <img style="width:200px" src="@/assets/images/login-wx-tip@2x.jpg" alt="">
    </div>
    <div class="wx-login-tip">
      <span>请使用微信扫一扫登录</span>
    </div>
  </div>

</template>

<script>
import {GetWxLoginQrCodeMethodName as wwConfig} from "@/api/wechat";
import {WxLogin}                                from "@/utils/wechat";
// import {config as wwConfig}           from "@/api/wxWork";

const WWLoginState = {
  NONE: 0,
  LOADING: 1,
  EXPIRED: 2,
  OK: 3,
};
export default {
  name: "wwlogin",
  props: {"className": String, "type": String},
  data() {
    return {
      /** @type {Promise<WWLoginType>}*/
      WWLogin: null,
      id: `wwlogin-${Math.random()}`,
      refreshTime: 0,
      state: WWLoginState.NONE,
    };
  },
  created() {
    this.WWLogin = WxLogin;
    // this.WWLogin = window.WwLogin ? Promise.resolve(window.WwLogin) : new Promise((resolve, reject) => {
    //   let scriptElement = document.createElement('script');
    //   scriptElement.src = '';
    //   scriptElement.onload = async () => {
    //     Promise.resolve().then(() => {
    //       if (window.WwLogin) {
    //         resolve(window.WwLogin);
    //       } else {
    //         reject(new Error('企业微信模块加载失败2'));
    //       }
    //     })
    //   };
    //   scriptElement.onerror = () => {
    //     reject(new Error('企业微信模块加载失败'));
    //   };
    //   window.document.body.appendChild(scriptElement);
    // });
  },
  mounted() {
    window.addEventListener("message", this.listener, false);
    this.updateUrl();
  },
  updated() {
    this.updateUrl();
  },
  beforeDestroy() {
    window.removeEventListener("message", this.listener, false);
  },
  methods: {
    /** @param e {MessageEvent<{event:string,data: {code:string, state:string, error:string}}>} */
    listener(e) {
      if (e.data?.event === "wxlogin") {
        let {wxUserId: code, token, errorCode} = e.data.data || {};
        // 有token 直接登录转跳， code的话跟普通登录一样， error直接提示
        if (token) {
          try {
            token = JSON.parse(decodeURIComponent(token));
          } catch (e) {
            token = null;
          }
        }
        if (this.type === "BindWechat") {
          this.$emit("bind-wechat", {code, origin: e.data.data});
          return;
        }
        if (token) {
          this.$emit("login", token);
          return;
        }
        if (errorCode) {
          if (+errorCode === 1) {
            this.$notify({type: "error", duration: 10000, message: this.$t('TestKeyText.TheAccountHasBeenDeactivated'), title: "ERROR"});
          } else {
            this.$notify({type: "error", duration: 10000, message: errorCode, title: "ERROR"});
          }
          this.updateUrl();
          return;
        }
        this.$emit("scanned", code);
      }
    },
    async updateUrl() {
      if (this._timer) {
        clearTimeout(this._timer);
      }
      this._timer = setTimeout(() => {
        this.state = WWLoginState.EXPIRED;
      }, 59 * 5);
      await new Promise(r => this.$nextTick(r));
      this.$refs.login.innerHTML = `<div id='${this.id}'  style='transform: translateY(-70px) scale(0.78);'></div>`;
      this.state = WWLoginState.NONE;
      try {
        // console.log(this.WWLogin)
        let WWLogin = await this.WWLogin;
        let data = await wwConfig();
        let resp = {data};
        // console.log(resp, document.getElementById(this.id));
        new WWLogin({
          id: this.id,
          // state: resp.data.state,
          // agentid: resp.data.agentId,
          // lang: this.$i18n.locale,
          // redirect_uri: encodeURIComponent(resp.data.redirect_uri),
          self_redirect: true,
          ...resp.data,
          // scope: resp.data.scope,
          // appid: resp.data.appid,
          // href: '',
        });
        this.state = WWLoginState.OK;
        this.$emit("init", resp.data);
      } catch (e) {
        this.state = WWLoginState.EXPIRED;
      }
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.wxwork-login {
  display: flex;
  justify-content: center;
  overflow: hidden;
  // transform: translateY(-60px);
  height: 207px;
  width: 207px;
}

.wx-login-tip {
  margin-top: 40px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}
</style>
