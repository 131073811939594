<script>
import Picker  from "./PickerExtend";
import Element from "element-ui";

export default {
  extends: Element.DatePicker,
  mixins: [Picker],
  props: {
    autoWidth: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    pickerVisible(visible) {
      if (visible) {
        this.$nextTick(() => {
          this.onResize();
        });
      }
    }
  },
  mounted() {
    if (this.autoWidth) {
      this.ob = new ResizeObserver(this.onResize);
      this.ob.observe(this.$refs.reference.$el);
    }
  },
  methods: {
    onResize() {
      if (this.pickerVisible && this.picker && this.autoWidth) {
        // 将$picker.el的宽度设置为$reference.el的宽度
        const rect = this.$refs.reference.$el.getBoundingClientRect();
        let width = rect.width > 500 ? 500 : rect.width;
        const td = this.picker.$el.querySelector('table td');
        this.picker.$el.style.width = width + "px";
        this.picker.$el.style.setProperty("--picker-td-width", td.getBoundingClientRect().width);
      }
    }
  },
  beforeDestroy() {
    if (this.ob) {
      this.ob.disconnect();
    }
  }
};
</script>

<style lang="scss">

.el-picker-panel.el-date-picker.el-popper[x-placement="top-start"] {
  transform-origin: center bottom !important;
}
</style>
