import Vue       from "vue";
import Element   from "element-ui";
import {i18n}    from "@/app";
import Cookies   from "js-cookie";
import elementCn from "element-ui/lib/locale/lang/zh-CN";
import elementEn from "element-ui/lib/locale/lang/en";
import elementTw from "element-ui/lib/locale/lang/zh-TW";
import elementRu from "element-ui/lib/locale/lang/ru-RU";

const SUPPORT_LOCALES = ["en", "zh-CN", "zh-TW", "ru"];

let initSetZhCn = true;

Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
});

export function setLanguage(locale){
  let language = {
    "zh-CN": "zh",
    "zh-TW": "tr",
    "en": "en",
    "ru": "ru",
  }[locale];
  Cookies.set("language", language);
  switch (language) {
    case "en":
      language = "c=en-US|uic=en-US";
      break;
    case "zh":
      language = "c=zh-CN|uic=zh-CN";
      break;
    case "tr":
      language = "c=zh-TW|uic=zh-TW";
      break;
    case "ru":
      language = "c=ru-RU|uic=ru-RU";
      break;
    default:
      language = "c=zh-CN|uic=zh-CN";
      break;
  }
  Cookies.set(".AspNetCore.Culture", language);
  Cookies.set("type", 'OMS_PC');
}

export default {
  namespaced: true,
  state: {
    // 为null不写死默认，而是交由互动设置默认
    locale: null,
    langJson: {},
    i18n: null,
  },
  // 同步
  mutations: {
    setupI18n(state, {i18n, locale}) {
      state.i18n = i18n;
      state.locale = locale;
    },
  },
  // 异步
  actions: {
    async INITIALIZE_LANGUAGES({state, commit, dispatch}, defaultLang = "zh-CN") {
      if (state.i18n) {
        return;
      }
      commit("setupI18n", {i18n, locale: defaultLang});
      await dispatch("ASYNC_LOAD_LANG_JSON", defaultLang);
    },
    async ASYNC_LOAD_LANG_JSON({commit, state}, locale) {
      if (!SUPPORT_LOCALES.includes(locale)) {
        locale = "zh-CN";
      }

      setLanguage(locale);
      Promise.all([
        initSetZhCn ? await import(`@/lang/zh-CN.json`) : null,
        await import(`@/lang/${locale}.json`)
      ])
      .then(([zhCN, messages]) => {
        function setLangJson(locale, messages){
          const langJson = messages.default;

          state.i18n.mergeLocaleMessage(locale, {
            ...langJson, ...{
              "zh-CN": elementCn,
              "en": elementEn,
              "zh-TW": elementTw,
              "ru": elementRu,
            }[locale]
          });
          // state.i18n.setLocaleMessage(locale, {
          //   ...langJson, ...{
          //     "zh-CN": elementCn,
          //     "en": elementEn,
          //     "zh-TW": elementTw,
          //     "ru": elementRu,
          //   }[locale]
          // });
          state.i18n.locale = locale;
        }

        if(initSetZhCn){
          setLangJson('zh-CN', zhCN);
          initSetZhCn =false;
        }

        setLangJson(locale,messages);
      });
    },
  },
};
