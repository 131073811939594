/**
 * 用来写一些全局公共方法
 */
import {v4 as uuidv4} from "uuid";
import Vue            from "vue";

Vue.prototype.$guid = () => uuidv4();
Vue.prototype.isNullOrUndefined = function (value) {
  return value === null || value === undefined;
};
window.isNullOrUndefined = function (value) {
  return value === null || value === undefined;
};
window.$CreateGuid = () => uuidv4();
window.JSONExtend = {
  tryParse(str, def) {
    try {
      return JSON.parse(str) ?? def;
    } catch (e) {
      return def;
    }
  },
};
Number.prototype.toTrimNum = function (length = 4) {
  const value = this.valueOf();
  let list = `${value}`.split(".");
  list[1] = list[1] || "";

  if (list[1].length < length) {
    for (let i = list[1].length; i < length; i++)
      list[1] += "0";
  }

  return list.join(".");
};
window.delay = function (timeout) {
  return new Promise(resolve => setTimeout(resolve, timeout));
};
window.flattenObject = (ob) => {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if ((typeof ob[i]) == "object" && ob[i] !== null) {
      var flatObject = window.flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + "." + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};
Object.defineProperty(Object.prototype, "keyEntries", {
  value(callback = null) {
    const keys = Object.keys(this.valueOf());
    if (typeof callback === "function") {
      keys.forEach(callback);
    }
    return keys;
  },
  enumerable: false,
});

Math.BigSum = function (left, right) {

  const leftResult = (left * 100 + right * 100) / 100;
  if (isNaN(leftResult)) return 0;
  return leftResult;

  if (isNullOrUndefined(left) || isNullOrUndefined(right)) throw new Error("left or right is null");
  if (+right === 0) return left;
  if (+left === 0) return right;

  left = `${left}`;
  right = `${right}`;

  let leftPointer = left.indexOf(".");
  let rightPointer = right.indexOf(".");
  let decimal;

  if (leftPointer >= 0 || rightPointer >= 0) {
    let leftDecimal = leftPointer >= 0 ? left.length - leftPointer - 1 : 0;
    let rightDecimal = rightPointer >= 0 ? right.length - rightPointer - 1 : 0;
    decimal = Math.max(leftDecimal, rightDecimal);

    left = left.replace(".", "");
    right = right.replace(".", "");

    left = left.padEnd(decimal, "0");
    right = right.padEnd(decimal, "0");
  }

  let maxLength = Math.max(left.length, right.length);
  left = left.padStart(maxLength, "0");
  right = right.padStart(maxLength, "0");

  let leftList = left.split("").reverse();
  let rightList = right.split("").reverse();
  let result = [];
  let carry = 0;
  for (let i = 0; i < maxLength; i++) {
    let value = parseInt(leftList[i]) + parseInt(rightList[i]) + carry;
    carry = Math.floor(value / 10);
    result.push(value % 10);
  }

  if (carry > 0) {
    result.push(carry);
  }

  result = result.reverse().join("");
  if (decimal) {
    result = result.slice(0, result.length - decimal) + "." + result.slice(result.length - decimal);
  }

  return result;
};

function isNullOrUndefined(val) {
  return val === undefined || val === null;
}

Math.BigMultiplication = function (left, right) {
  const leftResult = left * right;
  if (isNaN(leftResult)) return 0;
  return leftResult;

  if (isNullOrUndefined(left) || isNullOrUndefined(right)) throw new Error("left or right is null");
  left = `${left}`;
  right = `${right}`;

  let leftPointer = left.indexOf(".");
  let rightPointer = right.indexOf(".");
  let leftDecimal = leftPointer >= 0 ? left.length - leftPointer - 1 : 0;
  let rightDecimal = rightPointer >= 0 ? right.length - rightPointer - 1 : 0;

  left = left.replace(".", "");
  right = right.replace(".", "");

  // 大数据乘法怡情考虑,  有机会试试用wasm来算
  let len1 = left.length;
  let len2 = right.length;
  let result = new Array(len1 + len2).fill(0);

  for (let i = len1 - 1; i >= 0; i--) {
    for (let j = len2 - 1; j >= 0; j--) {
      let product = parseInt(left[i]) * parseInt(right[j]);
      let sum = result[i + j + 1] + product;
      result[i + j + 1] = sum % 10;
      result[i + j] += Math.floor(sum / 10);
    }
  }

  while (result[0] === 0 && result.length > 1) {
    result.shift();
  }

  if (leftDecimal || rightDecimal) {
    let decimal = leftDecimal + rightDecimal;
    result.splice(result.length - decimal, 0, ".");
  }

  return result.join("");
};

window.validatorExtend = {
  Email(str) {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(str);
  },
  Chinese(str){
    return /[\u4e00-\u9fa5]/ig.test(str);
  }
}
