import Vue                                           from "vue";
import {checkSmsCode, sendSmsCode, tryLoginByWechat} from "./user";
import request, {hashRequest, toQueryParams}         from "@/utils/request";

export function getFile(fid) {
  const params = toQueryParams({fid});
  const str = params.keyEntries().map(key => `${key}=${params[key] ?? ""}`).join("&");
  const url = `/f9api/api/v1/getfile?${str}`;
  return url;
}

// 上传文件
export function uploadFile(data, files) {
  let formData = new FormData();
  if (files && files.length > 0) {
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      formData.append("file", file, file.name);
    }
  }
  return request({
    url: "/api/v1/uploadfile",
    method: "post",
    data: formData,
    params: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

function CommonSendSmsCode(area, phone, type, verifyImgCode = "F") {
  // 其实这段没什么必要了来着，因为已经保证了区号一定不会有奇奇怪怪的字符
  if (area[0] === "+") {
    area = area.slice(1, area.length);
  }
  return sendSmsCode({
    countryCode: area,
    phone: phone,
    verifyImgCode,
    isInternational: !!area && +area !== 86 ? "T" : "F",
    type,
  });
}

Vue.mixin({
  computed: {
    OMS_API() {
      return {
        verifyImgCode,
        uploadFile,
        getFile,
        sendSmsCode,
        tryLoginByWechat,
        SendBindPhoneVerifyCode(area, phone, verifyImgCode) {
          return CommonSendSmsCode(area, phone, "BindPhone", verifyImgCode);
        },
        SendBusinessVerifyCode(area, phone) {
          return CommonSendSmsCode(area, phone, "BindCus");
        },
        SendWechatVerifyCode(area, phone) {
          return CommonSendSmsCode(area, phone, "UnBindWx");
        },
        SendBindAccountVerifyCode(area, phone) {
          // return CommonSendSmsCode(area, phone, "BindAccount");
          return CommonSendSmsCode(area, phone, "UpdatePassword");
        },
        CheckIdCardVerifyCode(phone, code, type) {
          return checkSmsCode({
            phone,
            code,
            type,
          });
        },
      };
    },
  },
});

export const verifyImgCode = "/f9api/api/v1/getimgverifycode";
