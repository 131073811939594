import Vue             from "vue";
import VueRouter       from "vue-router";

Vue.use(VueRouter);
import BaseLayout      from "@/views/layout/Base";
import OrderLayout     from "@/views/user/OrderLayout";
import WorkBenchLayout from "@/views/user/WorkBenchLayout";
import TrackViewer     from "@/views/share/trackViewer";
import Page404         from "@/views/state/Page404";

const routes = [
  {
    path: "",
    component: BaseLayout,
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/views/home/Home")
      },
      {
        path: "home",
        redirect: "/"
      },
      {
        path: "business/track/:type",
        name: "BusinessTrack",
        component: () => import("@/views/business/Tracker")
      },
      {
        path: "satisfied",
        name: "WorkOrderSatisfied",
        component: () => import("@/views/user/order/Satisfied")
      }
      // {
      //   path: "preview/template/:type",
      //   name: "PreviewTemplate",
      //   component: () => import("@/views/preview/View"),
      // },
    ]
  },
  {
    path: "/track/share/:orderNum/:jobNo/:conNo",
    component: TrackViewer
  },
  {
    path: "",
    name: "PermissionRouteToCheckUser",
    // 检测认证
    component: () => import("@/views/layout/Permission"),
    children: [
      {
        path: "freight/:type/query",
        name: "FreightQuery",
        component: () => import("@/views/freight/Query")
      },
      /*{
        path: "freight/:type/order",
        name: "FreightOrder",
        component: () => import("@/views/freight/Order")
      },*/
      {
        path: "freight/:type/order",
        name: "FreightOrder",
        redirect: to => {
          return {
            name: `ReOrder${{
              "railway": "Railway",
              "truck": "Truck",
              "highway": "Truck",
              "sea": "Sea",
              "ship": "Sea",
              "air": "Airway",
              "multiTrain": "MultiTrans",
            }[to.params.type]}`
          };
        }
        // component: () => import("@/views/freight/Order")
      },
      // 新版下单页
      {
        path: "freight/reorder",
        name: "FreightReOrder",
        component: BaseLayout,
        redirect: "freight/reorder/railway",
        children: [
          {
            path: "railway",
            name: "ReOrderRailway",
            component: () => import("@/views/freight/ReOrder/Railway.vue"),
          },
          {
            path: "sea",
            name: "ReOrderSea",
            component: () => import("@/views/freight/ReOrder/Sea.vue"),
          },
          {
            path: "truck",
            name: "ReOrderTruck",
            component: () => import("@/views/freight/ReOrder/Truck.vue"),
          },
          {
            path: "airway",
            name: "ReOrderAirway",
            component: () => import("@/views/freight/ReOrder/Airway.vue"),
          },
          {
            path: "multitrans",
            name: "ReOrderMultiTrans",
            component: () => import("@/views/freight/ReOrder/MultiTrans.vue"),
          },
        ]
      },
      {
        path: "freight/order",
        name: "SharedFreightOrder",
        component: () => import("@/views/freight/Order")
      }
    ]
  },
  {
    // 检测认证
    path: '/user',
    name: 'PermissionRouteToCheckUser',
    // 检测认证
    component: () => import('@/views/layout/Permission'),
    children: [
      {
        path: "order",
        name: "UserOrderBase",
        component: OrderLayout,
        children: [
          {
            path: "",
            name: "WorkPlace",
            component: () => import("@/views/user/order/Index")
          },
          {
            path: "userOrder/booking",
            name: "UserOrder",
            component: () => import("@/views/user/order/UserOrder")
          },
          {
            path: "myOrder/allList",
            name: "AllBookingList",
            component: () => import("@/views/user/order/modules/booking/AllBookingList")
          },
          {
            path: "booking/:type",
            name: "UserOrderBookingQuery",
            component: () => import("@/views/user/order/BookingQuery")
          },
          {
            path: "freight/common/query",
            name: "UserOrderFreightQuery",
            component: () => import("@/views/user/order/FreightQuery")
          },

          {
            path: "booking/:type/info/:trafficCd",
            name: "UserOrderInfo",
            component: () => import("@/views/user/order/OrderInfo")
          },
          {
            path: "track-info/:trafficCd",
            name: "TrackInfo",
            component: () => import("@/views/user/order/TrackInfoRailway")
          },
          {
            path: "unopen/common/:type",
            name: "UnOpenPage",
            component: () => import("@/views/user/order/UnopenPage")
          },
          {
            path: "",
            name: "workBenchBaseLayout",
            component: WorkBenchLayout,
            children: [
              {
                path: "workBench/common/invoice",
                name: "InvoiceManage",
                component: () => import("@/views/user/commonPage/InvoiceManage")
              },
              {
                path: "workBench/common/invoice/detail/:id",
                name: "InvoiceDetail",
                component: () => import("@/views/user/commonPage/components/InvoiceDetail")
              },
              {
                path: "workBench/common/invoice/manage/:type",
                name: "InvoiceDetailManage",
                component: () => import("@/views/user/commonPage/components/InvoiceDetailManage")
              }
            ]
          },
          {
            path: "workOrder/:type",
            name: "WorkOrderQuery",
            component: () => import("@/views/user/order/WorkOrderQuery")
          },
          {
            path: "workOrder/:type/info/:trafficCd",
            name: "WorkOrderInfo",
            component: () => import("@/views/user/order/WorkOrderInfo")
          },
          {
            path: "workBench/common/todo",
            name: "WorkOrderTodo",
            component: () => import("@/views/user/order/WorkOrderTodo")
          },
          {
            path: "workOrder/common/collect",
            name: "MyCollect",
            component: () => import("@/views/user/collect/MyCollect")
          },
          {
            path: "workBench/common/notice",
            name: "WorkOrderNotice",
            component: () => import("@/views/user/commonPage/WorkOrderNotice")
          },

          {
            path: "workBench/common/bill",
            name: "MyBill",
            component: () => import("@/views/user/commonPage/MyBill")
          },
          {
            path: "workBench/common/billDetail/:id",
            name: "MyBillDetail",
            component: () => import("@/views/user/commonPage/MyBillDetail")
          }
        ]
      },
      {
        path: "",
        name: "UserLayout",
        component: () => import("@/views/user/UserLayout"),
        children: [
          {
            path: "info",
            name: "UserInformation",
            component: () => import("@/views/user/info/Index")
          },
          {
            path: "business",
            name: "BusinessCheck",
            component: () => import("@/views/user/info/BusinessCheck")
          },
          {
            path: "accountSafe",
            name: "AccountSafe",
            component: () => import("@/views/user/info/AccountSafe")
          },
        ]
      }
    ]
  },
  {
    path: "*",
    redirect: (to) => {
      console.log(to);
      return {
        name: "Page404",
        query: {
          url: to.fullPath
        }
      };
      // return `/404?url=${encodeURIComponent(to.fullPath)}`
    }
  },
  {
    path: "/404",
    name: "Page404",
    component: Page404
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name === from.name) {
      return {};
    }
    return {x: 0, y: 0};
  }
});

export default router;
