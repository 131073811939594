import request, { scrmRequest } from '@/utils/request'
import { createCustomer, updateCustomer } from '@/api/customer'

export function countryCodeList(query) {
  return request({
    url: '/api/v1/country-list',
    method: 'get',
    params: query,
    once: true,
    cache: {
      type: 'local',
      ttl: 24 * 3600
    }
  })
}

export function sendSmsCode(query) {
  return request({
    url: '/api/v1/sendsmscode',
    method: 'post',
    data: query
  })
}

// 手机号登录
export function tryLoginByCode(area, phone, code, userid, openid) {
  const query = {
    phone,
    code,
    countryCode: area
  }
  if (userid) {
    query['userid'] = userid
  }
  if (openid) {
    query['openid'] = openid
  }
  return request({
    url: '/api/v1/tryloginbycode',
    method: 'post',
    data: query
  })
}

// 账号密码登录
export function tryLogin(name, password) {
  const login = {
    name,
    password
  }
  return request({
    url: '/api/v1/custrylogin',
    method: 'post',
    data: {
      login: JSON.stringify(login)
    }
  })
}

// 微信登录
export function tryLoginByWechat(wxToken, param = {}) {
  if (!param['openid'] && param.hasOwnProperty('openid')) {
    delete param['openid']
  }
  if (!param['userid'] && param.hasOwnProperty('userid')) {
    delete param['userid']
  }
  return request({
    url: '/api/v1/wxtrylogin',
    method: 'post',
    data: {
      wxToken,
      ...param
    }
  })
}

// 获取个人信息
export function getInfo() {
  return request({
    url: '/api/v1/cususerinfo',
    method: 'get'
  })
}

// 退出登录
export function logout() {
  return request({
    url: '/api/v1/logout',
    method: 'post',
    data: {
      logout: 'true'
    }
  })
}

// 绑定手机号
export function bindPhone(query) {
  return request({
    url: '/api/v1/phonenum',
    method: 'post',
    data: query
  })
}

export function bindAccount(query) {
  return request({
    url: '/api/v1/accountuser',
    method: 'post',
    data: query
  })
}

// 校验身份验证窗口的验证码
export function checkSmsCode(query) {
  return request({
    url: '/api/v1/checksmscode',
    method: 'post',
    data: query
  })
}

// 绑定企业信息（实质=更新客商资料）
export function bindCustomer(query) {
  return createCustomer(query)
}

//解绑微信
export function unbindWechat(query) {
  return request({
    url: 'api/v1/wechatuser',
    method: 'delete',
    params: query
  })
}

//绑定微信
export function bindWechat(query) {
  return request({
    url: 'api/v1/wechatuser',
    method: 'post',
    data: query
  })
}

// 修改密码
export function changePassword(oldPassword, password,smsCode) {
  return request({
    url: '/api/v1/updatepassword',
    method: 'put',
    data: {
      oldpassword: oldPassword,
      password: password,
      smsCode
    }
  })
}

// 获取我的订舱申请
export function getOrderList(data, type = 'R') {
  const common = {
    keyword: '',
    startDate: '',
    endDate: '',
    orderState: 'all',
    PortlCd: '',
    Portl: '',
    PortdCd: '',
    Portd: '',
    sort: 'asc',
    sortName: '',
    lclState: 'all'
  }
  switch (type) {
    case 'R':
      return request({
        url: '/api/v1/railorderonline-list',
        method: 'post',
        data: {
          ...common,
          ...data
        }
      })
    case 'S':
      return request({
        url: '/api/v1/orderonlineztyt-list',
        method: 'post',
        data: {
          ...common,
          ...data
        }
      })
    case 'A':
      return request({
        url: '/api/v1/airorderonline-list',
        method: 'post',
        data: {
          ...common,
          ...data
        }
      })
    case 'T':
      return request({
        url: '/api/v1/highwayorderonline-list',
        method: 'post',
        data: {
          ...common,
          ...data
        }
      })
    case 'M':
      return request({
        url: 'api/v1/multitransorderonline-list',
        method: 'post',
        data: {
          ...common,
          ...data
        }
      })
  }
}

// 获取我的订舱申请-全部
export function getAllOrderList(data) {
  return request({
    url: 'api/v1/orderonline-alllist',
    method: 'post',
    data 
  })
}

// 获取我的运踪轨迹
export function getRailwayTrackingList(query, type = 'R') {
  const common = {
    filter: '',
    Atd: '',
    Ata: '',
    Portl: '',
    Portd: '',
    PortlCd: '',
    PortdCd: '',
    sort: 'asc',
    sortName: '',
    blFed: false,
    ...query
  }
  switch (type) {
    case 'R':
      return request({
        url: '/api/v1/railwaytracking-list',
        method: 'post',
        data: common
      })
    case 'S':
      return request({
        url: '/api/v1/seatracking-list',
        method: 'post',
        data: common
      })
    case 'A':
      return request({
        url: '/api/v1/airtracking-list',
        method: 'post',
        data: common
      })
  }
}

// 获取SCRM订单详情
export function getSharedOrderDetail(keyId, type = 'R') {
  switch (type) {
    case 'R':
      return request({
        url: '/api/v1/scrmorder_detail',
        method: 'get',
        params: {
          id: keyId,
          type
        }
      })
  }
}

export function getOrderDetail(keyId, type = 'R') {
  switch (type) {
    case 'R':
      return request({
        url: '/api/v1/railorderonline-detail',
        method: 'get',
        params: {
          keyId: keyId
        }
      })
    case 'S':
      return request({
        url: '/api/v1/orderonlineztyt-detail',
        method: 'get',
        params: {
          keyId: keyId
        }
      })
    case 'A':
      return request({
        url: '/api/v1/airorderonline-detail',
        method: 'get',
        params: {
          keyId: keyId
        }
      })
    case 'T':
      return request({
        url: '/api/v1/highwayorderonline-detail',
        method: 'get',
        params: {
          keyId: keyId
        }
      })
    case 'M':
      return request({
        url: '/api/v1/multitransorderonline-detail',
        method: 'get',
        params: {
          keyId: keyId
        }
      })
  }
}

// 获取订舱详情里的工作单列表
export function getWorkOrderDetail(keyId, type = 'R', { pageSize, pageIndex }) {
  switch (type) {
    case 'R':
      return request({
        url: '/api/v1/railorderonline-detailworklist',
        method: 'post',
        data: {
          keyId: keyId,
          pageIndex,
          pageSize
        }
      })
    case 'S':
      return request({
        url: '/api/v1/orderonlineztyt-detailworklist',
        method: 'post',
        data: {
          keyId: keyId,
          pageIndex,
          pageSize
        }
      })
    case 'A':
      return request({
        url: '/api/v1/airorderonline-detailworklist',
        method: 'post',
        data: {
          keyId: keyId,
          pageIndex,
          pageSize
        }
      })
    case 'T':
      return request({
        url: '/api/v1/highwayorderonline-detailworklist',
        method: 'post',
        data: {
          keyId: keyId,
          pageIndex,
          pageSize
        }
      })
    case 'M':
      return request({
        url: '/api/v1/multitransorderonline-detailworklist',
        method: 'post',
        data: {
          keyId: keyId,
          pageIndex,
          pageSize
        }
      })
  }
}

// 获取工作单列表
export function getWorkOrderList(type = 'R', data) {
  switch (type) {
    case 'R':
      return request({
        url: '/api/v1/railorderonline-worklist',
        method: 'post',
        data
      })
    case 'S':
      return request({
        url: '/api/v1/orderonlineztyt-worklist',
        method: 'post',
        data
      })
    case 'A':
      return request({
        url: '/api/v1/airorderonline-worklist',
        method: 'post',
        data
      })
    case 'T':
      return request({
        url: '/api/v1/highwayorderonline-worklist',
        method: 'post',
        data
      })
    case 'M':
      return request({
        url: '/api/v1/multitransorderonline-worklist',
        method: 'post',
        data
      })
  }
}

// 获取工作单详情
export function getWorkOrderListDetail(type = 'R', keyId, jobNoIn) {
  switch (type) {
    case 'R':
      return request({
        url: '/api/v1/railorderonline-worklistdetail',
        method: 'get',
        params: {
          keyId,
          jobNoIn
        }
      })
    case 'S':
      return request({
        url: '/api/v1/orderonlineztyt-worklistdetail',
        method: 'get',
        params: {
          keyId,
          jobNoIn
        }
      })
    case 'A':
      return request({
        url: '/api/v1/airorderonline-worklistdetail',
        method: 'get',
        params: {
          keyId,
          jobNoIn
        }
      })
    case 'T':
      return request({
        url: '/api/v1/highwayorderonline-worklistdetail',
        method: 'get',
        params: {
          keyId,
          jobNoIn
        }
      })
    case 'M':
      return request({
        url: '/api/v1/multitransorderonline-worklistdetail',
        method: 'get',
        params: {
          keyId,
          jobNoIn
        }
      })
  }
}

// 确认工作单已完成
export function confirmWorkOrderFinish(jobNoIn) {
  return request({
    url: 'api/v1/ordercompletesheet_cofirm',
    method: 'put',
    data: { jobNoIn }
  })
}

// 获取我的待办列表
export function getWorkOrderTodoList(data) {
  return request({
    url: '/api/v1/messagelist',
    method: 'post',
    data
  })
}

// 获取我的待办列表详情 标记已读
export function getWorkOrderTodoDetail(keyId) {
  return request({
    url: '/api/v1/message-detail',
    method: 'get',
    params: {
      keyId
    }
  })
}

// 获取工作台首页待办数目
export function getWorkOrderTodoCount() {
  return request({
    url: '/api/v1/homemessage',
    method: 'get'
  })
}

// 获取首页热门路线 - 铁运
export function getHotLineList(data) {
  return request({
    url: '/api/v1/orderonlinespread-list',
    method: 'post',
    data
  })
}

// 获取首页热门路线 - 海运/多式联运
export function getHotLineListOther(data) {
  return request({
    url: '/api/v1/multitransorderonline-spread',
    method: 'post',
    data
  })
}

// 获取订舱 货物大类明细树
export function getCargoTree(params) {
  return request({
    url: 'api/v1/category-list',
    method: 'get',
    params,
    cache: {
      type: 'local',
      ttl: 24 * 3600
    }
  })
  // .then((res) => {
  //   console.log(res);
  //   // 处理树结构数据
  //   return res?.Items?.map((item) => {
  //     item.children = []
  //     res?.SubItems.forEach((subItem) => {
  //       subItem?.Pid === item?.Id && item.children.push(subItem)
  //     })
  //     return item
  //   })
  // })
}

// 获取客户满意度评分
export function getCustomerScores(Id) {
  return request({
    url: '/api/v1/customerscores-query',
    method: 'get',
    params: {
      Id
    }
  })
}

// 获取客户满意度评分
export function CustomerScores(scores) {
  return request({
    url: '/api/v1/customerscores',
    method: 'put',
    data: scores
  })
}

// 获取客户满意度评分
export function CustomerScoresHash(scores) {
  return request({
    url: '/api/v1/customerscores-hash',
    method: 'put',
    data: scores
  })
}

// 获取首页客户满意度评分
export function getCustomerScoresNew() {
  return request({
    url: '/api/v1/customerscores-new',
    method: 'get'
  })
}

// 工作台获取账单
export function geiBillWorkPlace() {
  return request({
    url: '/api/v1/dunnew-top',
    method: 'get'
  })
}

// 获取我的账单列表
export function getMyBillList(data) {
  return request({
    url: '/api/v1/dunnew-list',
    method: 'post',
    data
  })
}
// 获取我的账单详情
export function getMyBillDetail(Id) {
  return request({
    url: '/api/v1/dunnew-detail',
    method: 'get',
    params: {
      Id
    }
  })
}

// 确认或者退回账单
export function confirmBill(data) {
  return request({
    url: '/api/v1/dunnew-update',
    method: 'put',
    data
  })
}

// 获取账单-下载
export function getBillDownload(params) {
  return request({
    url: '/api/v1/dunnew_print',
    method: 'get',
    params
    // responseType: 'blob'
  })
}
// 下载
export function downloadBillFile(data) {
  return request({
    url: '/api/v1/downloadfile',
    method: 'post',
    data,
    responseType: 'blob'
  })
}
// 上传账单
export function uploadBillFile(params, data) {
  return request({
    url: '/api/v1/uploadfile',
    method: 'post',
    params,
    data
  })
}

// 获取开具发票列表
export function getInvoiceList(data) {
  return request({
    url: '/api/v1/invo-list',
    method: 'post',
    data
  })
}
//获取开票记录列表
export function getInvoiceRecordList(data) {
  return request({
    url: '/api/v1/invo-record',
    method: 'post',
    data
  })
}
// 获取发票详情
export function getInvoiceDetail(data) {
  return request({
    url: '/api/v1/invorecord-detail',
    method: 'post',
    data
  })
}

// 开票
export function putInvoicing(data) {
  return request({
    url: '/api/v1/invo-apply',
    method: 'put',
    data
  })
}

// 获取我的发票抬头
export function getInvoiceTitle() {
  return request({
    url: '/api/v1/customer',
    method: 'get'
  })
}

// 添加发票信息
export function addInvoice(data) {
  return request({
    url: '/api/v1/invoinfo',
    method: 'post',
    data
  })
}
// 修改发票信息
export function editInvoice(data) {
  return request({
    url: '/api/v1/invoinfo',
    method: 'put',
    data
  })
}

// 修改发票标注
export function editInvoiceRemark(data) {
  return request({
    url: '/api/v1/invo-personremark',
    method: 'put',
    data
  })
}
// 发票信息列表
export function getInvoiceInfoList(data) {
  return request({
    url: '/api/v1/invoinfo-list',
    method: 'post',
    data
  })
}

// 设置默认发票
export function setDefaultInvoice(Id) {
  return request({
    url: '/api/v1/invoinfo-default',
    method: 'put',
    data: {
      Id
    }
  })
}
// 邮箱管理-修改邮箱信息
export function editEmailInfo(data) {
  return request({
    url: '/api/v1/cususer-email',
    method: 'put',
    data
  })
}

// 获取email信息
export function getEmailList() {
  return request({
    url: '/api/v1/cususerinfo-email',
    method: 'get'
  })
}

// 下载订账单
export function getOrderBillDownload(params) {
  return request({
    url: '/api/v1/invo-export',
    method: 'get',
    responseType: 'blob',
    params
  })
}

// 站点收藏列表
export function getPortCollectList(params) {
  return request({
    url: '/api/v1/portcollect-list',
    method: 'get',
    params
  })
}
// 新增站点收藏
export function getAddPortCollect(dataObj) {
  const data = { json: dataObj }
  return request({
    url: '/api/v1/portcollect',
    method: 'post',
    data
  })
}
// 删除站点收藏
export function getDeletePortCollect(params) {
  // {
  //   TrafficCd,
  //   portlCd,
  //   portdCd
  // }
  return request({
    url: '/api/v1/portcollect',
    method: 'delete',
    params
  })
}

// 获取系统公告列表
export function getNoticeList(params) {
  return request({
    url: '/api/v1/homesaffiche-list',
    method: 'get',
    params
  })
}

// 公告已读
export function getNoticeRead(keyId) {
  return request({
    url: '/api/v1/saffiche-read',
    method: 'put',
    data: {
      keyId
    }
  })
}

// 公告下载附件
export function getNoticeAttach(params) {
  return request({
    url: '/api/v1/getattachment-list',
    method: 'get',
    params
  })
}

// 工作台公告弹窗
export function getNoticeTip() {
  return request({
    url: '/api/v1/saffiche-top',
    method: 'get'
  })
}

// 注销账户
export function deleteAccount() {
  return request({
    url: '/api/v1/delcususer',
    method: 'delete'
  })
}

export function getBusinessTypeList(params = {}){
  return request({
    url:'api/v1/codelib-list',
    method:'get',
    params:{
      classcode:'BUSTYPE',
      ...params,
    }

  })
}

// 工作台-订单列表
export function getWorkBenchOrderList(type = 'railway',params={}) {
  const url = {
    railway:'api/v1/railway-yzb',
    multiTrain:'api/v1/multitrans-yzb',
    truck:'api/v1/highway-yzb',
    ship:'api/v1/export-yzb',
  }[type]
  return request({
    url,
    method: 'get',
    params
  })
}
