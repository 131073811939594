<template>
  <div class="flex oms-control-group">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "OmsControlGroup"
};
</script>

<style lang="scss">
.oms-control-group {
  > * {
    flex-grow: 1;
    width: 0;
  }

  > *:not(:last-child) {
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  > *:not(:first-child) {
    input {
      border-left-width: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &:focus {
        border-left-width: 1px;
        margin-left: -1px;
      }
    }

    &:not(:first-child) {
      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
</style>
