export default {
  props: {
    dependent: [Array, Object, String, Number, Function],
    type: [String, Number],
  },
  computed: {
    DEPENDENT() {
      return this.dependent || [];
    },
  },
};

