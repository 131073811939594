import Axios           from "axios";

const host = "http://gps.nbstrans.com/prod-api";
const base = Axios.create({
  baseURL: host,
  // withCredentials: true,
  // crossDomain: true,
});

// 获取运踪轨迹
export async function getLocationInfo(jobNumber = "", conNumber = "") {
  try {
    const res = await base({
      url: `${host}/logistics/open-up/getLocationInfo/${jobNumber}/${conNumber}`,
      type: "get",
      // notHash: true,
      crossDomain: true,
    });
    return {
      success: true,
      data: res,
    };
  } catch (e) {
    return {
      success: false,
    };
  }
}

// 绑定运踪设备
export async function addOrderForm(form) {
  return await new Promise(resolve => $.ajax({
    url: `${host}/logistics/open-up/addOrderForm`,
    type: "post",
    crossDomain: true,
    data: {
      ...form,
      orderNo: form["orderNo"],
      caseNum: form["caseNum"],
      departure: form["departure"],
      destination: form["destination"],
      businessType: form["businessType"],
      customerName: form["customerName"],
      companyName: form["companyName"],
    },
    success(response) {
      resolve({
        success: true,
        data: response,
      });
    },
    error() {
      return resolve({
        success: false,
      });
    },
  }));
}

// 订购设备
export async function reserveDevice(orderNumber, quantity) {
  return await new Promise(resolve => ({
    url: `${host}/logistics/open-up/reserveDevice/${orderNumber}/${quantity}`,
    type: "get",
    crossDomain: true,
    success(response) {
      resolve({
        success: true,
        data: response,
      });
    },
    error() {
      return resolve({
        success: false,
      });
    },
  }));
}
