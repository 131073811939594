import {MessageBox, Message} from "element-ui";
import {i18n}                from "@/app";

export function modalWarning(title, message, options) {
  message = message || title;
  return new Promise(resolve => {
    MessageBox.confirm(message, title, {
      type: "warning",
      showCancelButton: false,
      dangerouslyUseHTMLString: true,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      callback(action) {
        if (action === "close" || action === "cancel") {
          return resolve(false);
        }
        return resolve(true);
      },
      ...options,
    });
  });
}

export function messageWarning(title, message) {
  if (!message) {
    message = title;
    title = i18n.t('MessageBox.Hint');
  }
  Message({
    title,
    message,
    type: "warning",
    duration: 2000,
  });
}

export default {
  computed: {
    commonModalOptions() {
      return {
        type: "warning",
      };
    },
    COMMON_TEXT() {
      return {
        // 提示
        tips: i18n.t('MessageBox.Hint'),
      };
    },
  },
  methods: {
    modalAlert(message, title) {
      return new Promise(resolve => {
        this.$alert(message, title || this.COMMON_TEXT.tips, {
          callback: action => {
            resolve();
          },
        });
      });
    },
    modalWarning(message, title, options) {
      return new Promise(resolve => {
        this.$confirm(message, title || this.COMMON_TEXT.tips, {
          ...options,
          ...this.commonModalOptions,
          dangerouslyUseHTMLString: true,
          callback(action) {
            if (action === "close" || action === "cancel") {
              return resolve(false);
            }
            return resolve(true);
          },
        });
      });
    },
    // 操作成功消息提示
    notifySuccess(message) {
      return this.$notify({
        title: i18n.t('MessageBox.Success'),
        message: message,
        type: "success",
        duration: 2000,
      });
    },
    // 操作消息提示
    notifyInfo(message) {
      return this.$notify({
        title: i18n.t('MessageBox.Hint'),
        message: message,
        type: "info",
        duration: 2000,
      });
    },
    // 错误提示
    notifyError(message) {
      return this.$notify({
        title: i18n.t('MessageBox.Error'),
        message: message,
        type: "error",
        duration: 2000,
      });
    },
    // 消息提示
    notifyWarning(message, title = i18n.t('MessageBox.Hint')) {
      //  消息通知弹窗
      return this.$notify({
        title: title, // 成功
        message: message,
        type: "warning",
        duration: 2000,
      });
    },
  },
};
