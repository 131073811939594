<template>
  <a v-if="TypeComputed.isDialog" v-bind="GlobalPropSetting" @click="onVisible">
    <slot name="button">
      <el-button @click="localModel.visible=true">
        {{ label }}
      </el-button>
    </slot>
    <el-dialog :visible.sync="localModel.visible" :title="LabelComputed" append-to-body v-bind="CurrentProps"
               custom-class="ui-component__second-version__oms-dialog-body">
      <div class="line"/>
      <slot/>
    </el-dialog>
  </a>
  <a v-else-if="TypeComputed.isPopover" v-bind="GlobalPropSetting">
    <el-popover-extend trigger="click" v-model="localModel.visible" v-bind="CurrentProps" transition="oms-transition"
                       v-on="CurrentListeners"
                       :popper-class="['oms-popover-custom',CurrentProps.popperClass, EffectClassComputed].filter(Boolean).join(' ')">
      <slot slot="reference" name="button">
        <el-button @click="localModel.visible=true">
          {{ label }}
        </el-button>
      </slot>
      <div class="popover-dialog-title" v-if="!CurrentProps.hideLabel">{{ LabelComputed }}</div>
      <slot/>
    </el-popover-extend>

  </a>
  <el-button v-else-if="ButtonTypeComputed.isButton" :type="type" ref="ButtonNode"
             v-bind="CurrentProps" v-on="CurrentListeners">
    <slot/>
  </el-button>
</template>

<script>
import typeComputed    from "@/mixins/global/typeComputed";
import Element         from "element-ui";
import ElPopoverExtend from "@/components/elementExtend/ElPopoverExtend";

export default {
  name: "OmsButton",
  components: {
    ElPopoverExtend
  },
  data() {
    return {
      localModel: {
        visible: !!this.value,
      },
      localLabelKey: "",
    };
  },
  computed: {
    EffectClassComputed() {
      return [this.effect === "OMS" ? "oms-item_popper_popper-style" : ""].join(",");
    },
    // 是否开启自动翻译
    AutoTranslate() {
      if (typeof this.autoTranslate === "boolean") {
        return !!this.autoTranslate;
      }
      return !!this.omsMainForm?.autoTranslate;
    },
    ButtonTypeComputed() {
      return {
        isButton: !this.type || ["primary", "info", "text", "warning", "danger", "success"].includes(this.type),
      };
    },
    GlobalPropSetting() {
      return {
        style: "font-size:0;vertical-align: top;"
      };
    },
    VNodeLabel() {
      const getText = vNode => [vNode.text ?? "", vNode.children?.length > 0 ? vNode.children.map(getText) : null].flat(Infinity).filter(Boolean).join("");

      if (this.$slots.button?.length > 0) {
        let [vNode] = this.$slots.button;

        return this.label ?? getText(vNode);
      }
      return this.label;
    },
    LabelComputed() {
      if (this.title) {
        return this.title;
      }
      const label = this.VNodeLabel.trim();
      this.localLabelKey = this.localLabelKey || this.onFindLabelI18nKey(label);
      if (!this.localLabelKey) {
        return label;
      }

      return this.$t(this.localLabelKey, this.$i18n.locale);
    },
    CurrentListeners() {
      return this.$listeners;
    },
    CurrentProps() {
      let props = {
        ...this.$props,
      };

      if (this.TypeComputed.isDialog) {
        Object.keys(Element.Dialog.props).forEach(prop => {
          props[prop] = this.$attrs[prop];
        });
      } else if (this.TypeComputed.isPopover) {
        Object.keys(Element.Popover.props).forEach(prop => {
          props[prop] = this.$attrs[prop];
          if (prop === "width") {
            props[prop] = parseFloat(props[prop]);
          }
        });
      } else {
        props = {
          ...props,
          ...this.$attrs,
        };
      }

      return props;
    },
  },
  mounted() {
    this.onProgressAutoTranslate();
  },
  mixins: [
    typeComputed,
  ],
  methods: {
    // 回游搜索
    onFindLabelI18nKey(label) {
      const flatten = this.$i18n._messageFlatten?.["zh-CN"] || {};
      return Object.keys(flatten).find(key => flatten[key] === label);
    },
    // 处理自动翻译并修改节点
    onProgressAutoTranslate() {
      if (this.AutoTranslate) {

        if (this.ButtonTypeComputed.isButton) {
          if (this.$slots.default) {

          }

          console.log("this.$slots.default", this.$slots.default);
        }

      }
    },
    onVisible() {
      this.localModel.visible = true;
    },
  },
  watch: {
    value(val) {
      if (this.TypeComputed.isPopover || this.TypeComputed.isDialog) {
        this.localModel.visible = !!val;
      }
    },
    "localModel.visible"(val) {
      if (!!this.value !== val) {
        this.$emit("change", val);
      }
    }
  },
  model: {
    event: "change",
    prop: "value",
  },
  inject: ["omsMainForm"],
  props: {
    effect: {type: String, default: "OMS"},
    title: String,
    label: String,
    hideLabel: Boolean,
    value: [Object, String, Boolean, Array],
    // 自动翻译
    autoTranslate: {
      type: [String, Boolean],
      default() {
        return "";
      }
    },
  }
};
</script>
<style lang="scss">
.oms-popover-custom {
  transform-origin: top center;
  z-index: 2000 !important;

  .popover-dialog-title {
    padding: 10px 0;
    border-bottom: 1px solid #efefef;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
  }
}
</style>
