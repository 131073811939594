<template>
  <bing-action ref="BING_ACTION" :map-data="dependent"/>
</template>

<script>
import BingAction from "@/components/map/modules/BingAction";

export default {
  name: "Bing",
  components: {BingAction},
  data() {
    return {
      loading: true,
      map: null,
    };
  },
  props: {
    dependent: {
      type: [Object, Array],
      default() {
        return {};
      },
    },
    loading:Boolean,
  },
  computed: {
    MIN_ZOOM() {
      return 3;
    },
  },
  mounted() {
    this.loadingBingMapModule();
  },
  methods: {
    MapModule() {
      return window.Microsoft?.Maps?.Map;
    },
    Location() {
      return window.Microsoft?.Maps?.Location;
    },
    loadingBingMapModule() {
      this.$emit('update:loading',true);
      if (!this.MapModule() || !this.Location()) {
        const src = "https://www.bing.com/api/maps/mapcontrol?callback=GetMap&key=AgbHa4-idBp3fPhesuOKIMYs3ff2GVYcX-mUzWt6NURqCYdo3raeH6Fr-SlzUSD7";

        const script = document.createElement("script");

        this.waitLoadMicrosoftMap = new Promise(resolve => {
          this.resolveMap = resolve;
        });

        script.src = src;
        script.onload = () => this.onCheckingMap();
        script.onerror = () => {
          this.loadingBingMapModule();
        };
        document.head.append(script);
      } else {
        this.waitLoadMicrosoftMap = Promise.resolve();
        // this.createMap();
        this.mapDataUpdate();
      }
    },
    async onCheckingMap() {
      const div = document.createElement("div");

      while (true) {
        await delay(1000);
        if (!this.MapModule() || !this.Location()) {
          continue;
        }
        try {
          let bing_map = this.MapModule();
          new bing_map(div);
        } catch (e) {
          console.error(e);
          continue;
        }
        break;
      }

      this.resolveMap?.();
      await this.mapDataUpdate();
    },
    // async createMap() {
    //   this.loading = false;
    //   this.map = new this.MapModule()(this.$refs.MAP);
    //   const {search} = await this.loadMapModule();
    //
    //
    //   const {location} = await search.getLocationByAddress("广东省深圳市龙岗区");
    // },
    // loadMapModule() {
    //   const map = this.map;
    //
    //   return new Promise(async resolve => {
    //     const [search] = await Promise.all([
    //       new Promise(resolve => {
    //         window.Microsoft.Maps.loadModule("Microsoft.Maps.Search", function () {
    //           resolve(new Microsoft.Maps.Search.SearchManager(map));
    //         });
    //       }),
    //     ]);
    //
    //     resolve({
    //       search: this.searchModule(search),
    //     });
    //   });
    // },
    //
    // searchModule(searchManager) {
    //   const map = this.map;
    //
    //   return {
    //     getLocationByAddress(address, display = true) {
    //       return new Promise(resolve => {
    //         const requestOptions = {
    //           bounds: map.getBounds(),
    //           where: address,
    //           callback: function (answer, userData) {
    //             if (display) {
    //               const bounds = answer.results[0].bestView;
    //               map.setView({
    //                 center: bounds.center,
    //                 zoom: this.MIN_ZOOM,
    //               });
    //             }
    //
    //             resolve({
    //               answer,
    //               userData,
    //               location: answer.results[0].bestView,
    //             });
    //           },
    //         };
    //         searchManager.geocode(requestOptions);
    //       });
    //     },
    //   };
    // },

    async mapDataUpdate() {
      await this.waitLoadMicrosoftMap;
      this.$emit('update:loading',false);
      const bing_location = this.Location();
      return this.$refs.BING_ACTION.mapDataUpdate({
        center: new bing_location(0, 0),
        zoom: this.MIN_ZOOM,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.map {
  width: 100%;
  height: 100%;
}
</style>