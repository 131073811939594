import App                from "@/App.vue";
import Vue                from "vue";
import AppLink            from "@/components/AppLink";
import VueI18n            from "vue-i18n";
import store              from "@/store";
import router             from "@/router";
import ElementUI          from "element-ui";
import * as ElementExtend from "@/components/elementExtend";
import * as OmsContainer  from "@/components/container";
import LoadingSpan        from "@/components/LoadingSpan";
import OmsDialogExtend    from "@/components/omsExtend/OmsDialogExtend";
import OmsInput           from "@/components/omsExtend/OmsInput";
import OmsSelect          from "@/components/omsExtend/OmsSelect";
import OmsDatePicker      from "@/components/omsExtend/OmsDatePicker";
import apiMixin           from "@/mixins/api.method";

import "@/assets/extend/fonts.css";
import "@/directives/el-drag-dialog";
import "@/router/modules/permission";
import "@/directives/flex";
import "@/directives/autoTranslate";
import "@/mixins";
import "@/api";
import "@/filter/index";
import OmsControlGroup    from "@/components/OmsControlGroup";
// import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import dependent          from "@/mixins/dependent";
import OmsInputNumber     from "@/components/omsExtend/OmsInputNumber";
import VueAnimateNumber   from "vue-animate-number"; //数字滚动组件
import scrollReveal       from "scrollreveal";
// 引入初始css样式
import "animate.css";
import "./styles/second.scss";
import OmsItem            from "@/components/omsExtend/global/OmsItem.vue";
import OmsButton          from "@/components/omsExtend/global/OmsButton.vue";
import OmsBaseCol         from "@/components/container/OmsBaseCol";
import VueSuperMethod     from "vue-super-call";


Vue.prototype.$scrollReveal = scrollReveal();

//todo: 还得将element-ui设置成中文化

const vueVersion = "2";

// switch (vueVersion){
//   case '3':
//     const app = createApp(App);
//     app.component("app-link", AppLink);
//     for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
//       app.component(key, component)
//     }
//
// // app.mixin()
//     app.mixin({
//       methods: {
//         log(...d) {
//           console.log(...d);
//         },
//       },
//     });
//     break;
// }
// app.mixin()
Vue.mixin({
  methods: {
    consoleLog(...d) {
      console.log(...d);
    },
  },
});
Vue.use(VueI18n);
Vue.use(ElementUI);
Vue.use(VueAnimateNumber);

Vue.mixin(apiMixin);
Vue.prototype.$super = VueSuperMethod;

Vue.component("app-link", AppLink);
Vue.component("oms-row", OmsContainer.OmsRow);
Vue.component("oms-control-group", OmsControlGroup);
Vue.component("oms-input-number", OmsInputNumber);
Vue.component("oms-child", OmsContainer.OmsRow);
Vue.component("oms-form-item", OmsContainer.OmsFormItem);
Vue.component("oms-input", OmsInput);
Vue.component("oms-select", OmsSelect);
Vue.component("oms-date-picker", OmsDatePicker);
/** 2023-06-21新增 */
Vue.component("oms-container", Object.assign({}, OmsItem, {
  name: "OmsContainer"
}));
Vue.component("oms-col", Object.assign({}, OmsItem, {
  name: "OmsCol"
}));
Vue.component("oms-form", Object.assign({}, OmsItem, {
  name: "OmsForm"
}));
Vue.component("oms-item", OmsItem);
Vue.component("oms-button", OmsButton);
Vue.component("oms-base-col", OmsBaseCol);
/** 2023-06-21新增 */
Vue.component("el-checkbox-extend", ElementExtend.ElCheckboxExtend);
Vue.component("el-radio-extend", ElementExtend.ElRadioExtend);
Vue.component("el-select-extend", ElementExtend.ElSelectExtend);
Vue.component("el-select-remote-extend", ElementExtend.ElSelectRemoteExtend);
Vue.component("el-tag-input", ElementExtend.ElTagInput);

Vue.component("loading-span", LoadingSpan);
Vue.component("oms-dialog", OmsDialogExtend);


// Object.keys(key => {
// });
export const i18n = new VueI18n({
  locale: "zh-CN",
});

let base = VueI18n.prototype.mergeLocaleMessage;
VueI18n.prototype.mergeLocaleMessage = function (locale, localeMessageObject) {
  base.call(this, locale, localeMessageObject);
  this.mergeLocalFlattenMessage(locale, window.flattenObject(localeMessageObject));
};
VueI18n.prototype.mergeLocalFlattenMessage = function (locale, localeFlattenMessageObject) {
  if (!this._messageFlatten) {
    this._messageFlatten = {};
  }

  if (!this._messageFlatten[locale]) {
    this._messageFlatten[locale] = {};
  }
  Object.assign(this._messageFlatten[locale], localeFlattenMessageObject);
};

let labelList = {};
VueI18n.prototype.$fKey = Vue.prototype.$fKey = (label, startsWith) => {
  let key = labelList[label];

  if (!i18n._messageFlatten) return "";

  if (!key) {
    let flatten = i18n._messageFlatten["zh-CN"];
    key = Object.keys(flatten).find(key => (!startsWith || key.startsWith(startsWith)) && flatten[key] === label);

    labelList[label] = key;
  }

  if (!key) {
    return label;
  }

  return key;
};

Vue.mixin({
  methods: {
    /** @param label 文本
     * @params messageParams | List 参数列表*/
    $t_reserve(label, ...messageParams) {
      let key = this.$fKey(label);
      return this.$t(key, messageParams);
    }
  }
});

const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount("#app");

export default app;
