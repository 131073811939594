<script>
// import ElSelectMenu from "element-ui/packages/select/src/select-dropdown";
import Element from 'element-ui';
import {
  PopupManager
} from 'element-ui/lib/utils/popup';

export default {
  name: "ElSelectMenu",
  extends: Element.Select.components.ElSelectMenu,
  mounted() {
    this.referenceElm = this.$parent.$refs.reference.$el;
    this.$parent.popperElm = this.popperElm = this.$el;
    this.$on('updatePopper', () => {
      if (this.$parent.visible) {
        PopupManager.nextZIndex();
        this.updatePopper();
      }
    });
    this.$on('destroyPopper', this.destroyPopper);
  },
  watch: {
    "$parent.inputWidth"() {
      let propElement = this.$parent.element;
      let element;
      this.minWidth = this.$parent.$el.getBoundingClientRect().width + "px";

      if (typeof propElement === "function") {
        element = propElement();
      } else {
        element = propElement;
      }

      let rect = element?.getBoundingClientRect?.();
      if (rect) {
        this.minWidth = rect.width + "px";
      }
    }
  },
};
</script>
