<template>
  <component :is="form ? 'el-form-item':'el-col'" v-bind="$attrs" :label="label" :label-width="labelWidth || null"
             :style="COMPUTED_STYLE"
             :class="{
               'block-line':NOT_INLINE,
               'fill-content':fillContent,
               'readonly':readonly,
               'show-required':required,
               'label-right':align === 'right',
               'ofi-fs-en': isEn,
               'ofi-fs-ru': isRu,
             }"
             :prop="prop" 
             class="oms-form-item is-required">
    <slot name="label" slot="label"/>
    <slot/>
  </component>
</template>

<script>
const formProps = {
  form: {
    type: Boolean,
    default: true,
  },
  label: String,
  labelWidth: {
    type: [String, Number],
    default: null,
  },
  align: String,
  prop: String,
  margin: [String, Number],
  padding: [String, Number],
  inline: {
    type: Boolean,
    default: true,
  },
  fillContent: {
    type: Boolean,
    default: false,
  },
  readonly: Boolean,
  disabled: Boolean,
};

export default {
  name: "OmsFormItem",
  props: {
    ...formProps,
    required: Boolean,
  },
  provide() {
    return {
      omsFormItem: this,
    };
  },
  inject: {
    elForm: {
      default: "",
    },
    omsRow: {
      default: "",
    },
  },
  computed: {
    isEn(){
      return this.$i18n.locale === 'en';
    },
    isRu(){
      return this.$i18n.locale === 'ru';
    },
    NOT_INLINE() {
      return !this.inline || this.elForm.inline === false;
    },
    COMPUTED_STYLE() {
      const styleRule = {};
      const margin = this.margin || this.omsRow?.formItemMargin;
      if (typeof margin === "string" || typeof margin === "number") {
        styleRule["margin"] = margin;
      }

      return styleRule;
    },
  },
  updated() {
    this.onUpdatePadding();
  },
  mounted() {
    this.onUpdatePadding();

    if (this.prop) {
      this.omsRow.pushItem(this.prop, this);
    }
  },
  watch: {
    padding() {
      this.onUpdatePadding();
    },
  },
  methods: {
    onUpdatePadding() {
      const padding = this.padding || this.omsRow?.formItemPadding;
      if (!this.isNullOrUndefined(padding)) {
        const [itemContent] = this.$el.getElementsByClassName("el-form-item__content");
        if (itemContent) {
          itemContent.style.padding = padding;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.oms-form-item.el-form-item {
  display: flex;

  &:before, &:after {
    display: none;
  }

  &.readonly {
    .el-input.is-disabled .el-input__inner {
      cursor: text;
      color: #333;
      border-color: #DCDFE6;
      background: #F6F6F6;
    }
  }

  &.fill-content {
    .el-form-item__content {
      flex-basis: 100%;
    }
  }

  .el-form-item__label {
    flex-shrink: 0;
    white-space: nowrap;
    padding: 0;
  }

  &:not(.text-left) .el-form-item__label {
    //text-align: left;
    // margin-right: 10px;
    margin-left: 20px;
    // justify-content: flex-end;
  }

  &.text-left .el-form-item__label {
    text-align: left;
    justify-content: flex-start;
  }

  &.block-line {
    flex-wrap: wrap;

    .el-form-item__label {
      display: block;
      width: 100%;
      flex-basis: 100%;
      text-align: left;
      justify-content: flex-start;
    }

    .el-form-item__content {
      flex-basis: 100%;
      margin-left: 0;
    }
  }

  .el-form-item__content {
    flex-grow: 1;
    flex-shrink: 0;
    width: 0;

    > .el-select, > .el-input {
      width: 100%;
    }
  }

  &.is-required {
    .el-form-item__label {
      display: flex;

      &:before {
        content: '*';
        color: #F56C6C;
        margin-right: 4px;
        font-family: fangsong;
        visibility: hidden;
      }
    }

    &.show-required {
      .el-form-item__label {
        &:before {
          visibility: visible;
        }
      }
    }
  }

  &.label-left {
    .el-form-item__label {
      text-align: left;
    }
  }
}
</style>
