<template>
  <!--  <el-col :xs="SPAN * 4" :md="SPAN * 2" :lg="SPAN" :xl="SPAN"-->
  <component :is="RowSpan === 'inner' ? 'div' : 'el-col'"
             :xs="SPAN * 4" :md="SPAN * 2" :lg="SPAN" :xl="SPAN"
             :style="StyleComputed"
             :class="DynamicClassName">
    <!--    class="flex"-->
    <component :is="DynamicComponent" :label-width="LABEL_WIDTH" :label="label"
               :label-class="labelClass"
               class="form-item-extend_col-base" :class="[formItemClass,DynamicFormClass]"
               :required="required"
               ref="FormItem"
               :required-space="requiredSpace"
               :contentClass="contentClass"
               :position="position"
               :autoTranslate="autoTranslate"
               :inline="inline"
               :ruleTranslateKey="ruleTranslateKey"
               :prop="prop" :rules="rules">
      <slot v-if="$slots.label" name="label" slot="label"></slot>
      <slot v-if="$slots['label-suffix']" name="label-suffix" slot="label-suffix"></slot>
      <slot v-if="$slots.error" name="error" slot="error"></slot>
      <slot :disabled="DISABLED_COMPUTED"/>
    </component>
  </component>
</template>

<script>
import ElFormItemExtend from "../elementExtend/ElFormItemExtend";

export default {
  name: "OmsBaseCol",
  components: {
    ElFormItemExtend,
  },
  methods: {
    getFormItem() {
      return this.$refs.FormItem;
    }
  },
  computed: {
    StyleComputed() {
      // 适用于仅Inner模式下（即纯div标签下的style）
      if (this.RowSpan === "inner") {
        const gutter = +this.omsRow.gutter;
        if (gutter > 0) {
          return {
            paddingLeft: (gutter / 2) + "px",
            paddingRight: (gutter / 2) + "px",
          };
        }

        return {};
      }
    },
    RowSpan() {
      return this.span || this.omsRow.span;
    },
    DynamicComponent() {
      if (this.tag) {
        return this.tag;
      }

      if (!this.label && !this.$slots.label) {
        return "div";
      }

      return "el-form-item-extend";
    },
    DynamicFormClass() {
      let classList = [];
      if (this.required) {
        classList.push("required");
      }

      return classList;
    },
    DynamicClassName() {
      const minWidth = this.omsItem.minWidth;
      return [
        "oms-item",
        this.$attrs["className"],
        this.omsItem.class,
        !isNaN(parseFloat(minWidth)) ? ("min-width-" + minWidth) : "",
      ].filter(Boolean);
    },
    LABEL_WIDTH() {
      const result = this.labelWidth || this.omsItem.LabelWidthExtend;
      if (result === false || result === "false") {
        return "";
      }
      return result || "120px";
    },
    SPAN() {
      // 如果存在tag自定义的情况 则默认将span款
      if (this.tag || this.omsItem.row) {
        return 24;
      }

      return +this.span || +this.omsItem.omsItem?.ItemSpanComputed || 6;
    },
    DISABLED_COMPUTED() {
      if (typeof this.disabled === "boolean") {
        return this.disabled;
      }

      if (this.omsItem.form) {
        return this.omsItem.disabled ?? this.disabled;
      }

      return this.disabled;
    },
  },
  props: {
    span: [Number, String],
    label: String,
    labelClass: String,
    formItemClass: String,
    ruleTranslateKey: String,
    labelWidth: {
      type: [String, Boolean], default() {
        return "";
      }
    },
    prop: String,
    position: String,
    inline: Boolean,
    autoTranslate: Boolean,
    contentClass: String,
    rules: Array,
    disabled: [],
    tag: String,
    required: [Object, String, Boolean],
    requiredSpace: Boolean,
  },
  inject: {
    omsRow: {
      default: "",
    },
    omsItem: {
      default: "",
    }
  },
};
</script>

<style lang="scss">
.form-item-extend_col-base {
  .el-form-item__content > * {
    width: 100%;
  }
}
</style>
