<template>
  <div ref="myMap" class="my-map"></div>
</template>

<script>
export default {
  name: "BingAction",
  data() {
    return {
      map: null,
      infobox: undefined,
    };
  },
  props: {
    mapData: Object,
  },
  methods: {
    initMap(options) {
      this.map = new Microsoft.Maps.Map(this.$refs.myMap, options);
    },
    mapDataUpdate(options) {
      this.initMap(options);
      const len = this.mapData.list.length;
      if (len > 0 && this.mapData.center !== null) {
        this.map.setView({
          center: new Microsoft.Maps.Location(Number(this.mapData.center.latitude), Number(this.mapData.center.longitude)),
          zoom: 5,
        });

        // 创建位置数组
        let coords = [];
        for (let i = 0; i < len; i++) {
          const data = this.mapData.list[i];
          let location = new Microsoft.Maps.Location(Number(data.latitude), Number(data.longitude));
          coords.push(location);

          // 在地图中心创建一个信息框 但不要显示它
          this.infobox = new Microsoft.Maps.Infobox(location, {visible: false});
          this.infobox.setMap(this.map);

          let icon, anchor;
          if (i !== 0 && i !== (len - 1)) {
            let size = 30;
            icon = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}">
    <circle cx="15" cy="15" r="5" stroke="red" stroke-width="4" fill="white" />
</svg>`;
            anchor = new Microsoft.Maps.Point(size / 2, size / 2);
          }
          // 创建自定义图钉
          let pin = new Microsoft.Maps.Pushpin(location, {
            icon,
            anchor,
            color: "red",
          });
          // 用图钉存储一些元数据
          pin.metadata = {
            title: data.latitude + "," + data.longitude,
            description: data.address + " [ " + data.createTime + " ]",
          };

          // 将单击事件处理程序添加到图钉
          Microsoft.Maps.Events.addHandler(pin, "mouseover", this.handlePushPinClick);
          // 将图钉添加到地图中
          this.map.entities.push(pin);
        }

        // 创建多段线
        const line = new Microsoft.Maps.Polyline(coords, {
          strokeColor: "red",
          strokeThickness: 3,
        });
        // 将多段线添加到地图
        this.map.entities.push(line);
      }
    },
    handlePushPinClick(e) {
      // 确保信息框中有要显示的元数据
      if (e.target.metadata) {
        // 使用图钉的元数据设置信息框选项
        this.infobox.setOptions({
          location: e.target.getLocation(),
          title: e.target.metadata.title,
          description: e.target.metadata.description,
          visible: true,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.my-map {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
