<template>
  <a class="verify-button" :class="{'disabled':DISPLAY_SMS_COOL_DOWN > 0}"
     @click="onGetSmsCode()">
    {{ DISPLAY_SMS_COOL_DOWN > 0 ? `${DISPLAY_SMS_COOL_DOWN}${$t('VerifySmsCode.Second')}` : $t('VerifySmsCode.GetSmsCode') }}
  </a>
</template>

<script>
import {mapGetters} from "vuex";
import store        from "@/store";

export default {
  name: "VerifySmsCode",
  data() {
    const user = store.getters.user;
    return {
      // buttonDown: {},
      loading: false,
      int: 0,
      countDateTime: 0,
    };
  },
  props: {
    send: Function,
    countKey: String,
    max: {
      type: Number,
      default: process.env.NODE_ENV === "development" ? 10 : 60,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    COOL_DOWN_KEY() {
      const user = store.getters.user;
      return "cooldown__" + (this.countKey || user.UserId || "GuestTemp");
    },
    DISPLAY_SMS_COOL_DOWN() {
      // const {gold} = this.buttonDown;
      // if (gold > 0) {
      //   return gold;
      // }
      // return 0;
      return this.countDateTime;
    },
  },
  mounted() {
    const time = sessionStorage.getItem(this.COOL_DOWN_KEY);
    if (Date.now() < time) {
      this.countButton();
    }
  },
  destroyed() {
    clearInterval(this.int);
  },
  methods: {
    countButton() {
      const countTime = (time) => (time - parseInt(Date.now() / 1000) * 1000) / 1000;
      const time = sessionStorage.getItem(this.COOL_DOWN_KEY);
      this.countDateTime = countTime(time);

      this.int = setInterval(() => {
        const lastTime = countTime(time);
        this.countDateTime = lastTime;
        if (lastTime <= 0) {
          this.countDateTime = 0;
          sessionStorage.removeItem(this.COOL_DOWN_KEY);
          return clearInterval(this.int);
        }
      }, 1000);
    },
    async onGetSmsCode() {
      if (this.loading) return;
      if (this.DISPLAY_SMS_COOL_DOWN > 0) return;

      this.loading = true;
      const ok = await this.send?.();
      this.loading = false;
      if (ok !== false) {

        sessionStorage.setItem(this.COOL_DOWN_KEY, parseInt(Date.now() / 1000) * 1000 + (this.max * 1000));
        this.countButton();
      }
    },
  },
};
</script>

<style scoped>

</style>