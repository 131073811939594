var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.form ? 'el-form' : 'el-row',_vm._b({ref:"FORM_ROW",tag:"component",staticClass:"oms-row",class:{
             'oms-row_row': !_vm.form,
             'width-auto':_vm.autoWidth,
             'clear-both':_vm.clearBoth,
             'readonly':_vm.readonly,
             'disabled':_vm.disabled,
             'oms-row-en':_vm.$i18n.locale === 'en',
             'oms-row-ru':_vm.$i18n.locale === 'ru',
             'oms-row-zh':_vm.$i18n.locale === 'zh-CN',
             'oms-row-tw':_vm.$i18n.locale === 'zh-TW',
           },attrs:{"labelWidth":_vm.inline ? _vm.labelWidth : null,"inline":_vm.inline,"disabled":_vm.disabled || _vm.readonly,"model":_vm.model,"rules":_vm.rules,"gutter":_vm.gutter}},'component',_vm.$attrs,false),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }