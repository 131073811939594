import {i18n} from "@/app";

export default {
  data() {
    return {
      HeaderInfo: {
        MobileLogin: {
          area: "",
          phone: "",
          verifyImgCode: "",
          verifySmsCode: "",
        },
      },
      bindUser: {
        area: "86",
        phone: "",
        verifySmsCode: "",
        type: "",
        account: "",
        password: "",
        newPassword: "",
        samePassword: "",
        email:'',
        oldEmail:''
      },
      bindUser_newPhone:{
        area: "86",
        phone: "",
        verifySmsCode: "",
      },
      INFO_RULES: {},
      emailList:[]
    };
  },
  created() {
    this.refreshRules();
  },
  watch:{
    '$i18n.locale'(){
      this.refreshRules();
    }
  },
  methods: {
    refreshRules() {
      this.INFO_RULES = {
        MOBILE_RULES_PROP: {
          areaAndPhone: "areaAndPhone",
          verifyImgCode: "verifyImgCode",
          verifySmsCode: "verifySmsCode",
        },
        RESET_PASSWORD_PROP: {
          password: "password",
          newPassword: "newPassword",
          samePassword: "samePassword",
        },
        BIND_PHONE_RULES_PROP: {
          areaAndPhone: "areaAndPhone",
          verifySmsCode: "verifySmsCode",
        },
        BIND_ACCOUNT_PROP: {
          account: "account",
          password: "password",
        },
        ACCOUNT_PROP: {
          phone: "phone",
          verifySmsCode: "verifySmsCode",
        },
        SAFE_EMAIL_PROP:{
          email:'email'
        },
        RESET_PASSWORD: {
          password: [{required: true, message: i18n.t("RuleMessage.OldPassword"), trigger: "submit"}],
          newPassword: [
            {required: true, message: i18n.t("RuleMessage.NewPassword"), trigger: "submit"},
            {validator: this.checkPasswordLength, trigger: "submit"},
          ],
          samePassword: [
            // {required: true, message: "两次密码不一致", trigger: "submit"},
            {validator: this.samePassword, trigger: "submit"},
          ],
        },
        BIND_ACCOUNT: {
          account: [{
            required: true,
            message: i18n.t("RuleMessage.InputAccount"),
            trigger: "submit",
          }],
          password: [{
            required: true,
            message: i18n.t("RuleMessage.InputPassword"),
            trigger: "submit",
          }],
        },
        ACCOUNT_LOGIN: {
          phone:[{
            validator:this.validator_account,
            trigger:'submit'
          } ],
          // phone: [{
          //   required: true,
          //   message: i18n.t("RuleMessage.InputAccount"),
          // }],
          verifySmsCode: [{
            validator:this.checkPassword,
            trigger:'submit'
          }],
        },
        MOBILE_LOGIN: {
          areaAndPhone: [{
            validator: this.validator_areaAndPhone,
            trigger: "submit",
          }],
          verifyImgCode: [{
            validator: this.validator_verifyImgCode,
            trigger: "submit",
          }],
          verifySmsCode: [{
            validator: this.validator_verifySmsCode,
            trigger: "submit",
          }],
        },
        BIND_PHONE: {
          areaAndPhone: [{
            validator: this.validator_bindAreaAndPhone,
            trigger: "submit",
          }],
          verifySmsCode: [{
            required: true,
            message: i18n.t("RuleMessage.InputSmsCode"),
            trigger: "submit",
          }],
        },
        SAFE_EMAIL:{
          email: [{
            validator: this.checkEmail,
            trigger: "submit",
          }],
        }
      };
    },
    checkPassword(rule,value,callback){
      if (!value) {
        return callback(new Error(this.$t("RuleMessage.InputPassword")));
      }
      return  callback()
    },
    checkPasswordLength(rule, value, callback) {
      if (!value) {
        return callback(new Error(this.$t("RuleMessage.InputPassword")));
      }

      if (value.length < 8) {
        return callback(new Error(this.$t("RuleMessage.PasswordLengthError")));
      }

      if (value.length > 20) {
        return callback(new Error(this.$t("RuleMessage.PasswordLengthOver")));
      }

      return callback();
    },
    samePassword(rule, value, callback) {
      if (this.bindUser.newPassword !== value) {
        return callback(new Error(this.$t("RuleMessage.PasswordLengthNotEquals")));
      }

      return callback();
    },
    validator_bindAreaAndPhone(rule, value, callback) {
      if (!this.bindUser.area) {
        return callback(new Error(this.$t("RuleMessage.AreaNumberNotEquals")));
      }
      if (!this.bindUser.phone) {
        return callback(new Error(this.$t("TestKeyText.PleaseFillInMobileNumber")));
      }

      return callback();
    },
    validator_areaAndPhone(rule, value, callback) {
      if (!this.HeaderInfo.MobileLogin.area) {
        return callback(new Error(this.$t("RuleMessage.AreaNumberNotEquals")));
      }
      if (!this.HeaderInfo.MobileLogin.phone) {
        return callback(new Error(this.$t("TestKeyText.PleaseFillInMobileNumber")));
      }
      return callback();
    },
    validator_verifyImgCode(rule, value, callback) {
      if (!this.HeaderInfo.MobileLogin.verifyImgCode) {
        return callback(new Error(this.$t("RuleMessage.InputVerifyCode")));
      }
      return callback();
    },
    validator_verifySmsCode(rule, value, callback) {
      if (!this.HeaderInfo.MobileLogin.verifySmsCode) {
        return callback(new Error(this.$t("RuleMessage.InputSmsCode")));
      }
      return callback();
    },
    validator_account(rule,value,callback){
      if(!value.trim()){
        return callback(this.$t('RuleMessage.InputAccount'))
      }
      const type = 'login.account'
      const loginInfo = this.HeaderInfo.MobileLogin
      this.$store.dispatch('user/USER_LOGIN', {
        type,
        user: loginInfo
      }).then((message)=>{
        if(message){
          return callback(new Error(message))
        }
        return callback()
      })

    },
    checkEmail (rule, value, callback) {
      if (!value.trim()) {
        return callback(new Error(this.$t_reserve('请填写邮箱')))
      } else if (!/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)) {
        return callback(new Error(this.$t_reserve('请输入正确的邮箱格式')))
      } else if (this.emailList.map((i) => i?.email)?.includes(value)) {
        // 判断填入的值是否已存在
        return callback(new Error(this.$t_reserve('邮箱已存在')))
      } else {
        callback()
      }
    }
  },
};
