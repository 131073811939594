import Vue    from "vue";
import LZUTF8 from "lzutf8";

export * from "@/utils/common";
export * from "@/mixins/requestAnimation";

// 通过计算Element的ClientRect来移动到指定元素
export function scrollToElement(element, offset = 0) {
  if (!element) return;
  const rect = element.getBoundingClientRect();
  const top = rect.top + (window.scrollY || window.pageYOffset) + offset;
  window.scrollTo({
    top,
    behavior: "smooth",
  });
}

export function toFixed(value, length = 2) {
  if (value === null || value === undefined) return value;
  value = `${value}`;
  if (!value || /([^\d.])/g.test(value)) return value;
  let [integer = "", pointer = ""] = value.split(".");
  pointer = pointer.padEnd(length, "0").slice(0, 2);
  return `${integer}.${pointer}`;
}

export function parseMoney(value, defValue) {
  if (/([^\d.])/g.test(value)) return defValue ?? "0.00";
  if (!value) return defValue ?? "0.00";
  value = value + "";
  let [integer = "", pointer = ""] = value.split(".");
  if (pointer.length > 2) pointer = pointer = pointer.slice(0, 2);
  pointer = pointer.padEnd(2, "0");
  integer = integer.replace(/(?=(\d{3})+$)/g, ",");
  if (integer[0] === ",") integer = integer.slice(1);

  return `${integer}.${pointer}`;
}

export function encodeDataForQuery(data) {
  const json = JSON.stringify(data);
  // const base64 = window.btoa(str);
  const str = LZUTF8.compress(json, {
    outputEncoding: "Base64",
  });
  // return encodeURIComponent(str);
  return str;
}

export function decodeQueryForData(baseString) {
  // const str = window.atob(baseString || "");
  // return JSONExtend.tryParse(decodeURIComponent(str), null);
  // const str = decodeURIComponent(baseString);
  const json = LZUTF8.decompress(baseString, {
    inputEncoding: "Base64",
    outputEncoding: "String",
  });
  // const data = window.atob(str);
  return JSONExtend.tryParse(json, null);
}

Vue.mixin({
  methods: {
    // 顺序执行函数，不为true时中断.
    serialFunction(...functions) {
      return !functions?.some(func => func?.() !== true);
    },
  },
});
