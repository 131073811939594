import Vue                              from "vue";
import Vuex                             from "vuex";
import lang                             from "./modules/lang";
import user                             from "./modules/user";
import business                         from "./modules/business";
import pinyin                           from "pinyin";
import {countryCodeList}                from "@/api/user";
import {
  getCityQueryList,
  getCountryQueryList,
}                                       from "@/api/customer";
import {ExRateOptions, getFileCategory} from "@/api/options";
import ls                               from "localstorage-slim";
import {i18n}                           from "@/app";
import options                          from "@/store/modules/options";

Vue.use(Vuex);

let storeLocalComputed = {};
// 公共数据丢在index.js
export default new Vuex.Store({
  state: {
    //区号
    countryTelCodeList: [],
    // 国家
    countryList: [],
    // 城市
    cityList: [],

    // 文件分类
    fileCategoryOptions: [],
    // dialogHome: true,
    // dialogWork: true

    // 汇率下拉
    exRateOptions: [],
  },
  getters: {
    options: state => state.options,
    locale: state => state.lang.locale,
    token: state => state.user.token,
    user: state => state.user.info || {},
    isLogin: state => !!state.user.token?.Id,
    cnts: state => state.business.cnts.map(x => x.Size + x.Type),
    conInfoList: state => state.business.cnts.map(x => ({
      label: x.Size + x.Type,
      value: x.Size + x.Type,
    })),
    area: state => ({
      stations: state.business.stations,
      portl: state.business.portLHotStation,
      dest: state.business.destHotStation,
      seaStations: state.business.seaStations,
      airStations: state.business.airStations,
      truckStations: state.business.truckStations,
      transPortStations:state.business.transPortStations
    }),
  },
  mutations: {
    SET_COUNTRY_TEL_CODE_LIST(state, list) {
      state.countryTelCodeList = list;
    },
    SET_CITY_QUERY_LIST(state, list) {
      state.cityList = list;
    },
    SET_COUNTRY_QUERY_LIST(state, list) {
      state.countryList = list;
    },
    SET_FILE_CATEGORY(state, list) {
      state.fileCategoryOptions = list;
      console.log(list, "list");
    },
    // DIALOG_HOME(state) {
    //   state.dialogHome = false
    // },
    // DIALOG_WORK(state) {
    //   state.dialogWork = false
    // }
    SET_EX_RATE_OPTIONS(state, options) {
      state.exRateOptions = options;
    },
  },
  actions: {
    async GET_EX_RATE_OPTIONS({commit, state}) {
      if (storeLocalComputed.exRateLoading) return;
      if (state.exRateOptions.length > 0) return;

      storeLocalComputed.exRateLoading = true;
      const response = await ExRateOptions();
      storeLocalComputed.exRateLoading = false;

      commit("SET_EX_RATE_OPTIONS", response);
    },
    async GET_FILE_CATEGORY({commit}) {
      const res = await getFileCategory();

      if (!res || res?.message) {
        return res?.message || i18n.t("RequestMessage.GetFileCategoryFail");
      }

      commit("SET_FILE_CATEGORY", res);
    },
    //获取城市
    async GET_CITY_QUERY_LIST({commit}) {
      const data = await getCityQueryList();

      const key = {
        "zh-CN": "CityNameChs",
        "zh-TW": "CityNameCht",
        "en": "CityNameEng",
        "ru": "CityNameRus",
      }[i18n.locale];
      const defaultKey = {
        "zh-CN": "CityNameChs",
        "zh-TW": "CityNameChs",
        "en": "CityNameEng",
        "ru": "CityNameEng",
      }[i18n.locale];

      commit("SET_CITY_QUERY_LIST", data.map(x => ({
        ...x,
        PortNameChs: x.CityNameChs?.trim() || "",
        PortNameCht: x.CityNameCht?.trim() || "",
        PortNameEng: x.CityNameEng?.trim() || "",
        PortNameRus: x.CityNameRus?.trim() || "",
        PortName: x.CityName?.trim() || "",
        name: (x[key] || x[defaultKey] || x.CityName)?.trim() || "",
        value: x.CityCd,
        py: pinyin(x.CityName, {
          style: pinyin.STYLE_NORMAL,
        }).join(""),
      })));
    },
    // 获取国家
    async GET_COUNTRY_QUERY_LIST({commit, state}) {
      const data = await getCountryQueryList();

      const key = {
        "zh-CN": "CountryNameChs",
        "zh-TW": "CountryNameCht",
        "en": "CountryNameEng",
        "ru": "CountryNameRus",
      }[i18n.locale];
      const defaultKey = {
        "zh-CN": "CountryNameChs",
        "zh-TW": "CountryNameChs",
        "en": "CountryNameEng",
        "ru": "CountryNameEng",
      }[i18n.locale];
      commit("SET_COUNTRY_QUERY_LIST", data.map(x => ({
        ...x,
        PortNameChs: x.CountryNameChs?.trim() || "",
        PortNameCht: x.CountryNameCht?.trim() || "",
        PortNameEng: x.CountryNameEng?.trim() || "",
        PortNameRus: x.CountryNameRus?.trim() || "",

        PortName: (x.CountryName)?.trim() || "",
        name: (x[key] || x[defaultKey] || x.CountryName)?.trim() || "",
        value: x.CountryCd,
        py: pinyin(x.CountryNameChs, {
          style: pinyin.STYLE_NORMAL,
        }).join(""),
      })));
    },
    // 获取区号
    async SET_COUNTRY_TEL_CODE_LIST({commit, state}) {
      let list = await countryCodeList();
      list = list.sort((x, y) => pinyin.compare(x.CountryNameChs, y.CountryNameChs)).filter(x => !!x["CountryTelCode"]);
      if (list.length <= 0) {
        ls.remove("/api/v1/country-list");
      }
      commit("SET_COUNTRY_TEL_CODE_LIST", list.map(x => ({
        ...x,
        PortNameChs: x.CountryNameChs?.trim() || "",
        PortNameCht: x.CountryNameCht?.trim() || "",
        PortNameEng: x.CountryNameEng?.trim() || "",
        PortNameRus: x.CountryNameRus?.trim() || "",
        PortName: (x.CountryName)?.trim() || "",

        py: pinyin(x.CountryNameChs, {
          style: pinyin.STYLE_NORMAL,
        }).join(""),
        name: state.lang.locale === "zh-CN" ? x.CountryNameChs : x.CountryName,
        value: x.CountryTelCode,
      })));
    },
  },
  modules: {
    lang,
    user,
    business,
    options,
  },
});
