<script>
import Element from "element-ui";

export default {
  name: "ElOptionVirtual",
  extends: Element.Option,
  methods: {
    // 覆盖原始的QueryChange，在虚拟化列表下不需要内部过滤
    queryChange() {
      if (!this.visible) {
        this.select.filteredOptionsCount--;
      }
    }
  }
};
</script>
