<template>
  <el-select v-model="dynamic" v-bind="$attrs" v-on="$listeners"
             :popper-class="['oms__el-select-drop-item-extend',popperClass ].join(' ')"
             class="oms__el-select-extend"
             :class="{'readonly':!!FORM_ITEM.readonly}"
             :disabled="!!COMPUTED_DISABLED">
    <slot/>
    <el-option v-for="(item,index) in list" :key="'OMS-SELECT-' + index" :value="item.value">
      <slot v-if="$scopedSlots.label" :row="item"/>
      <div v-else-if="api === API.Options.getServiceTypeOptions" class="flex between">
        <!-- 默认进入组件的数据，因为刚进组件是没有数据的 -->
        <!--        <template v-if="flag">
                  <span>{{ item.label }}</span>
                  <span v-show="flagYY == 'zh-CN'">{{ item["StNameChn"] }}</span>
                  <span v-show="flagYY == 'zh-TW'">{{ item["StNameCht"] }}</span>
                  <span v-show="flagYY == 'ru'">{{ item["StNameRus"] }}</span>
                </template>-->
        <!-- 中文 -->
        <span>{{ item.label }}</span>
        <span :class="[$i18n.locale+'-select-span']">{{ item.subLabel }}</span>
        <!--        <template v-if="zhCN">
                  <span>{{ item.label }}</span>
                  <span>{{ item["StNameChn"] }}</span>
                </template>
                &lt;!&ndash; 繁体 &ndash;&gt;
                <template v-else-if="zhTW">
                  <span>{{ item.label }}</span>
                  <span>{{ item["StNameCht"] }}</span>
                </template>
                &lt;!&ndash; 英语 &ndash;&gt;
                <template v-else-if="enFlag">
                  <span>{{ item.label }}</span>
                  &lt;!&ndash; <span>{{ item["StNameChn"] }}</span> &ndash;&gt;
                </template>
                &lt;!&ndash; 俄语 &ndash;&gt;
                <template v-else-if="ru">
                  <span>{{ item.label }}</span>
                  <span class="ru-select-span">{{ item["StNameRus"] }}</span>
                </template>-->
      </div>
      <span v-else>{{ item.label }}</span>
    </el-option>
  </el-select>
</template>

<script>
import {model} from "@/mixins";

export default {
  name: "OmsSelect",
  mixins: [model],
  inject: {
    omsFormItem: {
      default: "",
    },
  },
  props: {
    disabled: Boolean,
    popperClass: String,
  },
  data() {
    return {
      list: [],
      enFlag: false,
      zhCN: false,
      zhTW: false,
      ru: false,
      flag: true,
      flagYY: ""
    };
  },
  watch: {
    "$i18n.locale"(lang) {
      /** @description 基于你的变量设计的前提下的优化，以及将判断挪入了init中 */
      this.init();

      /*  if (val == "en") {
          this.enFlag = true;
          this.zhCN = false;
          this.zhTW = false;
          this.ru = false;
          this.flag = false;
          this.init();
        } else if (val == "zh-CN") {
          this.zhCN = true;
          this.enFlag = false;
          this.zhTW = false;
          this.ru = false;
          this.flag = false;
          this.init();
        } else if (val == "zh-TW") {
          this.zhTW = true;
          this.zhCN = false;
          this.enFlag = false;
          this.ru = false;
          this.flag = false;
          this.init();
        } else if (val == "ru") {
          this.ru = true;
          this.zhCN = false;
          this.enFlag = false;
          this.zhTW = false;
          this.flag = false;
          this.init();
        }*/
    },
  },
  computed: {
    FORM_ITEM() {
      return this.omsFormItem || {};
    },
    COMPUTED_DISABLED() {
      return !!(this.disabled || this.omsFormItem?.readonly || this.omsFormItem?.disabled);
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const lang = this.$i18n.locale || "zh-CN";
      const keys = {
        "en": "enFlat",
        "ru": "ru",
        "zh-CN": "zhCN",
        "zh-TW": "zhTW"
      };

      Object.keys(keys).forEach(key => {
        if (keys[key]) {
          this[keys[key]] = false;

          if (lang === key) {
            this[keys[lang]] = true;
          }
        }
      });


      if (this.api instanceof Function) {
        let resp = await this.api(this.apiParams || {
          inout: "I"
        });

        if (this.apiCallback instanceof Function) {
          resp = this.apiCallback(resp) || resp;
        }

        let labelName = "", valueName = "";
        let defaultKey;

        if (this.api === this.API.Options.getCartType) {
          labelName = valueName = "Type";
        } else if (this.api === this.API.Options.getServiceTypeOptions) {
          labelName = valueName = "StName";
          defaultKey = {
            "zh-CN": "StNameChn",
            "zh-TW": "StNameCht",
            "ru": "StNameRus",
            "en": "StNameEng-back",
          }[lang];

        }
        // console.log(this.$i18n.locale,'$i18n.locale');
        if (!Array.isArray(resp)) {
          resp = [];
        }
        this.list = resp.map(x => {
          return {
            ...x,
            label: x[labelName],
            value: x[valueName],
            subLabel: x[defaultKey] || ""
          };
        });
        this.flagYY = this.$i18n.locale;
      }
    }
  }
};
</script>

<style lang="scss">
.oms__el-select-extend {
  &.readonly {

  }

  .el-input.el-input--suffix {
    display: flex;
  }
}

.oms__el-select-drop-item-extend {
  .el-select-dropdown__item {
    min-height: 34px;
    height: auto;
  }
}
</style>
<style lang="scss" scoped>
.ru-select-span {
  font-size: 12px;
}
</style>
