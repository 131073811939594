<template>
  <div class="media-container-container">
    <app-header page-type="workSpace" />
    <aside-menu :collapse-able="false" defaultActive="/invoice" />
    <oms-row class="container layout-container">
      <router-view />
    </oms-row>
  </div>
</template>

<script>
import AsideMenu from '@/components/workSpace/AsideMenu'
import AppHeader from '@/components/AppHeader'

export default {
  name: 'WorkBenchLayout',
  components: { AsideMenu, AppHeader }
}
</script>

<style scoped>
.media-container-container {
  background-color: #f7f9fb;
}

.layout-container {
  margin: 32px 0 0 264px;
  max-width: 1624px;
  min-width: 1200px;
  min-height: 89vh;
}
</style>
