<template>
  <label :for="GUID" class="__oms-extend_el-tag-input fs-16"
         ref="TagInputRef"
         v-clickoutside="handleClose"
         :class="{'hover':hover,'disabled':DISABLED,'readonly':READONLY}">
    <a class="tag" v-for="item in tagList">
      <span>{{ item }}</span>
      <i class="el-tag__close el-icon-close" v-if="!NOT_ALLOW" @click="onRemoveTag(item)"/>
    </a>
    <a class="__input-container">
      <input v-model="inputEmail" :id="GUID" ref="reference" type="text"
             @focus="hover=true" @blur="onBlur"
             @input="onKeyCreateTag" @keypress.enter="onCreateTag()"
             @paste="onPasteCheck" @keydown.tab="handleClose"
             :disabled="NOT_ALLOW"
             @keydown="onKeyPressRemoveTag"/>
      <el-icon name="loading" class="loading-buff" :class="{'on': apiLoading}"/>
    </a>
    <!--    this;tagList;push -->
    <small v-if="!cloneSpanText && tagList.length <= 0" class="placeholder">{{ placeholder }}</small>
    <b ref="CLONE_SPAN" style="position: absolute;opacity: 0;">{{ cloneSpanText }}</b>
    <transition
        name="el-zoom-in-top"
        @before-enter="handleMenuEnter"
        @after-leave="doDestroy">
      <el-select-menu
          ref="popper"
          append-to-body
          v-show="visible && emptyText !== false">
        <el-scrollbar
            tag="ul"
            wrap-class="el-select-dropdown__wrap"
            view-class="el-select-dropdown__list"
            ref="scrollbar"
            v-show="optionDataSources.length > 0 && !loading">
          <el-option v-for="(item,index) in optionDataSources" :key="'optionDataSources-'+index"
                     :label="item.label" :value="item.value"/>
        </el-scrollbar>
        <template v-if="optionDataSources.length <= 0">
          <slot name="empty" v-if="$slots.empty"></slot>
          <p class="el-select-dropdown__empty" v-else>
            {{ emptyText }}
          </p>
        </template>
      </el-select-menu>
    </transition>
  </label>
</template>

<script>
import model     from "@/mixins/model";
import Element   from "element-ui";
import apiMethod from "@/mixins/api.method";
import _         from "lodash";

const ExtendSelect = Object.assign({}, Element.Select);
ExtendSelect.computed = Object.assign({}, ExtendSelect.computed);

delete ExtendSelect.computed.readonly;

export default {
  name: "ElTagInput",
  components: {
    ElSelectMenu: ExtendSelect.components.ElSelectMenu,
  },
  extends: ExtendSelect,
  inject: {
    omsRow: {
      default: "",
    },
    elForm: {
      default: "",
    },
    omsFormItem: {
      default: "",
    },
  },
  data() {
    return {
      apiLoading: false,
      optionDataSources: [],
      TestStr: "",
      tagList: this.value || [],
      cloneSpanText: "",
      hover: false,
      removeState: false,
      inputEmail: "",
    };
  },
  computed: {
    NOT_ALLOW() {
      return !!(this.omsRow.disabled || this.disabled || this.omsRow.readonly || this.readonly);
    },
    DISABLED() {
      return !!(this.omsRow.disabled || this.disabled);
    },
    READONLY() {
      return !!(this.omsRow.readonly || this.readonly);
    },
    GUID() {
      return this.$guid().replace("-", "");
    },
  },
  mixins: [model, apiMethod],
  mounted() {
    this.$refs.popper.referenceElm =  this.$refs.TagInputRef;
    this.onUpdateInputSize();
    this.$refs.TagInputRef.$el = this.$refs.TagInputRef;
    this.$refs.reference.$el = this.$refs.reference;
  },
  beforeDestroy() {
    this.inputEmail = "";
  },
  props: {
    popperClass: String,
    disabled: Boolean,
    readonly: Boolean,
    placeholder: String,
  },
  watch: {
    cloneSpanText(val) {
      this.onUpdateInputSize();

      if (!val) {
        this.visible = false;
      }
    },
    inputEmail(val) {
      this.cloneSpanText = val;
    },
    tagList: {
      deep: true,
      handler() {
        this.dynamic = this.tagList;
      },
    },
    dynamic() {
      this.tagList = this.dynamic ?? [];
      this.$emit("change", this.dynamic ?? []);
    },
    visible(val) {
      if (val) {
        this.resetInputWidth();
      }
    },
  },
  methods: {
    handleClose(...args) {
      this.isSilentBlur = true;
      this.onBlur();
      this.$super(ExtendSelect).handleClose(...args);
    },
    async onQuery(query) {
      if (!query) return;
      if (!this.api) return;

      let sources;
      let apiFunction = this.api;
      let index = (this.apiIndex = (this.apiIndex || 0) + 1);

      if (typeof this.api !== "function") {
        apiFunction = () => this.api ?? [];
      }

      let response = [];

      try {
        this.apiLoading = true;
        response = await apiFunction(Object.assign({}, this.apiParams, {
          filter: query,
        }));
        if (index !== this.apiIndex) {
          return;
        }
      } catch (e) {

      } finally {
        this.apiLoading = false;
      }
      const getArray = (...args) => args.find(item => Array.isArray(item));
      const convert = data => typeof data === "string" ? {label: data, value: data} : data;

      sources = getArray(response, response?.data, response?.data?.Items, response?.Items);
      if (typeof this.apiCallback === "function") {
        sources = this.apiCallback(sources) ?? sources.map(convert);
      } else {
        sources = sources.map(convert);
      }

      this.optionDataSources = response;
      return true;
    },
    // 失去焦点时
    onBlur() {
      setTimeout(() => {
        if (this.isSilentBlur) {
          this.hover = false;
          this.isSilentBlur = false;
          this.visible = false;
          this.onCreateTag();
        }
      }, 100);
    },
    async onPasteCheck(event) {
      let clipboardData = event.clipboardData || window.clipboardData;
      let pasteData = this.inputEmail + clipboardData.getData("Text");
      await delay(0);
      if (pasteData.indexOf(";") >= 0) {
        this.onCreateTag(pasteData, true);
      }
      // event.currentTarget.value = '';
    },
    onRemoveTag(item) {
      const index = this.tagList.indexOf(item);
      if (index < 0) return;
      this.tagList.splice(index, 1);
    },
    async onKeyPressRemoveTag(event) {
      if (event.code === "Backspace" && this.tagList.length > 0 && this.inputEmail === "") {
        // 第二次回退确认删除
        if (this.removeState) {
          this.tagList.pop();
          await this.$nextTick();
          this.$refs.reference.focus();
          this.removeState = false;
        } else {
          this.removeState = true;
        }
        return;
      }

      this.removeState = false;
    },
    onKeyCreateTag(event) {
      const char = event.data;

      this.cloneSpanText = event.target.value;
      if (char === ";") {
        event.preventDefault();
        event.stopPropagation();
        this.onCreateTag();
      }

      _.debounce(() => this.onQuery(event.target.value).then(success => {
        if (success) this.visible = true;
      }), 50)();
    },
    onCreateTag(str = this.inputEmail, stayInput = false) {
      // if (this.inputEmail[this.inputEmail.length - 1] === ";") {
      //   this.inputEmail = this.inputEmail.slice(0, this.inputEmail.length - 1);
      //   this.cloneSpanText = this.inputEmail;
      // }
      const tags = str.split(";").filter(_ => stayInput || Boolean(_)).map(x => x.trim());
      this.cloneSpanText = str;
      if (!str) return;
      let stay = "";
      if (stayInput) {
        stay = tags.pop();
      }
      this.inputEmail = stay;
      this.cloneSpanText = stay;
      tags.forEach(tag => this.tagList.push(tag));
    },
    async onUpdateInputSize() {
      this.$refs.CLONE_SPAN.style.fontSize = getComputedStyle(this.$refs.reference)["font-size"];
      await this.$nextTick();
      const {width} = this.$refs.CLONE_SPAN.getBoundingClientRect();
      this.$refs.reference.style.width = Math.max(width, 1) + "px";
    },
    handleOptionSelect(option, byClick) {
      this.tagList.push(option.label);
      this.cloneSpanText = this.inputEmail = "";
      // this.$emit('input', option.value);
      this.visible = false;

      this.isSilentBlur = byClick;
      this.$nextTick(() => {
        this.$refs.reference.focus();
      });
    },
  },
};
</script>

<style lang="scss">
.el-form-item.el-form-item__extend.form-item-extend_col-base.is-error {
  .__oms-extend_el-tag-input {
    border-color: #f56c6c;

    .tag {
      background-color: #fde2e2;
      border-color: #f56c6c;
      color: #f56c6c;
    }
  }
}
</style>
<style scoped lang="scss">
.__oms-extend_el-tag-input {
  border: 1px solid #DCDFE6;
  border-radius: 5px;
  display: block;
  position: relative;
  transition: .2s linear;
  cursor: text;
  line-height: 1;
  min-height: 40px;
  box-sizing: border-box;

  &.disabled {
    background-color: #F5F7FA;
    border-color: #E4E7ED;
    color: #C0C4CC;
    cursor: not-allowed;

    .tag {
      background-color: darken(#F5F7FA, 3%);
      padding-right: 15px;
    }
  }

  &.readonly {
    background-color: #F5F7FA;
    border-color: #E4E7ED;
    color: #C0C4CC;

    .tag {
      background-color: darken(#F5F7FA, 3%);
      padding-right: 15px;
    }
  }

  .__input-container {
    padding-left: 20px;
    display: inline-block;
    max-width: 100%;
    line-height: 1;
    box-sizing: border-box;
  }

  input {
    border: none;
    -webkit-appearance: none;
    line-height: 28px;
    padding: 5px 0;
    outline: 0;
    width: 2px;
    max-width: 100%;
  }

  &.hover {
    border-color: #409EFF;
  }

  .tag {
    line-height: 0;
    padding: 5px 5px 5px 15px;
    margin: 5px 0 5px 10px;
    border-radius: 5px;
    background-color: #f4f4f5;
    border-color: #e9e9eb;
    color: #909399;
    display: inline-block;
    min-height: 18px;
    overflow: hidden;
    vertical-align: top;

    > span {
      line-height: 1;
      vertical-align: top;
      display: inline-block;
      white-space: pre-wrap;
      word-break: break-all;
    }

    i {
      cursor: pointer;
      font-size: 12px;
      height: 16px;
      width: 16px;
      line-height: 16px;
      margin-left: 10px;
      vertical-align: middle;
      background-color: #c0c4cc;
      border-radius: 99px;
      text-align: center;

      &:hover {
        color: #fff;
        background-color: #909399;
      }
    }
  }

  .placeholder {
    font-size: 16px;
    position: absolute;
    padding: 5px 0 5px 20px;
    line-height: 30px;
    color: #aaa;
    top: 0;
    left: 0;
    user-select: none;
    pointer-events: none;
  }
}

.loading-buff {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -8px;
  color: #aaa;
  visibility: hidden;
  opacity: 0;
  transform: translate(30px, 0);
  transition: .2s linear;

  &.on {
    opacity: 1;
    transform: translate(0, 0);
    visibility: visible;
  }
}
</style>
