import request from "@/utils/request";
import moment from "moment";

export function getCodeLibQuery(classcode) {
  return request({
    url: "/api/v1/codelib-query",
    method: "get",
    cache: {
      type: "local",
      ttl: 24 * 3600,
    },
    once: true,
    params: {
      key: "",
      undefault: "F",
      classcode,
    },
  });
}

// 获取文件分类
export function getFileCategory() {
  return getCodeLibQuery("DOCTYPE");
}

// 获取箱型
export function conInfoOptions(key) {
  return request({
    url: "/api/v1/coninfo-query",
    method: "get",
    once: true,
    params: {
      key: key,
    },
  });
}

// 币别下拉
export function getCurrencyOptions(key = "") {
  return request({
    url: "/api/v1/currency-query",
    method: "get",
    params: {
      key,
    },
    once: true,
  });
}

// 海运运输条款下拉
export function getServiceTypeOptions({key = ""} = {}) {
  return request({
    url: "/api/v1/servicetype-option",
    method: "get",
    params: {key}
  });
}

export function getCartType({key = "", inout = ""} = {}) {
  return request({
    url: "/api/v1/cartype-query",
    method: "get",
    params: {
      key,
      inout
    },
    cache: {
      type: "session",
    },
    once: true,
  });
}

// 获取海关编号
export function GetHsCode(query, options) {
  const defaultOptions = {
    pageIndex: 1,
    pageSize: 20,
    sort: "asc",
    sortName: "",
  };

  if (!options) {
    options = defaultOptions;
  } else {
    Object.keys(defaultOptions).forEach(key => {
      if (!options.hasOwnProperty(key)) {
        options[key] = defaultOptions[key];
      }
    });
  }

  return request({
    url: "api/v1/omshscode-list",
    method: "get",
    params: Object.assign({}, options, {
      filter: query,
    }),
  });
}

// 获取汇率详细信息
export function ExRateInformation(currcd) {
  return request({
    url: "api/v1/exrate-now",
    method: "get",
    params: {
      currcd: currcd, // 币别
      yearMonth: moment().format("YYYY-MM"),
    }
  });
}

// 汇率下拉
export function ExRateOptions(key, curDate) {
  return request({
    url: "/api/v1/exrate-query",
    method: "get",
    params: {
      key: key,
      curDate: curDate // 发生时间
    }
  });
}

// 业务员下拉选择
export function getClerkOptions (){
  return request({
    url:'api/v1/dialog-mySalerlist',
    method:'get',
  })
}
