<template>
  <div class="map-container-fill">
    <div class="map-container">
      <div class="wrap" v-loading="loading" :element-loading-text="$t('TestKey.LoadingMap')" loading-icon="">
        <span v-if="!loading">{{$t('TestKey.MapDisplay')}}</span>
        <div class="non-track-data" v-if="!loading && (!mapData || !mapData.list || mapData.list.length <= 0)">
          <span>{{$t('TestKey.NoMovementTrace')}}</span>
        </div>
        <Bing ref="bingMap"
              :loading.sync="loading"
              :dependent="mapData"/>
      </div>
    </div>
  </div>
</template>

<script>
import Bing              from "@/components/map/Bing";
import {getLocationInfo} from "@/api/gps";

// 运踪轨迹分享
export default {
  name: "trackViewer",
  components: {Bing},
  data() {
    return {
      loading: false,
      mapData: {
        list: [],
        center: {},
      },
    };
  },
  props: {
    conNo: {
      type: String,
      default: "",
    },
    jobNo: {
      type: String,
      default: "",
    },
  },
  computed: {
    SHARE_LINK() {
      return (window.location.host || "") + "route/path/content.asp";
    },
  },
  mounted() {
    this.visitTrackInfo();
  },
  methods: {
    async visitTrackInfo() {
      let jobNo = this.jobNo || "";
      let orderNum = this.conNo || "";

      // if (process.env.NODE_ENV === "development") {
      //   jobNo = "SZNEPRF2205231445";
      //   orderNum = "CON220523";
      // }

      const res = await getLocationInfo(jobNo, orderNum);
      //使用最新的数据作为条件
      if (res.success && res.data?.status === 200) {
        const data = res.data?.data;
        if (data.track.length > 0) {
          this.mapData.list = data.track?.map(item => ({
            latitude: item.latitude,
            longitude: item.longitude,
            address: item.address,
            createTime: item.createTime,
          })) || [];
          this.mapData.center = {
            latitude: this.mapData.list[0]?.latitude,
            longitude: this.mapData.list[0]?.longitude,
          } || {};
        }
        await this.$refs.bingMap.mapDataUpdate();
      }
    },
  },
  watch: {
    conNo() {
      this.visitTrackInfo();
    },
    jobNo() {
      this.visitTrackInfo();
    },
  },
};
</script>

<style scoped
       lang="scss">
.map-container-fill {
  width: 100%;
  height: 100%;
}

.map-container {
  position: relative;
  width: 100%;
  height: 100%;

  .non-track-data {
    position: absolute;
    width: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    background: rgba(#000, .4);
    color: #ddd;
    font-size: 18px;
    font-weight: bold;
    text-shadow: 0 1px 0 #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .wrap {
    $LOCAL_SIZE: 0;
    position: absolute;
    top: $LOCAL_SIZE;
    right: $LOCAL_SIZE;
    bottom: $LOCAL_SIZE;
    left: $LOCAL_SIZE;
    background: #fdfdfd;

    > span {
      position: absolute;
      top: 0;
      bottom: 0;
      line-height: 30px;
      height: 30px;
      text-align: center;
      width: 100%;
      margin: auto;
      color: #ddd;
    }
  }
}
</style>
